import { create } from "zustand";
import { persist } from "zustand/middleware";

interface User {
  id: string;
  rememberMe: boolean;
  info: {
    company_name?: string;
    first_name?: string;
    last_name?: string;
    fivetran_destination_id?: string;
    connections?: string
  };
}


interface UserState {
  user: User | null;
  setUser: (user: User) => void;
  logout: () => void;
  getDestinationId: () => string | null;
}

export const useUserStore = create(
  persist<UserState>((set, get) => ({
    user: null,
    setUser: (user: User) => set({ user }),
    logout: () => set({ user: null }),
    getDestinationId: () => {
      return get().user?.info?.fivetran_destination_id ?? null;
    }
  }), {
    name: 'user-storage',
    // storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
  })
);
