import {
  IconButton,
  // Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  useColorModeValue,
  Text,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Image,
} from "@chakra-ui/react";

import {
  FiMenu,
  // FiBell,
  FiChevronDown,
} from "react-icons/fi";

import { Link, useNavigate } from "react-router-dom";

import { supabase } from "../../services/supabase";
import Logo from "../../assets/pulse.svg";
import { useEffect } from "react";
import { useUserStore } from "../../store/userStore";

interface MobileProps extends FlexProps {
  onOpen: () => void;
}

export default function Navbar({ onOpen, ...rest }: MobileProps) {
  const { user } = useUserStore();
  const navigate = useNavigate();

  useEffect(() => {
    const run = async () => {
      const { data, error } = await supabase.auth.getSession();

      if (!data?.session?.user || error) {
        navigate("/login");
      }
    };

    run();
  }, [navigate]);

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg="primary.200"
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Link to="/tracker">
        <Image
          display={{ base: "flex", md: "none" }}
          src={Logo}
          alt="Logo"
          height={{ base: "64px", md: "56px" }}
          py="6px"
          pr="40px"
          objectFit="cover"
        />
      </Link>

      <HStack spacing={{ base: "0", md: "6" }}>
        {/* <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiBell />}
          colorScheme="brand"
        /> */}
        <Flex alignItems={"center"}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                {/* <Avatar
                  size={"sm"}
                  src={
                    "https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
                  }
                /> */}
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                  color="brand.500"
                >
                  <Text fontSize="sm" fontWeight="bold">
                    Hello, {user?.info?.first_name || "User"}
                  </Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>

            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
              color="brand.700"
            >
              <MenuItem>Profile</MenuItem>
              {/* <MenuItem>Billing</MenuItem> */}
              <MenuDivider />
              <MenuItem as={Link} to="/logout">
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
}
