import { Button } from '@chakra-ui/react';
import { useFormikContext } from 'formik';

import { mapMatchFields, getHeadersFromXLS } from '../utils';
import { useState, useEffect } from 'react';

export default function MatchFields(props) {
    const {values, resetForm} = useFormikContext();

    const [headers, setHeaders] = useState(null);

    useEffect(() => {
        getHeadersFromXLS(props.file, (data) => {
          const taken = (data || [])
            .filter((a) => {
              return a.filter(Boolean).length;
            })
            .slice(0, 100);

          setHeaders(taken[0]);
        });
    }, [props.file]);

    const handleAutoMatchField = (e) => {
        e.preventDefault();
        
        let a = {};

        (headers || []).forEach((header, index) => {
            const lowerHeader = String(header).trim().toLowerCase();

            const allowedField = mapMatchFields.find((field) => {
                const fAllowed = field.allowed.map((f) =>
                    String(f).trim().toLowerCase()
                );

                return fAllowed.includes(lowerHeader);
            });

            if (allowedField) {
                a = { ...a, [allowedField.key]: index.toString() };
            }
        });

        resetForm({ values: { ...values, ...a } });
    };

    return (
        <Button
            colorScheme="red"
            title="Auto fill fields with header of CSV file"
            onClick={handleAutoMatchField}
        >
            Auto Fill Fields
        </Button>
    );
}
