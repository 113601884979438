// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore disable TS  for this file
import ApiClient from "./api-client.js";

const API_KEY = import.meta.env.VITE_API_KEY;
const API_SECRET = import.meta.env.VITE_API_SECRET;

// export const group = import.meta.env.VITE_GROUP;

const client = new ApiClient(
  "https://api.fivetran.com/v1",
  API_KEY,
  API_SECRET
);

export const getConnectors = async (group: string) => {
  try {
    const data = await client.getListOfConnectorsInGroup(group);

    return data;
  } catch (error) {
    console.log("getConnectors: ", { error });

    return [];
  }
};

export const getConnectorById = async (connectorId: string) => {
  const data = await client.getConnector(connectorId);

  return data;
};

export const createConnector = async (
  service: string,
  group: string,
  name?: string
) => {
  const connectorName = name
    ? `${name}_${service}_${Date.now()}`
    : `${service}`;
  const connector = await client.createConnector(service, group, connectorName);

  return connector;
};

export const getUrlToken = async (connectorId: string) => {
  const token = await client.getConnectCardTokenForConnector(connectorId);

  return `https://fivetran.com/connect-card/setup?auth=${token}`;
};

export const deleteConnector = async (connectorId: string) => {
  const data = await client.deleteConnector(connectorId);

  return data;
};
