import { useRef } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Stack,
  useToast,
  Text,
} from "@chakra-ui/react";

import { supabase } from "../../services/supabase";

import Pulse from "../../assets/pulse.svg";
import background from "../../assets/background.jpg";
import { useNavigate } from "react-router-dom";

export default function ResetPasswordScreen() {
  const toast = useToast();
  const navigate = useNavigate();

  const emailRef = useRef<HTMLInputElement>(null);

  const handleRecovery = async () => {
    const email: string = emailRef.current?.value ?? "";

    if (!email) {
      toast({
        title: "Email is required",
        status: "error",
        duration: 5000,
        isClosable: true,
      });

      return;
    }

    const redirectTo = `${window.location.host}/login`;

    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo,
    });

    if (!error) {
      toast({
        title: "Email sent",
        description:
          "Check your email for a link to reset your password. If it doesn't appear within a few minutes, check your spam folder.",
        status: "info",
        duration: 5000,
        isClosable: true,
      });

      setTimeout(() => {
        navigate("/login");
      }, 3000);
    }
  };

  return (
    <Stack
      minH={"100vh"}
      direction={{ base: "column", md: "row" }}
      backgroundImage={background}
    >
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack
          as="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleRecovery();
          }}
          spacing={4}
          w={"full"}
          maxW={"md"}
          mt={12}
        >
          <Image
            height={110}
            maxWidth="448px"
            my={{ base: "8px", md: "32px" }}
            objectFit="scale-down"
            pr={{ base: 1, md: 10 }}
            src={Pulse}
          />
          
          <Heading fontSize={"2xl"} color="brand.500">
            Reset your Password
          </Heading>

          <Text>You will receive a link in your email address</Text>

          <FormControl id="email">
            <FormLabel>Email address</FormLabel>
            <Input type="email" bg="white" ref={emailRef} required={true} />
          </FormControl>

          <Stack spacing={6}>
            <Button type="submit" colorScheme={"secondary"} variant={"solid"}>
              Recover password
            </Button>
          </Stack>
        </Stack>
      </Flex>

      <Flex
        flex={1}
        alignItems="center"
        justifyContent="center"
        display={{ base: "none", md: "flex" }}
      ></Flex>
    </Stack>
  );
}
