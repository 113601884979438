import Charts from "src/components/Charts";
import Layout from "src/components/Layout";

export default function ChartsPage() {
  return (
    <Layout>
      <Charts />
    </Layout>
  );
}
