import Connections from "../../components/Connections";
import Layout from "../../components/Layout";

const SidebarWithHeader = () => {
  return (
    <Layout>
      <Connections />
    </Layout>
  );
};

export default SidebarWithHeader;
