import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { supabase } from "../../services/supabase";
import { Center, Spinner } from "@chakra-ui/react";

export default function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    const logoutFn = async () => {
      const { error } = await supabase.auth.signOut();
      console.log({ error });

      if (error) {
        console.error({ error });
        return;
      }

      setTimeout(() => {
        navigate("/login");
      }, 1000);
    };

    logoutFn();
  }, [navigate]);

  return (
    <Center height="100vh">
      <Spinner color="brand.500" size="xl" />
    </Center>
  );
}
