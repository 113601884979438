import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Center,
} from '@chakra-ui/react';

import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { useEffect } from 'react';
import { getHeadersFromXLS } from '../utils';
import { useState } from 'react';

export default function PreviewButton(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    getHeadersFromXLS(props.file, (data) => {
      const taken = (data || [])
        .filter((a) => {
          return a.filter(Boolean).length;
        })
        .slice(0, 100);

      setPreview(taken);
    });
  }, [props.file]);

  return (
    <>
      <Button
        onClick={() => {
          onOpen();
          props.showPreview(true);
        }}
        colorScheme='orange'
        title='Show some rows of the file'
      >
        File Preview
      </Button>

      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose} size='2xl' isLazy>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>File Preview</ModalHeader>
            <ModalCloseButton />
            <ModalBody overflowX='auto'>
              <Center pb='16px' color='teal.600'>
                Showing the first 6 rows of the file.
              </Center>
              <Table variant='striped' colorScheme='teal' size='sm'>
                <Thead>
                  <Tr>
                    {preview[0] &&
                      preview[0].map((h, index) => {
                        return <Th key={index}>{h}</Th>;
                      })}
                  </Tr>
                </Thead>
                <Tbody>
                  {preview &&
                    preview.slice(1, 6).map((tr, index) => {
                      return (
                        <Tr key={index}>
                          {tr &&
                            tr.map((td, indx) => {
                              return <Td key={indx}>{td || '-'}</Td>;
                            })}
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme='gray' mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
