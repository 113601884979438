import Layout from "src/components/Layout";
import AddDashboardIds from "src/components/Looker/AddDashboardIds";

const AddDashboardIdsPage = () => {
  return (
    <Layout>
      <AddDashboardIds />
    </Layout>
  );
};

export default AddDashboardIdsPage;
