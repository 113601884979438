import { Box, Heading, Text, Link, Stack } from "@chakra-ui/react";
import Layout from "../../components/Layout";

export default function Faq() {
  return (
    <Layout>
      <Box maxWidth='1280px' bg="primary.200" rounded='md' px={4} py={8}>
        <Box mb={8}>
          <Heading>FAQs</Heading>
        </Box>
        <Stack spacing={8} lineHeight={1.5}>
          <div>
            <Heading size="md">What is Fivetran? </Heading>
            <Text>
              Fivetran is an automated data movement platform that moves data
              out of, into, and across various cloud platforms. It is trusted by
              thousands of large companies, including Denver Broncos,
              MorganStanley, DocuSign, and more. Fivetran is committed to
              security, holding SOC 1/2-3, PCI-DSS, and ISO27001 compliance. <br/>

              <Link href="https://fivetran.com/docs/security" color="brand.500">
                https://fivetran.com/docs/security
              </Link>
            </Text>
          </div>

          <div>
            <Heading size="md">What is Powered by Fivetran? </Heading>
            <Text>
              “Powered By Fivetran” is a way for us to extend our Fivetran
              instance to external users. Through this, users can authorize data
              sources for us to collect without giving us sensitive login
              information. We can sync data to your destinations, but not have
              access to your login credentials.
            </Text>
          </div>

          <div>
            <Heading size="md">What data are you accessing?</Heading>
            <Text>
              We collect relevant reports for each data source. Some data is
              performance-based, some is metadata. Sensitive data like PII is
              removed/hashed so we are unable to read values.
            </Text>
          </div>

          <div>
            <Heading size="md"> Is my data secure?</Heading>
            <Text>
              Yes, Fivetran is committed to security, holding SOC 1/2-3,
              PCI-DSS, and ISO27001 compliance.
              <br/>
              <Link href="https://fivetran.com/docs/security" color="brand.500">
                https://fivetran.com/docs/security
              </Link>
            </Text>
          </div>
        </Stack>
      </Box>
    </Layout>
  );
}
