import { Box, Heading } from "@chakra-ui/react";
import Layout from "../../components/Layout";
import { useEffect } from "react";

export default function Contact() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore window not defined
      if (window.hbspt) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore window not defined
        window.hbspt.forms.create({
          // portalId: 'YOUR_PORTAL_ID_HERE',
          // formId: 'YOUR_FORM_ID_HERE',
          target: "#hubspotForm",

          portalId: "6363940",
          region: "na1",
          formId: "3227f905-9d82-4df8-bbc1-c74b99e1825f",
        });
      }
    });
  }, []);

  return (
    <Layout>
      <Box maxWidth="1280px" bg="primary.200" rounded="md" px={4} py={8}>
        <Box mb={8}>
          <Heading>Contact</Heading>
        </Box>

        <Box>
          <div id="hubspotForm"></div>
        </Box>
      </Box>
    </Layout>
  );
}
