import { Box, Button, Heading, Input, Select, Text } from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { supabase } from "../../../services/supabase";

export default function AddDashboardIds() {
  const [userDashboards, setUserDashboards] = useState([
    { id: "1", alias: " " },
  ]);
  const [userList, setUserList] = useState([
    {
      id: "1",
      first_name: "test",
      last_name: "test",
      email_manager: "test@test.com",
    },
  ]);

  const [nonAddedUserList, setNonAddedUserList] = useState([
    {
      id: "1",
      first_name: "test",
      last_name: "test",
      email_manager: "test@test.com",
    },
  ]);
  const [nonAddedSelectedUser, setNonAddedSelectedUser] = useState({ id: "" });
  const [selectedUser, setSelectedUser] = useState({ id: "" });
  const [selectedUserForAdmin, setSelectedUserForAdmin] = useState({ id: "" });
  const [errorMessage, setErrorMessage] = useState("");
  const [dashboardToRemove, setDashboardToRemove] = useState("");
  const [authorizeErrorMessage, setAuthorizeErrorMessage] = useState("");
  const [makeAdminErrorMessage, setMakeAdminErrorMessage] = useState("");
  const [dashboardToAdd, setDashboardToAdd] = useState("");
  const [dashboardToAddAlias, setDashboardToAddAlias] = useState("");
  const [removeDashboardErrorMessage, setRemoveDashboardErrorMessage] =
    useState("");
  const [removeUserAccessErrorMessage, setRemoveUserAccessErrorMessage] =
    useState("");
  const [removeUserErrorMessage, setRemoveUserErrorMessage] = useState("");

  // check if user is admin

  supabase.auth.getUser().then(({ data }) => {
    if (!data.user) {
      document.location.href = "/";
    }
    supabase
      .from("pulse")
      .select("*")
      .eq("userId", data.user?.id)
      .then(({ data }) => {
        if (
          data &&
          data[0].connections &&
          !data[0].connections.includes("looker_admin")
        ) {
          document.location.href = "/";
        }
      });
  });

  // Get all users
  useEffect(() => {
    setUserList([]);
    supabase
      .from("pulse")
      .select("*")
      .then(({ data }) => {
        if (data) {
          setUserList([]);
          setNonAddedUserList([]);
          data.forEach((user) => {
            if (user.connections && user.connections.includes("looker_embed")) {
              setUserList((prev) => [...prev, user]);
            } else {
              setNonAddedUserList((prev) => [...prev, user]);
            }
          });
        }
      });
  }, []);

  // Get all dashboards for selected user

  const getDashboards = async (id: string) => {
    setSelectedUser({ id });
    if (selectedUser.id !== "") {
      await supabase
        .from("pulse")
        .select("*")
        .eq("id", Number(selectedUser.id))
        .then(({ data }) => {
          if (data) {
            setUserDashboards([]);
            if (data[0].dashboards_looker) {
              setUserDashboards(data[0].dashboards_looker);
              console.log(data[0].dashboards_looker);
            }
          }
        });
    }
  };

  useEffect(() => {
    if (selectedUser.id !== "") {
      supabase
        .from("pulse")
        .select("*")
        .eq("id", Number(selectedUser.id))
        .then(({ data }) => {
          if (data) {
            setUserDashboards([]);
            if (data[0].dashboards_looker) {
              setUserDashboards(data[0].dashboards_looker);
              console.log(data[0].dashboards_looker);
            }
          }
        });
    }
  }, [selectedUser]);

  const AddDashboardIds = async () => {
    setErrorMessage("");
    if (dashboardToAdd.length === 0) {
      setErrorMessage("Please enter a dashboard ID");
      return;
    }
    if (userDashboards.find((dashboard) => dashboard.id === dashboardToAdd)) {
      setErrorMessage("This dashboard ID has already been added");
      return;
    }
    if (selectedUser.id === "") {
      setErrorMessage("Please select a user");
      return;
    }
    if (userDashboards.length === 0) {
      await supabase
        .from("pulse")
        .update({
          dashboards_looker: [
            { alias: dashboardToAddAlias, id: dashboardToAdd },
          ],
        })
        .eq("id", Number(selectedUser.id))
        .select()
        .then(({ error }) => {
          if (error) {
            setErrorMessage(error.message);
          } else {
            setErrorMessage("Dashboard IDs added successfully");
            setDashboardToAdd("");
          }
        });
    } else {
      await supabase
        .from("pulse")
        .update({
          dashboards_looker: [
            ...userDashboards,
            { alias: dashboardToAddAlias, id: dashboardToAdd },
          ],
        })
        .eq("id", Number(selectedUser.id))
        .select()
        .then(({ error }) => {
          if (error) {
            setErrorMessage(error.message);
          } else {
            setErrorMessage("Dashboard IDs added successfully");
            setUserDashboards([
              ...userDashboards,
              { alias: dashboardToAddAlias, id: dashboardToAdd },
            ]);
            setDashboardToAdd("");
          }
        });
    }
  };
  const authorizeUser = async () => {
    setAuthorizeErrorMessage("");
    if (nonAddedSelectedUser.id === "") {
      setAuthorizeErrorMessage("Please select a user");
      return;
    }
    // get all user connections
    const userConnections = await supabase
      .from("pulse")
      .select("*")
      .eq("id", Number(nonAddedSelectedUser.id))
      .then(({ data }) => {
        if (data) {
          return data[0].connections;
        }
      });
    if (userConnections.includes("looker_embed")) {
      setAuthorizeErrorMessage("User already authorized");
      return;
    }
    await supabase
      .from("pulse")
      .update({
        connections: `${userConnections},looker_embed,`,
      })
      .eq("id", Number(nonAddedSelectedUser.id))
      .then(({ error }) => {
        if (error) {
          setAuthorizeErrorMessage(error.message);
        } else {
          setAuthorizeErrorMessage("User authorized successfully");
        }
      });
    const userData = nonAddedUserList.find(
      (user) => user.id === nonAddedSelectedUser.id
    );
    if (userData) {
      const newUserList = [...userList, userData];
      setUserList(newUserList);
    }
  };

  // Make user admin

  const makeUserAdmin = async () => {
    setMakeAdminErrorMessage("");
    if (selectedUserForAdmin.id === "") {
      setMakeAdminErrorMessage("Please select a user");
      return;
    }
    // get all user connections
    const userConnections = await supabase
      .from("pulse")
      .select("*")
      .eq("id", Number(selectedUserForAdmin.id))
      .then(({ data }) => {
        if (data && data[0].connections) {
          return data[0].connections;
        }
      });
    if (userConnections.includes("looker_admin")) {
      setMakeAdminErrorMessage("User already admin");
      return;
    }
    await supabase
      .from("pulse")
      .update({
        connections: `${userConnections},looker_admin,`,
      })
      .eq("id", Number(selectedUserForAdmin.id))
      .then(({ error }) => {
        if (error) {
          setMakeAdminErrorMessage(error.message);
        } else {
          setMakeAdminErrorMessage("User admin successfully");
        }
      });
  };

  // remove dashboard from user

  const RemoveDashboardIds = async () => {
    setRemoveDashboardErrorMessage("");
    if (dashboardToRemove.length === 0) {
      setRemoveDashboardErrorMessage("Please enter a dashboard ID");
      return;
    }
    const dashboardsUpdated = userDashboards.filter(
      (dashboard) => dashboard.id !== dashboardToRemove
    );
    if (dashboardsUpdated.length === userDashboards.length) {
      setRemoveDashboardErrorMessage("Dashboard not found");
      return;
    }
    await supabase
      .from("pulse")
      .update({
        dashboards_looker: dashboardsUpdated,
      })
      .eq("id", Number(selectedUser.id))
      .select()
      .then(({ error }) => {
        if (error) {
          setRemoveDashboardErrorMessage(error.message);
        } else {
          setRemoveDashboardErrorMessage("Dashboard removed successfully");
          setUserDashboards(dashboardsUpdated);
        }
      });
  };

  const removeUserAccess = async () => {
    setRemoveUserAccessErrorMessage("");
    if (selectedUser.id === "") {
      setRemoveUserAccessErrorMessage("Please select a user");
      return;
    }
    // get all user connections
    const userConnections = await supabase
      .from("pulse")
      .select("*")
      .eq("id", Number(selectedUser.id))
      .then(({ data }) => {
        if (data && data[0].connections) {
          return data[0].connections;
        }
      });

    await supabase
      .from("pulse")
      .update({
        connections: userConnections.replace("looker_embed", ""),
      })
      .eq("id", Number(selectedUser.id))
      .then(({ error }) => {
        if (error) {
          setRemoveUserAccessErrorMessage(error.message);
        } else {
          setRemoveUserAccessErrorMessage("User access removed successfully");
        }
      });
  };

  const removeAdmin = async () => {
    setRemoveUserErrorMessage("");
    if (selectedUser.id === "") {
      setRemoveUserErrorMessage("Please select a user");
      return;
    }
    // get all user connections
    const userConnections = await supabase
      .from("pulse")
      .select("*")
      .eq("id", Number(selectedUser.id))
      .then(({ data }) => {
        if (data && data[0].connections) {
          return data[0].connections;
        }
      });
    if (!userConnections.includes("looker_admin")) {
      setRemoveUserErrorMessage("User not admin");
      return;
    }
    await supabase
      .from("pulse")
      .update({
        connections: userConnections.replace("looker_admin", ""),
      })
      .eq("id", Number(selectedUser.id))
      .then(({ error }) => {
        if (error) {
          setRemoveUserErrorMessage(error.message);
        } else {
          setRemoveUserErrorMessage("User admin removed successfully");
        }
      });
  };
  return (
    <Box color="brand.500" margin="0 auto" textAlign="center">
      <Box position="relative">
        <AnimatePresence>
          <Heading margin={"32px"} size={"md"}>
            Manage dashboards
          </Heading>

          <Box
            display={"flex"}
            flexDir={"column"}
            justifyContent={"center"}
            alignItems={"stretch"}
            padding={"32px"}
            gap={"32px"}
            marginBottom={"32px"}
          >
            <Box
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"16px"}
              border={"1px"}
              borderColor={"gray.300"}
              borderRadius={"8px"}
              padding={"16px"}
            >
              <Heading size={"md"}>Authorize new User</Heading>
              <Select
                onChange={(e) =>
                  setNonAddedSelectedUser({ id: e.target.value })
                }
              >
                {nonAddedUserList.length > 0 && (
                  <>
                    <option value={""}>Select user</option>
                    {nonAddedUserList.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.first_name} {user.last_name} ({user.email_manager}
                        )
                      </option>
                    ))}
                  </>
                )}
              </Select>
              {nonAddedSelectedUser && (
                <>
                  <Button onClick={() => authorizeUser()} colorScheme="blue">
                    Authorize user
                  </Button>
                  <Text colorScheme="blue">{authorizeErrorMessage}</Text>
                </>
              )}
            </Box>
            <Box
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"16px"}
              border={"1px"}
              borderColor={"gray.300"}
              borderRadius={"8px"}
              padding={"16px"}
            >
              <Heading size={"md"}>Authorize new Admin</Heading>
              <Select
                onChange={(e) =>
                  setSelectedUserForAdmin({ id: e.target.value })
                }
              >
                {nonAddedUserList.length > 0 && (
                  <>
                    <option value={""}>Select user</option>
                    {nonAddedUserList.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.first_name} {user.last_name} ({user.email_manager}
                        )
                      </option>
                    ))}
                    {userList.length > 0 && (
                      <>
                        {userList.map((user) => (
                          <option key={user.first_name} value={user.id}>
                            {user.first_name} {user.last_name} (
                            {user.email_manager})
                          </option>
                        ))}
                      </>
                    )}
                  </>
                )}
              </Select>
              {selectedUserForAdmin && (
                <>
                  <Button onClick={() => makeUserAdmin()} colorScheme="blue">
                    Make admin
                  </Button>
                  <Text colorScheme="blue">{makeAdminErrorMessage}</Text>
                </>
              )}
            </Box>

            <Box
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"16px"}
              border={"1px"}
              borderColor={"gray.300"}
              borderRadius={"8px"}
              padding={"16px"}
            >
              <Heading size={"md"}>Add new dashboards to user</Heading>
              <Select onChange={(e) => getDashboards(e.target.value)}>
                {userList.length > 0 && (
                  <>
                    <option value={""}>Select user</option>
                    {userList.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.first_name} {user.last_name} ({user.email_manager}
                        )
                      </option>
                    ))}
                  </>
                )}
              </Select>
              {selectedUser && (
                <>
                  <Text>
                    Current dashboards :{" "}
                    {userDashboards.length > 0
                      ? userDashboards
                          .map((dashboard) => dashboard.alias)
                          .join(", ")
                      : "None"}
                  </Text>
                  <Input
                    onChange={(e) => setDashboardToAddAlias(e.target.value)}
                    placeholder="Enter dashboard Alias ..."
                  />
                  <Input
                    onChange={(e) => setDashboardToAdd(e.target.value)}
                    placeholder="Enter dashboard ID ..."
                  />
                  <Button onClick={() => AddDashboardIds()} colorScheme="blue">
                    Load
                  </Button>
                  <Text colorScheme="blue">{errorMessage}</Text>
                </>
              )}
            </Box>
            <Box
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"16px"}
              border={"1px"}
              borderColor={"gray.300"}
              borderRadius={"8px"}
              padding={"16px"}
            >
              <Heading size={"md"}>Remove dashboard from user</Heading>
              <Select onChange={(e) => getDashboards(e.target.value)}>
                {userList.length > 0 && (
                  <>
                    <option value={""}>Select user</option>
                    {userList.map((user) => (
                      <option key={user.first_name + "user"} value={user.id}>
                        {user.first_name} {user.last_name} ({user.email_manager}
                        )
                      </option>
                    ))}
                  </>
                )}
              </Select>
              <Select onChange={(e) => setDashboardToRemove(e.target.value)}>
                {userDashboards.length > 0 && (
                  <>
                    <option key={"0"} value={""}>
                      Select dashboard
                    </option>
                    {userDashboards.map((dashboard) => (
                      <option key={dashboard.id} value={dashboard.id}>
                        {dashboard.alias}
                      </option>
                    ))}
                  </>
                )}
              </Select>
              {dashboardToRemove && (
                <>
                  <Button
                    onClick={() => RemoveDashboardIds()}
                    colorScheme="blue"
                  >
                    Remove
                  </Button>
                  <Text colorScheme="blue">{removeDashboardErrorMessage}</Text>
                </>
              )}
            </Box>
            <Box
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"16px"}
              border={"1px"}
              borderColor={"gray.300"}
              borderRadius={"8px"}
              padding={"16px"}
            >
              <Heading size={"md"}>Remove user access</Heading>
              <Select onChange={(e) => setSelectedUser({ id: e.target.value })}>
                {userList.length > 0 && (
                  <>
                    <option key={"0"} value={""}>
                      Select user
                    </option>
                    {userList.map((user) => (
                      <option key={user.first_name + "remove"} value={user.id}>
                        {user.first_name} {user.last_name} ({user.email_manager}
                        )
                      </option>
                    ))}
                  </>
                )}
              </Select>
              <Button onClick={() => removeUserAccess()} colorScheme="blue">
                Remove
              </Button>
              <Text colorScheme="blue">{removeUserAccessErrorMessage}</Text>
            </Box>
            <Box
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"16px"}
            >
              <Heading size={"md"}>Remove Admin </Heading>
              <Select onChange={(e) => setSelectedUser({ id: e.target.value })}>
                {userList.length > 0 && (
                  <>
                    <option key={"0"} value={""}>
                      Select user
                    </option>
                    {userList.map((user) => (
                      <option key={user.id + "admin"} value={user.id}>
                        {user.first_name} {user.last_name} ({user.email_manager}
                        )
                      </option>
                    ))}
                  </>
                )}
              </Select>
              <Button onClick={() => removeAdmin()} colorScheme="blue">
                Remove
              </Button>
              <Text colorScheme="blue">{removeUserErrorMessage}</Text>
            </Box>
          </Box>
        </AnimatePresence>
      </Box>
    </Box>
  );
}
