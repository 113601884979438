import { Center, Box, Heading } from "@chakra-ui/react";
import sampleResponse from "./data/sampleresponse.json";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  ResponsiveContainer,
} from "recharts";
import "./charts.css";
import { useEffect, useState } from "react";
import Card from "./Card";
const Charts = () => {
  // Data needs to be numbers without (%)
  // const [loading, setLoading] = useState(false);
  // const [explainedVariance, setExplainedVariance] = useState([{}]);
  const [barChartWidth, setBarChartWidth] = useState(800);
  // const [brandLoveScore, setBrandLoveScore] = useState([{}]);
  const [connection, setConnection] = useState([{}]);
  // const [connectionList, setConnectionList] = useState([{}]);
  // const [intriguedList, setIntriguedlist] = useState([{}]);
  // const [affectionList, setAffectionList] = useState([{}]);
  // const [passionList, setPassionList] = useState([{}]);
  // const [attractionList, setAttractionList] = useState([{}]);
  const [attraction, setAttraction] = useState([{}]);
  const [intrigue, setIntrigue] = useState([{}]);
  const [passion, setPassion] = useState([{}]);
  const [affection, setAffection] = useState([{}]);
  const [brandLoveScore, setBrandLoveScore] = useState([{}]);
  const [percentageExplainedVariance, setPercentageExplainedVariance] =
    useState([{}]);
  const [
    formatedPercentageExplainedVariance,
    setFormatedPercentageExplainedVariance,
  ] = useState([{}]);
  const [weeklyScore, setWeeklyScore] = useState([{}]);
  const [formatedWeeklyScore, setFormatedWeeklyScore] = useState([{}]);
  const [monthlyScore, setMonthlyScore] = useState([{}]);
  const [monthlyBrandLove, setMonthlyBrandLove] = useState([{}]);
  const [brandLove, setBrandLove] = useState([{}]);
  const [formatedMonthlyBrandLove, setFormatedMonthlyBrandLove] = useState([
    {},
  ]);
  const [unnestedBrandLoveAvg, setUnnestedBrandLoveAvg] = useState([{}]);
  const [formatedMonthlyScore, setFormatedMonthlyScore] = useState([{}]);
  // const [rawResponse, setRawResponse] = useState([{}]);
  // const fetchUrl = "http://localhost/3000/api/dashboard";

  // useEffect(() => {
  //   fetch(fetchUrl, {}).then((res) => {
  //     res
  //       .json()
  //       .then((data) => {
  //         console.log(data);

  //         throw new Error("Something went wrong");
  //       })
  //       .catch((err) => console.log(err));
  //   });
  // }, []);

  useEffect(() => {
    setPercentageExplainedVariance(sampleResponse.data[0].data);
    // setConnectionList(sampleResponse.data[1].data);
    // setIntriguedlist(sampleResponse.data[2].data);
    // setAffectionList(sampleResponse.data[3].data);
    // setPassionList(sampleResponse.data[4].data);
    // setAttractionList(sampleResponse.data[5].data);
    // @ts-expect-error toReversed is a function
    setWeeklyScore(sampleResponse.data[6].data.toReversed());
    setAttraction(sampleResponse.data[7].data);
    setConnection(sampleResponse.data[8].data);
    setBrandLoveScore(sampleResponse.data[9].data);
    setPassion(sampleResponse.data[10].data);
    setIntrigue(sampleResponse.data[11].data);
    setAffection(sampleResponse.data[12].data);
    // there is one untitled data being returned which is why one element is skipped
    setMonthlyScore(sampleResponse.data[14].data);
    setMonthlyBrandLove(sampleResponse.data[15].data);
    setBrandLove(sampleResponse.data[16].data);
  }, []);

  // formatDates

  useEffect(() => {
    const extractedDates = unnestObject(
      monthlyBrandLove,
      "normalized_inference_scores_pop.timestamp_month"
    );
    const extractedValues = unnestObject(
      monthlyBrandLove,
      "normalized_inference_scores_pop.average_score"
    );
    const object = extractedDates.map((item, index) => ({
      timeStamp: parseMonthDate(item + "-01"),
      "Average Score": extractedValues[index],
    }));
    setFormatedMonthlyBrandLove(object);
  }, [monthlyBrandLove]);

  useEffect(() => {
    const extractedDates = unnestObject(
      weeklyScore,
      "normalized_inference_scores_pop.timestamp_week"
    );
    const extractedValues = unnestObject(
      weeklyScore,
      "normalized_inference_scores_pop.average_score"
    );
    const object = extractedDates.map((item, index) => ({
      // @ts-expect-error it returns a date not an object
      timeStamp: parseMonthDate(item),
      "Average Score": extractedValues[index],
    }));
    setFormatedWeeklyScore(object);
  }, [weeklyScore]);

  useEffect(() => {
    const extractedDates = unnestObject(
      monthlyScore,
      "normalized_inference_scores_pop.timestamp_month"
    );
    const extractedValues = unnestObject(
      monthlyScore,
      "normalized_inference_scores_pop.average_score"
    );
    const object = extractedDates.map((item, index) => ({
      timeStamp: parseMonthDate(item + "-01"),
      "Average Score": extractedValues[index],
    }));
    setFormatedMonthlyScore(object);
  }, [monthlyScore]);

  // Fix percentage for better display of data
  useEffect(() => {
    const extractedVarianceName = unnestObject(
      percentageExplainedVariance,
      "explained_variance.pc_renamed"
    );
    const extractedpercentages = unnestObject(
      percentageExplainedVariance,
      "explained_variance.total_explained_variance"
    );
    const fixedResultingData = extractedVarianceName.map((item, index) => ({
      varianceName: item,
      // @ts-expect-error it returns a number not an object
      "Explained Variance": parseFloat(extractedpercentages[index]) * 100,
    }));
    setFormatedPercentageExplainedVariance(fixedResultingData);
  }, [percentageExplainedVariance]);

  // Fix overly nested data && format dates
  useEffect(() => {
    const unnested = unnestObject(
      brandLove,
      "normalized_inference_scores_pop.average_score/normalized_inference_scores_pop.pc_renamed/-- Brand Love Score (Weighted)"
    );
    const unnestedTimeStamp = unnestObject(
      brandLove,
      "normalized_inference_scores_pop.timestamp_week"
    );
    const parsedTimeStamps = unnestedTimeStamp.map((item) => {
      // @ts-expect-error it returns a date not an object

      return parseMonthDate(item);
    });
    const object = unnested.map((item, index) => ({
      timeStamp: parsedTimeStamps[index],
      "Brand Love Score": item,
    }));
    // @ts-expect-error toReversed is a function
    setUnnestedBrandLoveAvg(object.toReversed());
  }, [brandLove]);

  const parseMonthDate = (date: string) => {
    const parsedDate = new Date(date);
    return parsedDate.toLocaleDateString("en-US", {
      month: "short",
    });
  };

  const unnestObject = (dataSet: object[], path: string) => {
    const parsedPath = path.split("/");
    return dataSet.map((item) => {
      let current = item;
      parsedPath.forEach((key) => {
        // @ts-expect-error it is correct
        current = current?.[key];
      });
      return current;
    });
  };

  // useEffect(() => {
  //   const updatedData = percentageExplainedVariance.map((item) => {
  //     return {
  //       ...item,
  //       "Explained Variance": parseFloat(item["Explained Variance"]),
  //     };
  //   });
  //   console.log(updatedData);
  //   setExplainedVariance(updatedData);
  // }, []);
  useEffect(() => {
    const width = window.innerWidth;
    if (width < 800) {
      setBarChartWidth(375);
    }
  }, []);

  return (
    <Center color="brand.500">
      <Box textAlign="center">
        {
          <>
            <Box className="card-container">
              <Card data={brandLoveScore} title={"Brand Love Score"}></Card>
              <Card data={connection} title={"Connection"}></Card>
              <Card data={attraction} title="Attraction"></Card>
              <Card data={intrigue} title={"Intrigue"}></Card>
              <Card data={passion} title={"Passion"}></Card>
              <Card data={affection} title={"Affection"}></Card>
            </Box>
            <Box id="container">
              <Box className="chart-container">
                <Heading size={"md"}>Weekly Score Trend</Heading>
                <LineChart width={375} height={400} data={formatedWeeklyScore}>
                  <Line
                    type={"monotone"}
                    strokeWidth={3}
                    dataKey={"Average Score"}
                  ></Line>
                  <XAxis
                    interval={"equidistantPreserveStart"}
                    fontSize={20}
                    dataKey={"timeStamp"}
                    stroke="#224580"
                    strokeWidth={2}
                  ></XAxis>
                  <YAxis
                    fontSize={20}
                    stroke="#224580"
                    scale={"linear"}
                    strokeWidth={2}
                    domain={["dataMin - 10", "dataMax + 10"]}
                  />
                  Average Score (%)
                </LineChart>
              </Box>
              <Box className="chart-container">
                <Heading size={"md"}>Monthly Score Trend</Heading>
                <LineChart width={375} height={400} data={formatedMonthlyScore}>
                  <Line
                    type={"monotone"}
                    strokeWidth={3}
                    dataKey={"Average Score"}
                  ></Line>
                  <XAxis
                    stroke="#224580"
                    fontSize={20}
                    dataKey={"timeStamp"}
                    strokeWidth={2}
                  ></XAxis>
                  <YAxis
                    stroke="#224580"
                    fontSize={20}
                    scale={"linear"}
                    strokeWidth={2}
                    domain={["dataMin - 10", "dataMax + 10"]}
                  />
                  Average Score (%)
                </LineChart>
              </Box>
              <Box className="chart-container">
                <Heading size={"md"}>
                  Brand Love Score <br /> Weighted Average
                </Heading>
                <LineChart width={375} height={400} data={unnestedBrandLoveAvg}>
                  <Line
                    type={"monotone"}
                    strokeWidth={3}
                    dataKey={"Brand Love Score"}
                  ></Line>
                  <XAxis
                    fontSize={20}
                    dataKey={"timeStamp"}
                    stroke="#224580"
                    strokeWidth={2}
                  ></XAxis>
                  <YAxis
                    fontSize={20}
                    stroke="#224580"
                    scale={"linear"}
                    strokeWidth={2}
                    domain={["dataMin - 10", "dataMax + 10"]}
                  />
                  Average Score (%)
                </LineChart>
              </Box>
              <Box className="chart-container">
                <Heading size={"md"}>
                  Brand Love Monthly Trend <br /> Weighted Average
                </Heading>
                <LineChart
                  width={375}
                  height={400}
                  data={formatedMonthlyBrandLove}
                >
                  <Line
                    type={"monotone"}
                    strokeWidth={3}
                    dataKey={"Average Score"}
                  ></Line>
                  <XAxis
                    fontSize={20}
                    stroke="#224580"
                    dataKey={"timeStamp"}
                    strokeWidth={2}
                  ></XAxis>
                  <YAxis
                    scale={"linear"}
                    fontSize={20}
                    stroke="#224580"
                    strokeWidth={2}
                    domain={["dataMin - 10", "dataMax + 10"]}
                  />
                  Average Score (%)
                </LineChart>
              </Box>

              <Box width={"100%"} className="chart-container">
                <Heading size={"md"}>Percentage Of Explained Variance</Heading>
                <ResponsiveContainer width={"80%"} height={400} minWidth={375}>
                  <BarChart
                    width={barChartWidth}
                    height={400}
                    data={formatedPercentageExplainedVariance}
                  >
                    <XAxis
                      stroke="#224580"
                      strokeWidth={2}
                      fontSize={20}
                      dataKey="varianceName"
                    />
                    <YAxis
                      stroke="#224580"
                      fontSize={20}
                      strokeWidth={2}
                      // domain={[0, "dataMax + 5"]}
                      unit={"%"}
                    />
                    <Bar
                      dataKey="Explained Variance"
                      minPointSize={0}
                      fill="#3d6bab"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Box>
          </>
        }
      </Box>
    </Center>
  );
};

export default Charts;
