
import { extendTheme } from '@chakra-ui/react'

const colorsBrand = {
    'san-marino': {
        '50': '#f2f8fa', 
        '100': '#e9f3f7', 
        '200': '#c5ddeb', 
        '300': '#a4c6de', 
        '400': '#6c98c4', 
        '500': '#3d6bab', 
        '600': '#315b99', 
        '700': '#224580', 
        '800': '#163166', 
        '900': '#0c204d', 
        '950': '#051030'
    },
    'ochre': {
        '50': '#fcfaf2', 
        '100': '#fcf6e8', 
        '200': '#f7e7c6', 
        '300': '#f2d6a5', 
        '400': '#ebaf67', 
        '500': '#e1832e', 
        '600': '#c96f24', 
        '700': '#a85519', 
        '800': '#873c10', 
        '900': '#662809', 
        '950': '#421704'
    },
    'mystic': {
        '50': '#ffffff', 
        '100': '#fcffff', 
        '200': '#f7fcfc', 
        '300': '#f0f8fa', 
        '400': '#e9f4f7', 
        '500': '#deeef4', 
        '600': '#b4cfdb', 
        '700': '#7da2b8', 
        '800': '#517894', 
        '900': '#2d4f6e', 
        '950': '#132b47'
    },
    'half-baked': {
        '50': '#f7fcfc', 
        '100': '#f2f9fa', 
        '200': '#dff0f2', 
        '300': '#cae7eb', 
        '400': '#a7d3db', 
        '500': '#87c1cd', 
        '600': '#6ca9b8', 
        '700': '#4b8499', 
        '800': '#31627a', 
        '900': '#1c425c', 
        '950': '#0b243b'
    }
};


export const theme = extendTheme({
    colors: {
        ...colorsBrand,

        brand: colorsBrand['san-marino'],
        primary: colorsBrand['half-baked'],
        secondary: colorsBrand['ochre'],
    }
  })