import { createBrowserRouter, redirect } from "react-router-dom";

import { fakeAuthProvider } from "./auth";

import Connector from "../pages/Connector";
import Contact from "../pages/Contact";
import CreateConnector from "../pages/Connector/Create";
import Dashboard from "../pages/Dashboard";
import Faq from "../pages/Faq";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import NotFound from "../pages/NotFound";
import ResetPassword from "../pages/ResetPassword";
import UpdatePasswordScreen from "../pages/UpdatePassword";
import Welcome from "../pages/Welcome";
import Tracker from "../pages/Tracker";
import Publishers from "../pages/Publishers";
import Looker from "../pages/Looker";
import ChartsPage from "src/pages/Charts";
import AddDashboardIdsPage from "src/pages/Looker/AddDashboardIds";

export const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    loader() {
      // Our root route always provides the user, if logged in
      return { user: fakeAuthProvider.username };
    },
    // Component: Layout,
    children: [
      {
        index: true,
        Component: Login,
      },
      {
        path: "login",
        // action: loginAction,
        // loader: loginLoader,
        Component: Login,
      },
      //   {
      //     path: "protected",
      //     loader: protectedLoader,
      //     Component: ProtectedPage,
      //   },
    ],
  },
  {
    path: "/looker",
    children: [
      {
        index: true,
        Component: Looker,
      },
    ],
  },
  {
    path: "/charts",
    children: [
      {
        index: true,
        Component: ChartsPage,
      },
    ],
  },
  {
    path: "/looker/manage",
    children: [
      {
        index: true,
        Component: AddDashboardIdsPage,
      },
    ],
  },
  {
    path: "/connections",
    children: [
      {
        index: true,
        Component: Dashboard,
      },
    ],
  },
  {
    path: "/faq",
    children: [
      {
        index: true,
        Component: Faq,
      },
    ],
  },
  {
    path: "/connector",
    children: [
      {
        index: true,
        Component: Connector,
      },
      {
        path: "create/:connectorId",
        Component: CreateConnector,
      },
    ],
  },
  {
    path: "/contact",
    children: [
      {
        index: true,
        Component: Contact,
      },
    ],
  },
  {
    path: "/reset-password",
    children: [
      {
        index: true,
        Component: ResetPassword,
      },
    ],
  },
  {
    path: "/update-password",
    children: [
      {
        index: true,
        Component: UpdatePasswordScreen,
      },
    ],
  },
  {
    path: "/logout",
    children: [
      {
        index: true,
        Component: Logout,
      },
    ],
  },
  {
    path: "/welcome",
    children: [
      {
        index: true,
        Component: Welcome,
      },
    ],
  },
  {
    path: "/tracker",
    children: [
      {
        index: true,
        Component: Tracker,
      },
    ],
  },
  {
    path: "/publisher",
    children: [
      {
        index: true,
        Component: Publishers,
      },
    ],
  },
  {
    path: "*", // 404
    Component: NotFound,
    loader() {
      if (location.pathname.includes("//")) {
        console.log("redirecting...", location.pathname.replaceAll("//", "/"));
        return redirect(location.pathname.replaceAll("//", "/"));
      }

      return {};
    },
  },
]);
