import {
  FaFacebookF,
  FaGoogleDrive,
  FaHubspot,
  FaInstagram,
  FaTiktok,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";

import {
  SiFacebook,
  SiGoogleads,
  SiGoogleanalytics,
  SiLinkedin,
  SiOracle,
} from "react-icons/si";

export const mapConnectorToIcon: Record<string, unknown> = {
  eloqua: SiOracle,
  facebook_ads: SiFacebook,
  facebook_pages: FaFacebookF,
  facebook: FaFacebookF,
  google_ads: SiGoogleads,
  google_analytics_4: SiGoogleanalytics,
  google_analytics: SiGoogleanalytics,
  google_drive: FaGoogleDrive,
  google_sheets: FaGoogleDrive,
  hubspot: FaHubspot,
  instagram_business: FaInstagram,
  linkedin_company_pages: SiLinkedin,
  tiktok_ads: FaTiktok,
  twitter_ads: FaTwitter,
  twitter: FaTwitter,
  youtube_analytics: FaYoutube,
};

export const mapConnectorToColor: Record<string, unknown> = {
  incomplete: "primary.700",
  completed: "brand.500",
  connected: "brand.500",
};

export const getIconAndColor = (
  service: string = "facebook",
  status: string = "incomplete"
) => {
  const connectorIcon = mapConnectorToIcon[service] as React.ElementType;
  const connectorColor = mapConnectorToColor[status] as string;

  return { connectorIcon, connectorColor };
};
