import { Box, Grid, Heading, Stack, Select } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { presets } from "../utils";

export default function Presets() {
  const { values, resetForm } = useFormikContext();

  const updatePresetOptions = (value) => {
    const presetOptions = presets.find((preset) => {
      return Object.keys(preset)[0] === value;
    });

    if (presetOptions) {
      resetForm({ values: { ...values, ...Object.values(presetOptions)[0] } });
    }
  };

  return (
    <Box minW="0" maxW="100%" mt="24px">
      <Grid templateColumns="repeat(auto-fill, minmax(240px, 1fr))" gap="24px">
        <Box>
          <Heading fontSize="lg">Presets</Heading>
          <Stack p="8px">
            <Select
              placeholder="Select a preset"
              bg="white"
              onChange={(e) => {
                updatePresetOptions(e.target.value);
              }}
            >
              <option value="amazon">Amazon Preset</option>
              <option value="priceline">Priceline Preset</option>
              <option value="expedia">Expedia Preset</option>
              <option value="tripadvisor">TripAdvisor Preset</option>
            </Select>
          </Stack>
        </Box>
      </Grid>
    </Box>
  );
}
