import { Box, Text, Flex, Select } from '@chakra-ui/react';

import { FastField } from 'formik';

import { mapMatchFields, options } from '../utils';

export default function SelectInput(props) {
    const name = props.name;
    const objectField = mapMatchFields.find((f) => f.key === name);

    return (
        <FastField name={name}>
            {({ field }) => {
                const isSelected = field?.value !== '';

                return (
                    <Box>
                        <Flex
                            alignItems="center"
                            title={
                                objectField.isRequired
                                    ? 'This field is required'
                                    : ''
                            }
                        >
                            <Text>{objectField.field}</Text>

                            {objectField.isRequired && (
                                <Text color="red.500" mx="4px">
                                    *
                                </Text>
                            )}
                        </Flex>

                        <Select
                            {...field}
                            bg={isSelected ? `submarine.100` : `gray.100`}
                            title={props.title || ''}
                        >
                            <option value={-1} key="-1">
                                -- N/A --
                            </option>
                            {options &&
                                options.map((op, index) => {
                                    return (
                                        <option value={index} key={index}>
                                            {op}
                                        </option>
                                    );
                                })}
                        </Select>
                    </Box>
                );
            }}
        </FastField>
    );
}
