import { Box, Heading, Text } from "@chakra-ui/react";
// @ts-expect-error data
const Card = ({ data, title }) => {

  return (
    <Box className="card">
      <Heading size={"lg"}>
        {data[0] &&
          data[0]["normalized_inference_scores_pop.average_score"].toFixed(2)}
      </Heading>
      <Text fontSize={"lg"} size={"md"}>
        {title}
      </Text>
      <Text>
        {data[0] &&
        data[0][
          "percent_change_from_previous_normalized_inference_scores_pop_average_score"
        ] < 0 ? (
          <Text fontSize={"lg"} color={"red"}>
            {(
              data[0][
                "percent_change_from_previous_normalized_inference_scores_pop_average_score"
              ] * 100
            ).toFixed(2)}{" "}
            Change
          </Text>
        ) : (
          <Text size={"lg"} color={"green"}>
            {(
              data[0][
                "percent_change_from_previous_normalized_inference_scores_pop_average_score"
              ] * 100
            ).toFixed(2)}{" "}
            Change
          </Text>
        )}
      </Text>
    </Box>
  );
};

export default Card;
