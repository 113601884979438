import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";

import { supabase } from "../../services/supabase";

import Pulse from "../../assets/pulse.svg";
import background from "../../assets/background.jpg";

const getURI = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const URI = urlParams.get("url");

  return URI;
};

export default function Welcome() {
  const [hasInvitation, setHasInvitation] = useState<boolean>(false);

  useEffect(() => {
    const URI = getURI();

    if (!URI) return;

    setHasInvitation(true);
  }, []);

  const handleAcceptInvitation = async () => {
    const { data } = await supabase.auth.getSession();

    if (data?.session?.user?.id) {
      const { error } = await supabase.from("pulse").insert({
        userId: data?.session?.user?.id,
        first_name: "new user!",
        last_name: "new user!",
      });

      console.log({ error });
    }

    const URI = getURI();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore - window.location.href is a string
    window.location.href = URI;
  };

  return (
    <Stack
      backgroundImage={background}
      direction={{ base: "column", md: "row" }}
      minH={"100vh"}
    >
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={4} w={"full"} maxW={"md"} mt={12}>
          <Image
            height={110}
            maxWidth="448px"
            my={{ base: "8px", md: "32px" }}
            objectFit="scale-down"
            pr={{ base: 1, md: 10 }}
            src={Pulse}
          />

          {!hasInvitation && (
            <Box color="brand.700" textAlign="center">
              <Heading size="lg" mb={8}>
                Sorry!
                <br /> Look like you don't have an invitation
              </Heading>

              <Text
                as="a"
                href="https://www.theshipyard.com/contact"
                title="click here to contact"
              >
                Please contact with pulse team!
              </Text>
            </Box>
          )}

          {hasInvitation && (
            <>
              <Box mb={8}>
                <Heading
                  size={"xl"}
                  color="brand.700"
                  mb={8}
                  textAlign="center"
                >
                  Welcome to PULSE
                </Heading>

                <Heading size="sm" color="brand.600" mb={2}>
                  Please note:
                </Heading>

                <UnorderedList spacing={2} color="brand.600">
                  <ListItem>
                    This invitation is valid once, and will expire when you
                    click on accept invitation button.
                  </ListItem>
                  <ListItem>
                    For security reasons, you need to update your password.
                  </ListItem>
                </UnorderedList>
              </Box>

              <Stack spacing={6}>
                <Button
                  colorScheme={"secondary"}
                  onClick={handleAcceptInvitation}
                  type="submit"
                  variant={"solid"}
                >
                  Accept Invitation
                </Button>
              </Stack>
            </>
          )}
        </Stack>
      </Flex>
    </Stack>
  );
}
