import { Container, Heading, Stack, Text, Button } from "@chakra-ui/react";

export default function NotFound() {
  return (
    <Container maxW={"5xl"}>
      <Stack
        textAlign={"center"}
        align={"center"}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 20, md: 28 }}
      >
        <Heading
          fontWeight={600}
          fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
          lineHeight={"110%"}
        >
          Sorry,{" "}
          <Text as={"span"} color={"secondary.500"}>
            we couldn't find this page.
          </Text>
        </Heading>
        <Text color={"brand.700"} maxW={"3xl"}>
          We're really sorry, but the page you were looking for seems to have
          disappeared or never existed in the first place. It's possible that
          the link is broken, the URL was mistyped, or the page has been moved
          to a new location.
        </Text>

        <Stack spacing={6} direction={"row"}>
          <Button
            _hover={{ bg: "orange.500" }}
            as="a"
            bg={"orange.400"}
            colorScheme={"secondary"}
            href={"/tracker"}
            px={6}
            rounded={"full"}
          >
            Go to Home
          </Button>
          <Button
            rounded={"full"}
            px={6}
            as="a"
            target="_blank"
            href="https://www.theshipyard.com/contact"
          >
            Contact Us
          </Button>
        </Stack>

        <Text color={"brand.500"} maxW={"md"} size="sm">
          We apologize for any inconvenience this may have caused, and we
          appreciate your patience as we work to keep our space tidy and
          navigable. <br /> Thank you for visiting, and happy exploring!
        </Text>
      </Stack>
    </Container>
  );
}
