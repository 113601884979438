import { Box, Button, Heading, Link, Select, Text } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { supabase } from "../../services/supabase";

const VITE_NODE_URL = import.meta.env.VITE_NODE_URL;
// Embed ID
import "./looker.css";

export default function LookerEmbed() {
  const [loading, setLoading] = useState(true);
  const [userAuthorized, setUserAuthorized] = useState(false);
  const [userAdmin, setUserAdmin] = useState(false);
  const [beingRedirected, setBeingRedirected] = useState(false);
  const [userDashboards, setUserDashboards] = useState([
    { id: "1", alias: "test" },
  ]);
  const [embedId, setEmbedId] = useState(0);
  const [embedUrl, setEmbedUrl] = useState("");

  useEffect(() => {
    supabase.auth.getUser().then(({ data }) => {
      if (data.user) {
        supabase
          .from("pulse")
          .select("*")
          .eq("userId", data.user.id)
          .then(({ data }) => {
            if (data && data[0].connections.includes("looker_admin")) {
              setUserAdmin(true);
              setUserAuthorized(true);
            }
            if (
              (data && data[0].connections.includes("looker_embed")) ||
              (data && data[0].connections.includes("looker_admin"))
            ) {
              setUserAuthorized(true);
              setUserDashboards(data[0].dashboards_looker);
            } else {
              setBeingRedirected(true);
              setTimeout(() => {
                location.href = "/tracker";
              }, 5000);
            }
          });
      }
    });
  }, []);
  useEffect(() => {
    if (userAuthorized && userDashboards && userDashboards.length === 1) {
      setEmbedId(Number(userDashboards[0].id));
      setLoading(true);
      fetch(`${VITE_NODE_URL}/${embedId}`).then((res) => {
        res
          .json()
          .then((data) => {
            console.log(data);
            if (data.url_embed && userAuthorized) {
              setLoading(false);
              setEmbedUrl(data.url_embed);
            }
            throw new Error("Something went wrong");
          })
          .catch((err) => console.log(err));
      });
    }
  }, [embedId, userAuthorized, userDashboards]);

  const checkEmbedId = async () => {
    setLoading(true);
    await fetch(`${VITE_NODE_URL}/${embedId}`).then((res) => {
      res
        .json()
        .then((data) => {
          console.log(data);
          if (data.url_embed && userAuthorized) {
            setLoading(false);
            setEmbedUrl(data.url_embed);
          }
          throw new Error("Something went wrong");
        })
        .catch((err) => console.log(err));
    });
  };

  return (
    <Box color="brand.500" margin="0 auto" textAlign="center">
      <Box position="relative">
        <AnimatePresence>
          {userAdmin && (
            <Box margin={"32px"}>
              <Link
                padding={"16px"}
                borderRadius={"8px"}
                backgroundColor={"brand.200"}
                href="/looker/manage"
              >
                Access Admin Panel
              </Link>
            </Box>
          )}
          {beingRedirected && !userAuthorized && (
            <Text
              as={motion.p}
              fontSize={"large"}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { duration: 0.5 } }}
            >
              You are being redirected to the home page... <br />
              If you think you should be able to see this page, please contact
              us.
            </Text>
          )}
          {userAuthorized && !userDashboards && (
            <Box>
              <Heading size={"md"}>
                No dashboards found, please contact us if you think this is
                incorrect.
              </Heading>
            </Box>
          )}
          {userAuthorized && userDashboards && userDashboards.length > 1 && (
            <Box
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"16px"}
              marginBottom={"32px"}
            >
              <Heading size={"md"}>Select your dashboard</Heading>
              <Box
                display={"flex"}
                flexDir={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={"16px"}
              >
                <Select
                  maxWidth={"250px"}
                  onChange={(e) => setEmbedId(parseInt(e.target.value))}
                  placeholder="Select dashboard ..."
                >
                  {userDashboards.map((dashboard) => (
                    <option key={dashboard.id} value={dashboard.id}>
                      {dashboard.alias}
                    </option>
                  ))}
                </Select>
                <Button colorScheme="blue" onClick={() => checkEmbedId()}>
                  Load
                </Button>
              </Box>
            </Box>
          )}
          {!loading && (
            <Box
              className="embed"
              width={{
                base: "100% - 16px",
                sm: "100% - 16px",
                md: "calc(100vw - 280px)",
              }}
              height="calc(100vh - 120px)"
            >
              <motion.iframe
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                src={embedUrl}
                width="100%"
                height={"100%"}
              ></motion.iframe>
            </Box>
          )}
        </AnimatePresence>
      </Box>
    </Box>
  );
}
