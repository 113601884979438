import {
  Box,
  Heading,
  ListItem,
  UnorderedList,
  Text,
  Flex,
} from "@chakra-ui/react";

import Layout from "src/components/Layout";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore 
import UploadCSV from "src/components/UploadCSV";
import HelpButton from "src/components/UploadCSV/components/HelpButton";
// import Loading from "src/components/Loading";

export default function Publishers() {
  return (
    <Layout>
      <Box px="8px">
        <Heading color="brand.700">Publishers</Heading>
        <Text color="brand.500">Upload your report file</Text>

        <Flex py={4} justifyContent='space-between'>
          <Box>
            <Text> Use Our Presets for Easier Processing!</Text>
            <UnorderedList>
              <ListItem>
                Remember to upload your files in CSV or XLSX format to ensure
                compatibility.
              </ListItem>

              <ListItem>
                Explore our presets for streamlined and efficient data handling.
              </ListItem>
            </UnorderedList>
          </Box>

          <HelpButton />
        </Flex>

        <Box my={8}>
          <UploadCSV />
        </Box>
      </Box>
    </Layout>
  );
}
