import axios from "axios";

class ApiClient {
  constructor(baseUrl, apiKey, apiSecret) {
    this.auth = { username: apiKey, password: apiSecret };
    this.baseUrl = baseUrl;
  }
  async getMetadata() {
    return await this.executePaged(`get`, `/metadata/connectors`, 200);
  }

  async getListOfConnectorsInGroup(group) {
    return await this.executePaged(`get`, `/groups/${group}/connectors`, 1000);
  }

  async getConnector(id) {
    const responseData = await this.execute(`get`, `/connectors/${id}`, null);
    return responseData.data;
  }

  async createConnector(service, group, name) {
    const connector_ft_name = name.replace(/ /g, "_").toLowerCase();
    console.log("Connector schema name: " + connector_ft_name);

    let customConfig = {};

    if (["google_analytics_4", "google_analytics"].includes(service)) {
      const isGa4 = service === "google_analytics_4";
      
      customConfig = {
        schema: isGa4 ? "ga4" : "ga",
        schema_prefix: isGa4 ? "ga4" : "ga",
        timeframe_months: "ALL_TIME",
        reports: [
          {
            table: "site_acquisition",
            config_type: "CUSTOM",
            dimensions: [
              "date",
              "deviceCategory",
              "sessionGoogleAdsAdGroupID",
              "sessionCampaignName",
              "sessionManualTerm",
              "sessionSourceMedium",
            ],
            metrics: [
              "engagedSessions",
              "screenPageViews",
              "sessions",
              "userEngagementDuration",
            ],
          },
          {
            table: "site_conversions",
            config_type: "CUSTOM",
            dimensions: [
              "date",
              "deviceCategory",
              "sessionGoogleAdsAdGroupID",
              "sessionCampaignName",
              "sessionManualTerm",
              "sessionSourceMedium",
              "eventName",
            ],
            metrics: ["conversions"],
          },
        ],
      };
    }

    if (["instagram_business"].includes(service)) {
      customConfig = {
        timeframe_months: "TWENTY_FOUR",
      };
    }

    const responseData = await this.execute(`post`, `/connectors`, {
      service: service,
      group_id: group,
      config: {
        schema: connector_ft_name,
        // table: connector_ft_name, //deprecated
        schema_prefix: connector_ft_name,
        ...customConfig,
      },
    });
    let result = responseData.data;
    result.display_name = name;
    return result;
  }

  async deleteConnector(connectorId) {
    const responseData = await this.execute(
      `delete`,
      `/connectors/${connectorId}`,
      null
    );

    return responseData;
  }

  async getConnectCardTokenForConnector(id) {
    const responseData = await this.execute(
      `post`,
      `/connectors/${id}/connect-card-token`,
      {}
    );
    return responseData.token;
  }

  async execute(method, path, data) {
    if (data) console.log(data);
    const response = await axios({
      method: method,
      baseURL: this.baseUrl,
      url: path,
      auth: this.auth,
      data: data,
    });

    if (response.status >= 400) throw Error(response.data);

    return response.data;
  }

  async executePaged(method, path, limit) {
    console.log(`${method}, ${path}`);
    const response = await axios({
      method: method,
      baseURL: this.baseUrl,
      url: path,
      auth: this.auth,
      params: {
        limit: limit,
      },
    });
    let cursor = response.data.data.next_cursor;
    let result = response.data.data.items.slice();
    while (cursor) {
      const response = await axios({
        method: method,
        baseURL: this.baseUrl,
        url: path,
        auth: this.auth,
        params: {
          limit: limit,
          cursor: cursor,
        },
      });

      if (response.status >= 400) {
        throw Error(response.data);
      }
      cursor = response.data.data.next_cursor;
      result = result.concat(response.data.data.items);
    }
    return result;
  }
}

export default ApiClient;
