import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Icon,
  VStack,
} from "@chakra-ui/react";

import { FiTrash, FiEdit } from "react-icons/fi";

import { getIconAndColor } from "../../utils/icons";
import { getServiceName } from "../../utils/connectors";

interface Props {
  connector: any;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
}

export default function ConnectionCard(props: Props) {
  const { connector, onDelete, onEdit } = props;

  const { connectorIcon, connectorColor } = getIconAndColor(
    connector?.service,
    connector?.status?.setup_state
  );

  return (
    <VStack>
      <Box m="0 auto" height="128px">
        <Icon
          as={connectorIcon as React.ElementType}
          bg={connectorColor as string}
          color="white"
          m="0 auto"
          p={5}
          w={28}
          h={28}
          style={{
            clipPath:
              "polygon(0 20%, 50% 0, 100% 20%, 100% 80%, 50% 100%, 0 80%)",
          }}
        />
      </Box>

      <Heading
        autoCapitalize="true"
        color={connectorColor as string}
        size="md"
        textTransform={"capitalize"}
      >
        {getServiceName(connector?.service)}
      </Heading>

      <Box m="0 auto">
        <ButtonGroup spacing="2">
          <Button
            color={connectorColor as string}
            colorScheme="brand"
            cursor="pointer"
            onClick={() => onEdit(connector.id)}
            size="lg"
            title="edit"
            variant="ghost"
            _hover={{
              color: "brand.600",
            }}
          >
            <FiEdit />
          </Button>

          <Button
            color={connectorColor as string}
            colorScheme="brand"
            cursor="pointer"
            onClick={() => onDelete(connector.id)}
            size="lg"
            title="delete"
            variant="ghost"
            _hover={{
              color: "red.500",
            }}
          >
            <FiTrash />
          </Button>
        </ButtonGroup>
      </Box>
    </VStack>
  );
}
