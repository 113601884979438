import { useEffect, useState } from "react";
import {
  Button,
  Heading,
  Image,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";

import Layout from "../../../components/Layout";
import Loading from "../../../components/Loading";

import { useParams } from "react-router-dom";
import { getUrlToken, createConnector } from "../../../services/fivetran";
import { getServiceById } from "../../../services/fivetranServices";
import { useUserStore } from "../../../store/userStore";

interface Service {
  id: string;
  name: string;
  icon_url: string;
  description: string;
}

export default function CreateConnector() {
  const { getDestinationId } = useUserStore();
  const { connectorId } = useParams<{ connectorId: string }>();
  const toast = useToast();

  const [service, setService] = useState<Service | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleAgregateConnector = async () => {
    if (!connectorId) return;

    const serviceId = service?.id;
    if (!serviceId) return;

    // const name = String(nameRef.current?.value ?? "").toLowerCase();
    const name = undefined;

    const group = getDestinationId();
    if (!group) return;

    toast({
      title: "Creating new connector...",
      description: "Please wait a moment.",
      status: "info",
      duration: 5000,
      isClosable: true,
    });

    const newConnector = await createConnector(connectorId, group, name);

    // console.log("newConnector: ", { newConnector });
    // return;

    const uri = await getUrlToken(newConnector.id);
    const redirectUri = window.location.origin;

    toast({
      title: "New connector created!",
      description: "We going to redirect to our parther to finish the process.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });

    // console.log('url: ',  `${uri}&redirect_uri=${redirectUri}/dashboard/`);

    window.location.href = `${uri}&redirect_uri=${redirectUri}/connections/`;
  };

  useEffect(() => {
    if (!connectorId) {
      return;
    } else {
      const res = getServiceById(connectorId) as Service | null;

      if (res) {
        setService(res);
      }
    }

    setIsLoading(false);
  }, [connectorId]);

  return (
    <Layout>
      <>
        {isLoading && <Loading />}

        {!isLoading && !service && <Heading>Service not found</Heading>}

        {!isLoading && service && (
          <VStack px={8} spacing={2}>
            <Image src={service?.icon_url} width={32} height={32} />

            <Heading color="primary.900" size="md">
              {service?.name}
            </Heading>

            <Text color="primary.900">{service.description}</Text>
            <Heading
              size="xs"
              color="primary.900"
              // textAlign="center"
              width="100%"
            >
              Please select the appropriate Account and Properties you wish to
              collect.
            </Heading>

            {/* <Input
              placeholder="Connector Name"
              bg="white"
              ref={nameRef}
              required={true}
              mt={2}
            /> */}

            <Button
              mt={8}
              size="lg"
              colorScheme="brand"
              onClick={handleAgregateConnector}
            >
              Create Connector
            </Button>
          </VStack>
        )}
      </>
    </Layout>
  );
}
