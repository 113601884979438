import { useDropzone } from 'react-dropzone';

import { Box, Flex, Stack, Text, Icon, Button } from '@chakra-ui/react';

import { FaFileUpload } from 'react-icons/fa';

export default function Dropzone(props) {
    const { getInputProps, getRootProps, isDragActive } = useDropzone({
        ...props,
    });

    console.log(getInputProps());

    return (
        <Box
            {...getRootProps()}
            bg={isDragActive ? `blue.50` : undefined}
            border="2px dashed"
            borderColor={isDragActive ? `blue.500` : `gray.300`}
            borderRadius="md"
            outline="none"
            _focus={{
                borderColor: `blue.500`,
            }}
        >
            <input {...getInputProps()} />

            <Flex align="center" direction="column" px="2" py="6">
                <Icon color="brand.800" as={FaFileUpload} fontSize="32px" />

                <Stack mt="4" spacing="1" textAlign="center">
                    <Text color="brand.600">Drag & Drop your file here</Text>
                    <Text color="brand.800" fontWeight="bold">
                        OR
                    </Text>

                    <Button color="brand.600" cursor="pointer" fontSize="sm">
                        Browse Files
                    </Button>
                </Stack>
            </Flex>
        </Box>
    );
}
