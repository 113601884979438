import { useState } from "react";
import axios from "axios";

import {
  Box,
  Button,
  Grid,
  Heading,
  Flex,
  FormControl,
  //   FormErrorMessage,
  Table,
  Tbody,
  Tr,
  Td,
  Checkbox,
  Stack,
  HStack,
  useToast,
  Image,
  Text,
  Select,
} from "@chakra-ui/react";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";

import { Formik, Form, FastField } from "formik";
import prettyBytes from "pretty-bytes";

import PreviewButton from "./components/PreviewButton";
import ButtonMatchFields from "./components/MatchFields";

import Dropzone from "../Dropzone";
import SelectInput from "./components/Select";

import NotifyImage from "./assets/undraw_Notify_re_65on.svg";

import { boxesExceeded, mapMatchFields } from "./utils";
import Presets from "./components/Presets";

export default function UploadCSV() {
  const API_URL = import.meta.env.VITE_PULSE_API_URL;

  const toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);

  const [preview, setPreview] = useState(null);
  const [previewShown, showPreview] = useState(false);
  const [withoutHeader, setWithoutHeader] = useState(false);
  const [disableUpload, setDisableUpload] = useState(true);

  const uploadFile = async (file, fields) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fields", JSON.stringify(fields));

    const response = await axios.post(`${API_URL}/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response && response.status === 200) {

        // Create an example promise that resolves in 5s
        const uploadPromise = new Promise((resolve, reject) => {
          setTimeout(() => resolve(200), 5000)
        })

        // Will display the loading toast until the promise is either resolved
        // or rejected.
        toast.promise(uploadPromise, {
          success: { title: 'File uploaded!', description: "Thanks for your patience" },
          loading: { title: "We're uploading your file.", description: 'Please wait.' },
        })

    } else {
      toast({
        title: "There was an error trying to upload your file.",
        status: "error",
        duration: 5500,
        isClosable: true,
      });
    }
  };

  const handleSubmit = (values, { resetForm }) => {
    const data = { ...values, input_has_no_heading: !!withoutHeader };

    const exceeded = boxesExceeded(values["numofboxes_column"], preview);

    if (!previewShown) {
      toast({
        title: 'Click on "File Preview" first',
        description: "Please preview your file before submitting it.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      return;
    }

    if (exceeded && disableUpload) {
      setIsOpen(true);

      return;
    }

    if (!data.file || !data["creative_item_column"] || !data["date_column"]) {
      const message = !data.file
        ? `File is required.`
        : `Required fields are missing`;

      toast({
        title: message,
        description: "Please select one.",
        status: "error",
        duration: 4000,
        isClosable: true,
      });

      return;
    }
    const excludeKey = "file";

    const { [excludeKey]: excludedKey, ...fields } = data;

    uploadFile(data.file, fields);
  };

  return (
    <>
      <Formik
        initialValues={{
          file: null,
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <FastField name="file">
            {({ field, form, meta }) => {
              return (
                <Grid
                  templateColumns="repeat(auto-fit, minmax(300px, 1fr))"
                  gap="16px"
                >
                  <Box bg="white" color="brand.500" p={4} rounded="md">
                    <FormControl isInvalid={meta.touched && !!meta.error}>
                      <Dropzone
                        accept={{
                          'text/csv': ['.csv', '.ods'],
                          'application/csv': ['.csv', '.ods'],
                          'application/vnd.ms-excel': ['.xls', '.xlsx'],
                          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xls', '.xlsx'],
                        }}
                        maxFiles={1}
                        maxSize={10 * 1000 * 1000}
                        onDrop={(
                          acceptedFiles
                          // fileRejections
                        ) => {
                          if (acceptedFiles.length > 0) {
                            form.setFieldValue("file", acceptedFiles[0]);
                            // Clear form values
                            mapMatchFields.map((column) => {
                              form.setFieldValue(column.key, -1);
                            });
                          }
                        }}
                      />

                      {/* <FormErrorMessage>{meta?.error}</FormErrorMessage> */}
                    </FormControl>
                  </Box>

                  {field.value && (
                    <Box bg="white" rounded="md" px="8px" py="4px">
                      <Table size="sm">
                        <Tbody>
                          <Tr>
                            <Td>Name of file:</Td>
                            <Td>{field?.value?.name}</Td>
                          </Tr>
                          <Tr>
                            <Td>Size: </Td>
                            <Td>{prettyBytes(field.value?.size || 0)}</Td>
                          </Tr>
                          <Tr>
                            <Td>Type: </Td>
                            <Td>{field?.value?.type}</Td>
                          </Tr>
                        </Tbody>
                      </Table>

                      <Flex mt="8px" alignItems="center" fontSize="xs">
                        <Checkbox
                          m="0"
                          mr="4px"
                          value={withoutHeader}
                          onChange={(e) => {
                            setWithoutHeader(!!e.target.checked);
                          }}
                        >
                          <span style={{ fontSize: "12px" }}>
                            File does NOT have heading row (Check box if you
                            removed the heading/title row)
                          </span>
                        </Checkbox>
                      </Flex>

                      <HStack mt="16px" justifyContent="center">
                        {!withoutHeader && (
                          <ButtonMatchFields file={field.value ?? []} />
                        )}

                        <PreviewButton file={field.value ?? []} showPreview={showPreview} />
                      </HStack>
                    </Box>
                  )}
                </Grid>
              );
            }}
          </FastField>
          <Presets />

          <Box minW="0" maxW="100%" mt="24px">
            <Heading fontSize="lg">Map Reporting Fields</Heading>
            <Text py={3}>Map the columns from your file into what data they represent</Text>
            <Grid
              templateColumns="repeat(auto-fill, minmax(240px, 1fr))"
              gap="24px"
            >
              <Box>
                <Stack p="8px">
                  <SelectInput name="advertiser_column" />
                  <SelectInput name="advertiser_id_column" />
                  <SelectInput name="creative_item_column" />
                  <SelectInput name="creative_id_column" />
                </Stack>
              </Box>

              <Box>
                <Stack p="8px">
                  <SelectInput name="date_column" />
                  <SelectInput name="impressions_column" />
                  <SelectInput name="clicks_column" />
                  <SelectInput name="video_views_column" />
                  <SelectInput name="video_completes_column" />
                  <SelectInput name="total_cost_column" />
                </Stack>
              </Box>
            </Grid>
          </Box>

          <Box p="32px">
            <Button
              _hover={{ background: "bc_green.700" }}
              bg="brand.600"
              color="white"
              size="lg"
              type="submit"
              w="100%"
            >
              Upload File
            </Button>
          </Box>
        </Form>
      </Formik>

      {isOpen && (
        <AlertDialog isOpen={isOpen} onClose={onClose}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Are you sure?
              </AlertDialogHeader>

              <AlertDialogBody>
                <Image src={NotifyImage} p="32px" />
                {/* <Center>There are some orders with more that 100 boxes.</Center> */}
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    setDisableUpload(false);
                    onClose();
                  }}
                  ml={3}
                >
                  Yes, I Checked
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </>
  );
}
