import { read, utils } from "xlsx";

export const getHeadersFromXLS = (file, callback) => {
  const reader = new FileReader();

  reader.onload = (e) => {
    /* Parse data */
    const bstr = e?.target?.result;
    const wb = read(bstr, {
      type: "binary",
    });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data = utils.sheet_to_json(ws, {
      header: 1,
      defval: "",
    });

    callback(data);
  };

  reader.readAsBinaryString(file);
};

export const options = [
  "A (1st column)",
  "B (2nd column)",
  "C (3rd column)",
  "D (4th column)",
  "E (5th column)",
  "F (6th column)",
  "G (7th column)",
  "H (8th column)",
  "I (9th column)",
  "J (10th column)",
  "K (11th column)",
  "L (12th column)",
  "M (13th column)",
  "N (14th column)",
  "O (15th column)",
  "P (16th column)",
  "Q (17th column)",
  "R (18th column)",
  "S (19th column)",
  "T (20th column)",
  "U (21th column)",
  "V (22th column)",
];

export const boxesExceeded = (boxIdx, rows) => {
  if (!boxIdx) {
    return false;
  }

  const max = 99;
  let boxCounter = 0;

  (rows || []).forEach((row) => {
    if (row[boxIdx] > max) {
      boxCounter++;
    }
  });

  return !!boxCounter;
};

export const mapMatchFields = [
  {
    allowed: ["date", "datetime", "day"],
    field: "Date",
    key: "date_column",
    isRequired: true,
  },
  {
    allowed: ["advertiser", "line item", "line_item", "lineitem", "ad name"],
    field: "Line Item",
    key: "advertiser_column",
  },
  {
    allowed: ["advertiser id", "ads id", "advertiser_id", "ad id"],
    field: "Line Item ID",
    key: "advertiser_id_column",
  },
  {
    allowed: ["item", "creative"],
    field: "Creative Item",
    key: "creative_item_column",
    isRequired: true,
  },
  {
    allowed: ["creative_id", "creative id", "campaign id", "advertiser id"],
    field: "Creative Id",
    key: "creative_id_column",
    isRequired: true,
  },
  {
    allowed: ["impressions", "impression", "views"],
    field: "Impressions",
    key: "impressions_column",
    isRequired: false,
  },
  {
    allowed: ["clicks", "click"],
    field: "Clicks",
    key: "clicks_column",
    isRequired: false,
  },
  {
    allowed: ["video views", "video start"],
    field: "Video Views",
    key: "video_views_column",
    isRequired: false,
  },
  {
    allowed: ["video completes", "video completed"],
    field: "Video Completes",
    key: "video_completes_column",
    isRequired: false,
  },
  {
    allowed: ["cost", "burn", "total cost"],
    field: "Total Cost",
    key: "total_cost_column",
    isRequired: false,
  },
];

export const presets = [
  {
    amazon: {
      advertiser_column: "1",
      advertiser_id_column: "2",
      creative_item_column: "3",
      creative_id_column: "4",
      date_column: "5",
      impressions_column: "6",
      clicks_column: "7",
      video_views_column: "8",
      total_cost_column: "9",
      video_completes_column: "10",
    },
  },
  {
    priceline: {
      advertiser_column: "2",
      advertiser_id_column: "3",
      creative_item_column: "4",
      creative_id_column: "5",
      date_column: "6",
      impressions_column: "7",
      clicks_column: "8",
      video_views_column: "9",
      total_cost_column: "10",
      video_completes_column: "11",
    },
  },
  {
    expedia: {
      advertiser_column: "3",
      advertiser_id_column: "4",
      creative_item_column: "5",
      creative_id_column: "6",
      date_column: "7",
      impressions_column: "8",
      clicks_column: "9",
      video_views_column: "10",
      total_cost_column: "11",
      video_completes_column: "12",
    },
  },
  {
    tripadvisor: {
      advertiser_column: "4",
      advertiser_id_column: "5",
      creative_item_column: "6",
      creative_id_column: "7",
      date_column: "8",
      impressions_column: "9",
      clicks_column: "10",
      video_views_column: "11",
      total_cost_column: "12",
      video_completes_column: "13",
    },
  },
];
