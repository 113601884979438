import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import { motion } from "framer-motion";

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Heading,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

import ConnectionCard from "./ConnectionCard";

import {
  getConnectors,
  deleteConnector,
  getUrlToken,
} from "../../services/fivetran";
import { getServices } from "../../services/fivetranServices";
import { allowedServices } from "../../utils/connectors";

import { useUserStore } from "../../store/userStore";
import Loading from "../Loading";
import ConnectionCardNew from "./ConnectionCardNew";

export default function Connections() {
  const { user, getDestinationId } = useUserStore();
  const alert = useDisclosure();
  const cancelRef = useRef<any | null>(null);
  const currentConnector = useRef<any | null>(null);

  const [connectors, setConnectors] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<any | null>(null);

  useEffect(() => {
    async function run() {
      try {
        const destinationId = getDestinationId();

        if (!destinationId) {
          setIsLoading(false);
          setError("Destination not found");

          return;
        }

        const data = await getConnectors(destinationId);

        setConnectors(data);
        setIsLoading(false);
        setError(null);
      } catch (e) {
        setIsLoading(false);
        setError("Error loading connectors");
      }
    }

    run();
  }, [getDestinationId]);

  const connectionsAllowed = user?.info?.connections
    ? (user?.info?.connections || "").replaceAll(" ", "").split(",")
    : null;

  const services = getServices();
  const servicesFiltered = services
    .filter((s) => allowedServices.includes(s.id))
    .filter((s) =>
      !connectionsAllowed ? true : (connectionsAllowed || []).includes(s.id)
    )
    .filter((s) => !connectors.find((c) => c.service === s.id));

  const conectorsFiltered = connectors
    .filter((c) => allowedServices.includes(c.service))
    .filter((s) =>
      !connectionsAllowed
        ? true
        : (connectionsAllowed || []).includes(s.service)
    );

  const handleDelete = async (connectorId: string) => {
    await deleteConnector(connectorId);

    const filteredConnectors = (connectors || []).filter(
      (connector: { id: string }) => connector?.id !== connectorId
    );

    setConnectors(filteredConnectors);
  };

  const handleEdit = async (connectorId: string) => {
    const uri = await getUrlToken(connectorId);
    const redirectUri = window.location.origin;

    // console.log("url: ", `${uri}&redirect_uri=${redirectUri}/connections/`);

    window.location.href = `${uri}&redirect_uri=${redirectUri}/connections/`;
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return (
      <Center color="brand.500" height="90vh">
        <Box textAlign="center">
          <Heading size="md" mb={2}>
            {error}
          </Heading>
          <Text as={Link} to="/contact">
            Please contact with pulse team
          </Text>
        </Box>
      </Center>
    );
  }

  return (
    <Box>
      <Grid
        templateColumns="repeat(auto-fill, minmax(280px, 1fr))"
        gap={6}
        mt={8}
      >
        {(conectorsFiltered || []).map((connector: any, key: number) => (
          <GridItem key={connector.id}>
            <motion.div
              animate={{
                opacity: [0, 1],
                scale: [0.9, 1],
              }}
              transition={{
                duration: 1,
                ease: "easeOut",
                delay: (key + 1) * 0.2,
              }}
              style={{
                opacity: 0,
                scale: 0.9,
              }}
            >
              <ConnectionCard
                connector={connector}
                onDelete={() => {
                  currentConnector.current = connector;
                  alert.onOpen();
                }}
                onEdit={handleEdit}
              />
            </motion.div>
          </GridItem>
        ))}

        {(servicesFiltered || []).map((connector: any, key: number) => {
          const delay = (key + 1 + (connectors || []).length) * 0.32;

          return (
            <GridItem key={connector.id}>
              <motion.div
                animate={{
                  opacity: [0, 1],
                  scale: [0.9, 1],
                }}
                transition={{
                  duration: 1,
                  ease: "easeOut",
                  delay,
                }}
                style={{
                  opacity: 0,
                  scale: 0.9,
                }}
              >
                <ConnectionCardNew
                  connector={connector}
                  onDelete={() => {
                    currentConnector.current = connector;
                    alert.onOpen();
                  }}
                  onEdit={handleEdit}
                />
              </motion.div>
            </GridItem>
          );
        })}
      </Grid>

      {/* {isOpen && <ModalConnections isOpen={isOpen} onClose={onClose} />} */}

      <AlertDialog
        isOpen={alert.isOpen}
        leastDestructiveRef={cancelRef}
        onClose={alert.onClose}
        onCloseComplete={async () => {
          await handleDelete(currentConnector.current?.id);
          alert.onClose();
        }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Connector
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={alert.onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={alert.onClose} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}
