export const getServiceById = (id: string) => {
  return getServices().find((s) => s.id === id);
};

export const getServices = () => {
  return [
    {
      id: "15five",
      name: "15Five",
      type: "HumanResources",
      description: "15Five is an employee performance management platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/15five/resources/15five.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/15five/resources/15five-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/15five/resources/15five-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/15five",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/15five#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "360learning",
      name: "360Learning",
      type: "HumanResources",
      description: "360Learning is a learning and development platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/360learning/resources/360learning.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/360learning/resources/360learning-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/360learning/resources/360learning-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/360learning",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/360learning#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "adp_workforce_now",
      name: "ADP Workforce Now",
      type: "HumanResources",
      description:
        "ADP Workforce Now is a all-in-one platform for payroll and HR software, providing expert support and analytics for data-driven insights",
      icon_url:
        "https://fivetran.com/integrations/adp_workforce_now/resources/adp_logo.svg",
      icons: [
        "https://fivetran.com/integrations/adp_workforce_now/resources/adp_workforce_now.png",
        "https://fivetran.com/integrations/adp_workforce_now/resources/adp_workforce_now.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/adp-workforce-now",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/adp-workforce-now#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "aws_cloudtrail",
      name: "AWS CloudTrail",
      type: "File",
      description:
        "AWS CloudTrail is a service that enables governance, compliance, operational auditing, and risk auditing of your AWS account. With AWS CloudTrail, you can log, continuously monitor, and retain account activity related to actions across your AWS infrastructure.",
      icon_url:
        "https://fivetran.com/integrations/aws_cloudtrail/resources/aws_cloudtrail.svg",
      icons: [
        "https://fivetran.com/integrations/aws_cloudtrail/resources/cloudtrail.svg",
        "https://fivetran.com/integrations/aws_cloudtrail/resources/cloudtrail.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/aws-cloudtrail",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/aws-cloudtrail#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "aws_inventory",
      name: "AWS Inventory",
      type: "API",
      description:
        "AWS Inventory is a comprehensive service that provides you with an Amazon Web Services (AWS) resource inventory, configuration history, and configuration change notifications. With AWS Inventory, you can fetch and view the details of the services of your AWS account.",
      icon_url:
        "https://fivetran.com/integrations/aws_inventory/resources/aws_inventory.svg",
      icons: [
        "https://fivetran.com/integrations/aws_inventory/resources/aws_inventory-0.svg",
        "https://fivetran.com/integrations/aws_inventory/resources/aws_inventory-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/aws-inventory",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/aws-inventory#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "aws_lambda",
      name: "AWS Lambda",
      type: "Function",
      description:
        "AWS Lambda is a serverless computing platform that runs code in response to events and automatically manages the compute resources required by that code.",
      icon_url:
        "https://fivetran.com/integrations/functions/aws_lambda/resources/logo.png",
      icons: [
        "https://fivetran.com/integrations/functions/aws_lambda/resources/aws-lambda.png",
        "https://fivetran.com/integrations/functions/aws_lambda/resources/aws-lambda.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/functions/aws-lambda",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/functions/aws-lambda#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "aws_msk",
      name: "AWS MSK",
      type: "Events",
      description:
        "AWS Managed Streaming for Kafka is a managed distributed streaming platform.",
      icon_url: "https://fivetran.com/integrations/kafka/resources/aws_msk.svg",
      icons: [
        "https://fivetran.com/integrations/kafka/resources/aws_msk-0.svg",
        "https://fivetran.com/integrations/kafka/resources/aws_msk-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/events/aws-msk",
      connector_class: "standard",
      link_to_erd: "https://fivetran.com/docs/events/aws-msk#schemainformation",
      service_status: "beta",
    },
    {
      id: "activecampaign",
      name: "ActiveCampaign",
      type: "Marketing",
      description:
        "ActiveCampaign is a customer experience automation platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/activecampaign/resources/activecampaign.svg",
      icons: null,
      link_to_docs: "https://fivetran.com/docs/applications/activecampaign",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/activecampaign#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "adroll",
      name: "AdRoll",
      type: "Marketing",
      description: "AdRoll is prospecting and retargeting platform",
      icon_url: "https://fivetran.com/integrations/adroll/resources/adroll.svg",
      icons: [
        "https://fivetran.com/integrations/adroll/resources/adroll-logo.png",
        "https://fivetran.com/integrations/adroll/resources/adroll-logo.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/adroll",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/adroll#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "ada",
      name: "Ada",
      type: "Support",
      description: "Ada is an automated brand interaction platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/ada/resources/ada.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/ada/resources/ada-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/ada/resources/ada-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/ada",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/ada#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "adjust",
      name: "Adjust",
      type: "Marketing",
      description:
        "Adjust is a mobile measurement and fraud prevention platform.",
      icon_url:
        "https://fivetran.com/integrations/adjust/resources/adjust-logo.svg",
      icons: [
        "https://fivetran.com/integrations/adjust/resources/adjust.png",
        "https://fivetran.com/integrations/adjust/resources/adjust.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/adjust",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/adjust#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "adobe_analytics",
      name: "Adobe Analytics",
      type: "Marketing",
      description:
        "Adobe Analytics provides marketing analytics and reporting tools.",
      icon_url:
        "https://fivetran.com/integrations/adobe_analytics/resources/adobe-analytics-symbol.svg",
      icons: [
        "https://fivetran.com/integrations/adobe_analytics/resources/adobe_analytics.png",
        "https://fivetran.com/integrations/adobe_analytics/resources/adobe_analytics.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/adobe-analytics",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/adobe-analytics#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "adobe_analytics_data_feed",
      name: "Adobe Analytics Data Feed",
      type: "Marketing",
      description: "Adobe Analytics Data Feeds provide raw events data",
      icon_url:
        "https://fivetran.com/integrations/adobe_analytics_data_feed/resources/adobe-analytics-data-feed-symbol.svg",
      icons: [
        "https://fivetran.com/integrations/adobe_analytics_data_feed/resources/adobe_analytics.png",
        "https://fivetran.com/integrations/adobe_analytics_data_feed/resources/adobe_analytics.svg",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/adobe-analytics-data-feed",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/adobe-analytics-data-feed#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "aircall",
      name: "Aircall",
      type: "Marketing",
      description: "Aircall is a cloud-based call center and phone system.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/aircall/resources/aircall.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/aircall/resources/aircall-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/aircall/resources/aircall-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/aircall",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/aircall#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "airtable",
      name: "Airtable",
      type: "Productivity",
      description:
        "Airtable is an online collaboration platform for creating and sharing relational databases.",
      icon_url: "https://fivetran.com/integrations/airtable/resources/logo.svg",
      icons: [
        "https://fivetran.com/integrations/airtable/resources/airtable.png",
        "https://fivetran.com/integrations/airtable/resources/airtable.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/airtable",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/airtable#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "alchemer",
      name: "Alchemer",
      type: "Support",
      description: "Alchemer is a feedback and data collection platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/alchemer/resources/alchemer.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/alchemer/resources/alchemer-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/alchemer/resources/alchemer-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/alchemer",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/alchemer#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "amazon_ads",
      name: "Amazon Ads",
      type: "Marketing",
      description:
        "Amazon Ads is an advertising service that uses the pay-per-click model.",
      icon_url:
        "https://fivetran.com/integrations/amazon_ads/resources/amazon-ads-logo.svg",
      icons: [
        "https://fivetran.com/integrations/amazon_ads/resources/amazon_ads.png",
        "https://fivetran.com/integrations/amazon_ads/resources/amazon_ads.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/amazon-ads",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/amazon-ads#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "cloudfront",
      name: "Amazon CloudFront",
      type: "File",
      description: null,
      icon_url:
        "https://fivetran.com/integrations/cloudfront/resources/cloudfront.svg",
      icons: [
        "https://fivetran.com/integrations/cloudfront/resources/amazon-cloudfront.svg",
        "https://fivetran.com/integrations/cloudfront/resources/amazon-cloudfront.png",
      ],
      link_to_docs: "https://fivetran.com/docs/files/amazon-cloudfront",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/amazon-cloudfront#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "documentdb",
      name: "Amazon DocumentDB",
      type: "Database",
      description:
        "Amazon DocumentDB is a fully-managed NoSQL database service that is built for JSON data management and integrated with AWS.",
      icon_url:
        "https://fivetran.com/integrations/document/resources/documentDB.svg",
      icons: [
        "https://fivetran.com/integrations/document/resources/documentdb-0.svg",
        "https://fivetran.com/integrations/document/resources/documentdb-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/databases/documentdb",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/documentdb#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "kinesis",
      name: "Amazon Kinesis Firehose",
      type: "File",
      description:
        "Amazon Kinesis Firehose is a tool for capturing, manipulating, and loading data as it streams from a source to an AWS destination. Upon arrival at an S3 bucket, data from a Firehose delivery stream will appear without newline delimiters in extensionless files. Fivetran supports syncing from an Amazon S3 bucket to your destination json files that originate from a Kinesis Firehose delivery stream.",
      icon_url:
        "https://fivetran.com/integrations/kinesis/resources/kinesis.svg",
      icons: [
        "https://fivetran.com/integrations/kinesis/resources/kinesis-0.svg",
        "https://fivetran.com/integrations/kinesis/resources/kinesis-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/events/amazon-kinesis-firehose",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/events/amazon-kinesis-firehose#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "s3",
      name: "Amazon S3",
      type: "File",
      description:
        "Amazon Simple Storage Service (Amazon S3), provides scalable cloud storage services. Fivetran supports syncing files from your S3 bucket to your destination.",
      icon_url: "https://fivetran.com/integrations/s3/resources/s3.svg",
      icons: [
        "https://fivetran.com/integrations/s3/resources/s3-logo.svg",
        "https://fivetran.com/integrations/s3/resources/s3-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/files/amazon-s3",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/files/amazon-s3#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "amazon_selling_partner",
      name: "Amazon Selling Partner",
      type: "Sales",
      description:
        "Amazon Selling Partner (Seller/Vendor ) is Amazon's sales activity monitoring portal.",
      icon_url:
        "https://fivetran.com/integrations/amazon_selling_partner/resources/logo.svg",
      icons: [
        "https://fivetran.com/integrations/amazon_selling_partner/resources/logo.svg",
        "https://fivetran.com/integrations/amazon_selling_partner/resources/logo.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/amazon-selling-partner",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/amazon-selling-partner#schemainformation",
      service_status: "beta",
    },
    {
      id: "amplitude",
      name: "Amplitude",
      type: "Events",
      description:
        "Amplitude is the comprehensive product analytics software for web and mobile",
      icon_url:
        "https://fivetran.com/integrations/amplitude/resources/logo.svg",
      icons: [
        "https://fivetran.com/integrations/amplitude/resources/amplitude.png",
        "https://fivetran.com/integrations/amplitude/resources/amplitude.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/amplitude",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/amplitude#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "anaplan",
      name: "Anaplan",
      type: "Finance",
      description:
        "Anaplan is a cloud-based platform for business planning and modeling.",
      icon_url:
        "https://fivetran.com/integrations/anaplan/resources/anaplan.svg",
      icons: [
        "https://fivetran.com/integrations/anaplan/resources/anaplan-logo.png",
        "https://fivetran.com/integrations/anaplan/resources/anaplan-logo.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/anaplan",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/anaplan#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "apache_kafka",
      name: "Apache Kafka",
      type: "Events",
      description:
        "Apache Kafka is an open-source distributed streaming platform for building real-time data pipelines and stream processing applications.",
      icon_url:
        "https://fivetran.com/integrations/kafka/resources/apache_kafka.svg",
      icons: [
        "https://fivetran.com/integrations/kafka/resources/apache-kafka.svg",
        "https://fivetran.com/integrations/kafka/resources/apache-kafka.png",
      ],
      link_to_docs: "https://fivetran.com/docs/events/apache-kafka",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/events/apache-kafka#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "appfigures",
      name: "Appfigures",
      type: "Engineering",
      description:
        "Appfigures is a reporting platform for mobile application developers.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/appfigures/resources/appfigures.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/appfigures/resources/appfigures-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/appfigures/resources/appfigures-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/appfigures",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/appfigures#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "itunes_connect",
      name: "Apple App Store",
      type: "Marketing",
      description:
        "Apple App Store (formerly known as iTunes Connect) is a suite of web-based tools created for developers to submit and manage their apps for sale in the App Store or the Mac App Store.",
      icon_url:
        "https://fivetran.com/integrations/itunes_connect/resources/itunes_connect_logo.png",
      icons: [
        "https://fivetran.com/integrations/itunes_connect/resources/apple-app-store.png",
        "https://fivetran.com/integrations/itunes_connect/resources/apple-app-store.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/apple-app-store",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/apple-app-store#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "apple_search_ads",
      name: "Apple Search Ads",
      type: "Marketing",
      description:
        "Apple Search Ads helps people discover your app on the App Store for iPhone and iPad.",
      icon_url:
        "https://fivetran.com/integrations/apple_search_ads/resources/apple_search_ads.svg",
      icons: [
        "https://fivetran.com/integrations/apple_search_ads/resources/apple-search-ads.svg",
        "https://fivetran.com/integrations/apple_search_ads/resources/apple-search-ads.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/apple-search-ads",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/apple-search-ads#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "appsflyer",
      name: "AppsFlyer",
      type: "Marketing",
      description:
        "AppsFlyer is a mobile app tracking and attribution analytics platform.",
      icon_url:
        "https://fivetran.com/integrations/appsflyer/resources/appsflyer.svg",
      icons: [
        "https://fivetran.com/integrations/appsflyer/resources/appsflyer-logo.svg",
        "https://fivetran.com/integrations/appsflyer/resources/appsflyer-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/appsflyer",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/appsflyer#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "asana",
      name: "Asana",
      type: "Productivity",
      description:
        "Asana is a project management tool that helps teams track their tasks and projects.",
      icon_url: "https://fivetran.com/integrations/asana/resources/asana.svg",
      icons: [
        "https://fivetran.com/integrations/asana/resources/asana-logo.svg",
        "https://fivetran.com/integrations/asana/resources/asana-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/asana",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/asana#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "assembled",
      name: "Assembled",
      type: "Support",
      description:
        "Assembled is a workforce management platform for customer service.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/assembled/resources/assembled.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/assembled/resources/assembled-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/assembled/resources/assembled-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/assembled",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/assembled#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "aurora",
      name: "Aurora MySQL",
      type: "Database",
      description: "Amazon Aurora MySQL is a relational database.",
      icon_url:
        "https://fivetran.com/integrations/mysql/connector/resources/logos/aurora.png",
      icons: [
        "https://fivetran.com/integrations/mysql/connector/resources/logos/amazon-aurora.png",
        "https://fivetran.com/integrations/mysql/connector/resources/logos/amazon-aurora.svg",
      ],
      link_to_docs:
        "https://fivetran.com/docs/databases/mysql/aurora-setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/mysql/aurora-setup-guide#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "aurora_postgres",
      name: "Aurora PostgreSQL",
      type: "Database",
      description:
        "Aurora PostgreSQL is a hosted version of the popular Postgres database on the Amazon Aurora platform",
      icon_url:
        "https://fivetran.com/integrations/postgres/resources/aurora.png",
      icons: [
        "https://fivetran.com/integrations/postgres/resources/aurora_postgres.png",
        "https://fivetran.com/integrations/postgres/resources/aurora_postgres.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/databases/postgresql",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/postgresql/aurora-configuration#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "awin",
      name: "Awin",
      type: "Marketing",
      description: "Awin is an affiliate marketing platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/awin/resources/awin.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/awin/resources/awin-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/awin/resources/awin-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/awin",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/awin#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "azure_blob_storage",
      name: "Azure Blob Storage",
      type: "File",
      description:
        "Fivetran supports syncing files from Azure Blob Storage to your destination.",
      icon_url:
        "https://fivetran.com/integrations/azure_blob_storage/resources/azure_blob_storage.svg",
      icons: [
        "https://fivetran.com/integrations/azure_blob_storage/resources/azure_blob_storage-0.svg",
        "https://fivetran.com/integrations/azure_blob_storage/resources/azure_blob_storage-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/files/azure-blob-storage",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/files/azure-blob-storage#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "cosmos",
      name: "Azure Cosmos DB - NoSQL API",
      type: "Database",
      description:
        "Azure Cosmos DB is a fully managed, serverless NoSQL database for high-performance applications.",
      icon_url: "https://fivetran.com/integrations/cosmos/resources/cosmos.svg",
      icons: null,
      link_to_docs: "https://fivetran.com/docs/databases/cosmos",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/cosmos#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "azure_event_hub",
      name: "Azure Event Hubs",
      type: "Events",
      description:
        "Azure Event Hubs is a managed distributed streaming platform.",
      icon_url:
        "https://fivetran.com/integrations/kafka/resources/event_hub.svg",
      icons: [
        "https://fivetran.com/integrations/kafka/resources/azure_event_hub.svg",
        "https://fivetran.com/integrations/kafka/resources/azure_event_hub.png",
      ],
      link_to_docs: "https://fivetran.com/docs/events/azure-event-hubs",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/events/azure-event-hubs#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "azure_function",
      name: "Azure Functions",
      type: "Function",
      description:
        "Azure Functions is a serverless computing platform that runs code in response to events and automatically manages the compute resources required by that code.",
      icon_url:
        "https://fivetran.com/integrations/functions/azure_function/resources/logo.svg",
      icons: [
        "https://fivetran.com/integrations/functions/azure_function/resources/azure_function.png",
        "https://fivetran.com/integrations/functions/azure_function/resources/azure_function.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/functions/azure-functions",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/functions/azure-functions#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "maria_azure",
      name: "Azure MariaDB",
      type: "Database",
      description:
        "Azure MariaDB is a hosted version of the popular Maria database on the Azure platform.",
      icon_url:
        "https://fivetran.com/integrations/mysql/connector/resources/logos/maria_azure.png",
      icons: [
        "https://fivetran.com/integrations/mysql/connector/resources/logos/maria_azure-0.svg",
        "https://fivetran.com/integrations/mysql/connector/resources/logos/maria_azure-0.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/databases/mariadb/azure-setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/mariadb/azure-setup-guide#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "mysql_azure",
      name: "Azure MySQL",
      type: "Database",
      description:
        "Microsoft Azure MySQL is a high-performance MySQL-compatible database system.",
      icon_url:
        "https://fivetran.com/integrations/mysql/connector/resources/logos/azure.png",
      icons: [
        "https://fivetran.com/integrations/mysql/connector/resources/logos/mysql_azure.svg",
        "https://fivetran.com/integrations/mysql/connector/resources/logos/mysql_azure.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/databases/mysql/azure-setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/mysql/azure-setup-guide#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "azure_postgres",
      name: "Azure PostgreSQL Database",
      type: "Database",
      description:
        "Azure SQL Database is a managed relational cloud database service",
      icon_url:
        "https://fivetran.com/integrations/postgres/resources/azure.png",
      icons: [
        "https://fivetran.com/integrations/postgres/resources/azure_postgres.png",
        "https://fivetran.com/integrations/postgres/resources/azure_postgres.svg",
      ],
      link_to_docs:
        "https://fivetran.com/docs/databases/postgresql/azure-setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/postgresql/azure-setup-guide#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "azure_sql_db",
      name: "Azure SQL Database",
      type: "Database",
      description:
        "Azure SQL Database is a managed relational cloud database service.",
      icon_url:
        "https://fivetran.com/integrations/sql_server/resources/azure.png",
      icons: [
        "https://fivetran.com/integrations/sql_server/resources/azure_sql_db.png",
        "https://fivetran.com/integrations/sql_server/resources/azure_sql_db.svg",
      ],
      link_to_docs:
        "https://fivetran.com/docs/databases/sql-server/azure-setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/sql-server/azure-setup-guide#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "azure_sql_managed_db",
      name: "Azure SQL Managed Instance",
      type: "Database",
      description:
        "Azure SQL Managed Instance is a managed relational cloud database service.",
      icon_url:
        "https://fivetran.com/integrations/sql_server/resources/azure.png",
      icons: [
        "https://fivetran.com/integrations/sql_server/resources/azure_sql_managed_db.png",
        "https://fivetran.com/integrations/sql_server/resources/azure_sql_managed_db.svg",
      ],
      link_to_docs:
        "https://fivetran.com/docs/databases/sql-server/azure-managed-setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/sql-server/azure-managed-setup-guide#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "azure_service_bus",
      name: "Azure Service Bus",
      type: "Events",
      description: "Azure Service Bus is a cloud messaging service.",
      icon_url:
        "https://fivetran.com/integrations/azure_service_bus/resources/azure_service_bus.svg",
      icons: [
        "https://fivetran.com/integrations/azure_service_bus/resources/azure_service_bus-0.svg",
        "https://fivetran.com/integrations/azure_service_bus/resources/azure_service_bus-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/events/azure-service-bus",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/events/azure-service-bus#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "ballotready",
      name: "BallotReady",
      type: "Marketing",
      description: "BallotReady is an online voter guide for elections.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/ballotready/resources/ballotready.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/ballotready/resources/ballotready-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/ballotready/resources/ballotready-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/ballotready",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/ballotready#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "bamboohr",
      name: "Bamboo HR",
      type: "HumanResources",
      description:
        "BambooHR is a human resources management software as a service.",
      icon_url:
        "https://fivetran.com/integrations/bamboohr/resources/bamboohr.svg",
      icons: [
        "https://fivetran.com/integrations/bamboohr/resources/bamboohr_logo.png",
        "https://fivetran.com/integrations/bamboohr/resources/bamboohr_logo.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/bamboohr",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/bamboohr#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "big_commerce",
      name: "Big Commerce",
      type: "Sales",
      description:
        "Complete e-commerce solution that allows you to set up an online store on any social media platform",
      icon_url:
        "https://fivetran.com/integrations/big_commerce/resources/big_commerce.svg",
      icons: [
        "https://fivetran.com/integrations/big_commerce/resources/big_commerce-0.svg",
        "https://fivetran.com/integrations/big_commerce/resources/big_commerce-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/big-commerce",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/big-commerce#schemainformation",
      service_status: "beta",
    },
    {
      id: "bigquery_db",
      name: "BigQuery",
      type: "Database",
      description: "Cloud Database Service (https://cloud.google.com/bigquery)",
      icon_url: "https://fivetran.com/integrations/bigquery/resources/logo.png",
      icons: [
        "https://fivetran.com/integrations/bigquery/resources/bigquery-logo.png",
        "https://fivetran.com/integrations/bigquery/resources/bigquery-logo.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/databases/bigquery",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/bigquery#schemainformation",
      service_status: "beta",
    },
    {
      id: "birdeye",
      name: "Birdeye",
      type: "Support",
      description: "Birdeye is an experience marketing platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/birdeye/resources/birdeye.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/birdeye/resources/birdeye-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/birdeye/resources/birdeye-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/birdeye",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/birdeye#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "box",
      name: "Box",
      type: "File",
      description:
        "Box is a cloud-based file storage service. Fivetran supports syncing files from your Box account to your destination. You can sync multiple files as unique tables to your destination.",
      icon_url: "https://fivetran.com/integrations/box/resources/box-icon.svg",
      icons: [
        "https://fivetran.com/integrations/box/resources/box.svg",
        "https://fivetran.com/integrations/box/resources/box.png",
      ],
      link_to_docs: "https://fivetran.com/docs/files/box",
      connector_class: "standard",
      link_to_erd: "https://fivetran.com/docs/files/box#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "braintree",
      name: "Braintree",
      type: "Finance",
      description:
        "Braintree helps businesses of all sizes, from small to large enterprise, accept, process, and split payments to help maximize business opportunities and revenue growth. Companies around the world benefit from the technology and support of Braintree coupled with the scale, backing, and confidence of partnering with a PayPal service.",
      icon_url:
        "https://fivetran.com/integrations/braintree/resources/braintree.svg",
      icons: [
        "https://fivetran.com/integrations/braintree/resources/braintree-0.svg",
        "https://fivetran.com/integrations/braintree/resources/braintree-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/braintree",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/braintree#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "braintree_sandbox",
      name: "Braintree Sandbox",
      type: "Finance",
      description:
        "Braintree helps businesses of all sizes, from small to large enterprise, accept, process, and split payments to help maximize business opportunities and revenue growth. Companies around the world benefit from the technology and support of Braintree coupled with the scale, backing, and confidence of partnering with a PayPal service.",
      icon_url:
        "https://fivetran.com/integrations/braintree/resources/braintree.svg",
      icons: [
        "https://fivetran.com/integrations/braintree/resources/braintree_sandbox.svg",
        "https://fivetran.com/integrations/braintree/resources/braintree_sandbox.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/braintree",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/braintree#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "branch",
      name: "Branch",
      type: "Marketing",
      description:
        "Branch is a mobile measurement and deep linking platform that unifies user measurement across different devices, platforms, and channels.",
      icon_url: "https://fivetran.com/integrations/branch/resources/branch.svg",
      icons: [
        "https://fivetran.com/integrations/branch/resources/branch-logo.svg",
        "https://fivetran.com/integrations/branch/resources/branch-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/branch",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/branch#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "braze",
      name: "Braze",
      type: "Marketing",
      description:
        "Braze is a platform for cross-channel marketing automation.",
      icon_url: "https://fivetran.com/integrations/braze/resources/braze.png",
      icons: [
        "https://fivetran.com/integrations/braze/resources/braze-0.png",
        "https://fivetran.com/integrations/braze/resources/braze-0.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/braze",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/braze#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "brex",
      name: "Brex",
      type: "Finance",
      description: "Brex is a spend management platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/brex/resources/brex.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/brex/resources/brex-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/brex/resources/brex-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/brex",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/brex#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "buildkite",
      name: "Buildkite",
      type: "Engineering",
      description:
        "Connect your Buildkite data to your destination using Fivetran. Learn about configuration requirements, setup, and ERDs with our technical documentation.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/buildkite/resources/buildkite.svg",
      icons: null,
      link_to_docs: "https://fivetran.com/docs/applications/buildkite",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/buildkite#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "calendly",
      name: "Calendly",
      type: "Productivity",
      description:
        "Calendly is an app for scheduling appointments, meetings, and events.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/calendly/resources/calendly.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/calendly/resources/calendly-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/calendly/resources/calendly-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/calendly",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/calendly#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "campaignmonitor",
      name: "Campaign Monitor",
      type: "Marketing",
      description: "Campaign Monitor is an email marketing automation tool.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/campaignmonitor/resources/campaignmonitor.svg",
      icons: null,
      link_to_docs: "https://fivetran.com/docs/applications/campaignmonitor",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/campaignmonitor#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "chameleon",
      name: "Chameleon",
      type: "Engineering",
      description: "Chameleon is a platform for user onboarding.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/chameleon/resources/chameleon.svg",
      icons: null,
      link_to_docs: "https://fivetran.com/docs/applications/chameleon",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/chameleon#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "channel_advisor",
      name: "ChannelAdvisor",
      type: "Finance",
      description: "ChannelAdvisor is a cloud-based e-commerce platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/channel_advisor/resources/channel_advisor.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/channel_advisor/resources/channel_advisor-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/channel_advisor/resources/channel_advisor-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/channel-advisor",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/channel-advisor#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "chargedesk",
      name: "ChargeDesk",
      type: "Marketing",
      description: "ChargeDesk is a payment management service.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/chargedesk/resources/chargedesk.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/chargedesk/resources/chargedesk-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/chargedesk/resources/chargedesk-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/chargedesk",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/chargedesk#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "chargebee_product_catalog_1",
      name: "Chargebee Product Catalog 1.0",
      type: "Finance",
      description:
        "Chargebee is a subscription billing and revenue management platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/chargebee_product_catalog_1/resources/chargebee_product_catalog_1.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/chargebee_product_catalog_1/resources/chargebee_product_catalog_1-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/chargebee_product_catalog_1/resources/chargebee_product_catalog_1-0.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/chargebee/chargebee-product-catalog-1",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/chargebee/chargebee-product-catalog-1#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "chargebee_product_catalog_2",
      name: "Chargebee Product Catalog 2.0",
      type: "Finance",
      description:
        "Chargebee Product Catalog 2.0 is a billing, subscription, and revenue management platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/chargebee_product_catalog_2/resources/chargebee_product_catalog_2.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/chargebee_product_catalog_2/resources/chargebee_product_catalog_2-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/chargebee_product_catalog_2/resources/chargebee_product_catalog_2-0.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/chargebee/chargebee-product-catalog-2",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/chargebee/chargebee-product-catalog-2#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "chartmogul",
      name: "ChartMogul",
      type: "Marketing",
      description: "ChartMogul is a reporting and analytics solution.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/chartmogul/resources/chartmogul.svg",
      icons: null,
      link_to_docs: "https://fivetran.com/docs/applications/chartmogul",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/chartmogul#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "chorusai",
      name: "Chorus",
      type: "Marketing",
      description: "Chorus is an AI conversation platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/chorusai/resources/chorusai.svg",
      icons: null,
      link_to_docs: "https://fivetran.com/docs/applications/chorus",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/chorus#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "churnzero",
      name: "ChurnZero",
      type: "Productivity",
      description:
        "ChurnZero is a customer success platform for subscription-based businesses.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/churnzero/resources/churnzero.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/churnzero/resources/churnzero-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/churnzero/resources/churnzero-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/churnzero",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/churnzero#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "churnkey",
      name: "Churnkey",
      type: "Marketing",
      description:
        "Churnkey is a subscription-based service that assists businesses in reducing their churn rate.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/churnkey/resources/churnkey.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/churnkey/resources/churnkey-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/churnkey/resources/churnkey-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/churnkey",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/churnkey#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "cin7",
      name: "Cin7",
      type: "Productivity",
      description:
        "Cin7 is a fully integrated, cloud-based inventory management software that streamlines stock management across multiple channels.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/cin7/resources/cin7.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/cin7/resources/cin7-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/cin7/resources/cin7-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/cin7",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/cin7#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "clari",
      name: "Clari",
      type: "Sales",
      description: "Clari is a revenue management platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/clari/resources/clari.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/clari/resources/clari-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/clari/resources/clari-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/clari",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/clari#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "clickup",
      name: "ClickUp",
      type: "Engineering",
      description:
        "ClickUp is a cloud-based collaboration and project management tool.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/clickup/resources/clickup.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/clickup/resources/clickup-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/clickup/resources/clickup-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/clickup",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/clickup#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "close",
      name: "Close",
      type: "Sales",
      description: "Close is a CRM system for sales specialists.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/close/resources/close.svg",
      icons: null,
      link_to_docs: "https://fivetran.com/docs/applications/close",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/close#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "collibra",
      name: "Collibra",
      type: "Engineering",
      description: "Collibra is a data intelligence cloud platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/collibra/resources/collibra.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/collibra/resources/collibra-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/collibra/resources/collibra-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/collibra",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/collibra#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "confluent_cloud",
      name: "Confluent Cloud",
      type: "Events",
      description:
        "Confluent Cloud is a managed distributed streaming platform.",
      icon_url:
        "https://fivetran.com/integrations/kafka/resources/confluent_cloud.svg",
      icons: [
        "https://fivetran.com/integrations/kafka/resources/confluent_cloud-0.svg",
        "https://fivetran.com/integrations/kafka/resources/confluent_cloud-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/events/confluent-cloud",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/events/confluent-cloud#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "coupa",
      name: "Coupa",
      type: "Finance",
      description:
        "Coupa Software is a global technology platform for Business Spend Management",
      icon_url:
        "https://fivetran.com/integrations/coupa/resources/coupa_logo.svg",
      icons: [
        "https://fivetran.com/integrations/coupa/resources/coupa.svg",
        "https://fivetran.com/integrations/coupa/resources/coupa.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/coupa",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/coupa#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "criteo",
      name: "Criteo",
      type: "Marketing",
      description:
        "Criteo helps internet retailers serve personalized advertisements through retargeting",
      icon_url: "https://fivetran.com/integrations/criteo/resources/criteo.png",
      icons: [
        "https://fivetran.com/integrations/criteo/resources/criteo-0.svg",
        "https://fivetran.com/integrations/criteo/resources/criteo-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/criteo",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/criteo#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "crossbeam",
      name: "Crossbeam",
      type: "Sales",
      description: "Crossbeam is a partner ecosystem platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/crossbeam/resources/crossbeam.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/crossbeam/resources/crossbeam-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/crossbeam/resources/crossbeam-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/crossbeam",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/crossbeam#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "customerio",
      name: "Customer.io",
      type: "Marketing",
      description: "Customer.io is an automated messaging platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/customerio/resources/customerio.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/customerio/resources/customerio-logo.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/customerio/resources/customerio-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/customer.io",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/customer.io#schemainformation",
      service_status: "beta",
    },
    {
      id: "dcl_logistics",
      name: "DCL Logistics",
      type: "Productivity",
      description:
        "DCL Logistics is a logistics and supply chain management company.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/dcl_logistics/resources/dcl_logistics.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/dcl_logistics/resources/dcl_logistics-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/dcl_logistics/resources/dcl_logistics-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/dcl-logistics",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/dcl-logistics#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "dear",
      name: "DEAR",
      type: "Finance",
      description: "DEAR is a cloud-based ERP solution.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/dear/resources/dear.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/dear/resources/dear-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/dear/resources/dear-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/dear",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/dear#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "delighted",
      name: "Delighted",
      type: "Support",
      description:
        "Delighted is an online solution that enables businesses to gather and  measure actionable feedback from customers, employees, partners, and vendors.",
      icon_url:
        "https://fivetran.com/integrations/delighted/resources/delighted.svg",
      icons: [
        "https://fivetran.com/integrations/delighted/resources/delighted-logo.svg",
        "https://fivetran.com/integrations/delighted/resources/delighted-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/delighted",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/delighted#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "destini",
      name: "Destini",
      type: "Marketing",
      description: "Destini is a product locator solution for consumers.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/destini/resources/destini.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/destini/resources/destini-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/destini/resources/destini-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/destini",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/destini#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "dixa",
      name: "Dixa",
      type: "Productivity",
      description: "Dixa is a customer service platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/dixa/resources/dixa.svg",
      icons: null,
      link_to_docs: "https://fivetran.com/docs/applications/dixa",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/dixa#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "docebo",
      name: "Docebo",
      type: "HumanResources",
      description: "Docebo is a learning management system.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/docebo/resources/docebo.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/docebo/resources/docebo-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/docebo/resources/docebo-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/docebo",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/docebo#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "drift",
      name: "Drift",
      type: "Productivity",
      description:
        "Drift is a sales acceleration platform that provides sales and marking software.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/drift/resources/drift.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/drift/resources/drift-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/drift/resources/drift-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/drift",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/drift#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "drip",
      name: "Drip",
      type: "Marketing",
      description:
        "Drip is a marketing automation platform for e-commerce businesses.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/drip/resources/drip.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/drip/resources/drip-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/drip/resources/drip-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/drip",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/drip#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "dropbox",
      name: "Dropbox",
      type: "File",
      description:
        "Dropbox is a cloud storage provider. Fivetran supports syncing files from your Dropbox account to your destination. You can sync multiple files as unique tables to your destination.",
      icon_url:
        "https://fivetran.com/integrations/dropbox/resources/dropbox.svg",
      icons: [
        "https://fivetran.com/integrations/dropbox/resources/dropbox-logo.svg",
        "https://fivetran.com/integrations/dropbox/resources/dropbox-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/files/dropbox",
      connector_class: "standard",
      link_to_erd: "https://fivetran.com/docs/files/dropbox#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "duoplane",
      name: "Duoplane",
      type: "Sales",
      description:
        "Duoplane is a dropship automation and order management software.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/duoplane/resources/duoplane.svg",
      icons: null,
      link_to_docs: "https://fivetran.com/docs/applications/duoplane",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/duoplane#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "dynamodb",
      name: "DynamoDB",
      type: "Database",
      description:
        "Amazon DynamoDB is a fully-managed, proprietary NoSQL database service that is offered as part of Amazon Web Services (AWS).",
      icon_url:
        "https://fivetran.com/integrations/dynamodb/resources/dynamodb.svg",
      icons: [
        "https://fivetran.com/integrations/dynamodb/resources/amazon-dynamodb.png",
        "https://fivetran.com/integrations/dynamodb/resources/amazon-dynamodb.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/databases/dynamodb",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/dynamodb#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "easypost",
      name: "EasyPost",
      type: "Finance",
      description: "EasyPost is a shipping platform for e-commerce businesses.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/easypost/resources/easypost.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/easypost/resources/easypost-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/easypost/resources/easypost-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/easypost",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/easypost#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "elastic_cloud",
      name: "Elastic Cloud",
      type: "Database",
      description: "Elastic Cloud is a NoSql Document Database",
      icon_url:
        "https://fivetran.com/integrations/elasticsearch/resources/logos/elastic_cloud.png",
      icons: [
        "https://fivetran.com/integrations/elasticsearch/resources/logos/elastic_cloud-0.png",
        "https://fivetran.com/integrations/elasticsearch/resources/logos/elastic_cloud-0.svg",
      ],
      link_to_docs:
        "https://fivetran.com/docs/databases/elasticsearch/elastic-cloud-setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/elasticsearch/elastic-cloud-setup-guide#schemainformation",
      service_status: "beta",
    },
    {
      id: "eloqua",
      name: "Eloqua",
      type: "Marketing",
      description:
        "Oracle Eloqua equips marketers with best-in-class lead and campaign management tools that help marketers engage the right audience at the right time in the buyer's journey while providing real-time reporting and insights.",
      icon_url: "https://fivetran.com/integrations/eloqua/resources/eloqua.svg",
      icons: [
        "https://fivetran.com/integrations/eloqua/resources/eloqua-0.svg",
        "https://fivetran.com/integrations/eloqua/resources/eloqua-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/eloqua",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/eloqua#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "email",
      name: "Email",
      type: "File",
      description:
        "Email CSV and JSON files to your destination with the Email Connector. The Email Connector extracts one or more attachments from the email, parses the attachment data and uploads them into your warehouse. Emails are deleted once attachments are extracted from it. This is designed for schedule email reports.",
      icon_url: "https://fivetran.com/integrations/email/resources/email.svg",
      icons: [
        "https://fivetran.com/integrations/email/resources/email-0.svg",
        "https://fivetran.com/integrations/email/resources/email-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/files/email",
      connector_class: "standard",
      link_to_erd: "https://fivetran.com/docs/files/email#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "employment_hero",
      name: "Employment Hero",
      type: "HumanResources",
      description:
        "Employment Hero is a cloud-based human resource and payroll management platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/employment_hero/resources/employment_hero.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/employment_hero/resources/employment_hero-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/employment_hero/resources/employment_hero-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/employment-hero",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/employment-hero#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "eventbrite",
      name: "Eventbrite",
      type: "Marketing",
      description: "Eventbrite is an event management and ticketing platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/eventbrite/resources/eventbrite.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/eventbrite/resources/eventbrite-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/eventbrite/resources/eventbrite-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/eventbrite",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/eventbrite#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "everhour",
      name: "Everhour",
      type: "Engineering",
      description:
        "Everhour is a time-tracking software that can integrate with project management tools.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/everhour/resources/everhour.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/everhour/resources/everhour-logo.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/everhour/resources/everhour-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/everhour",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/everhour#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "ftp",
      name: "FTP",
      type: "File",
      description:
        "Fivetran supports syncing files from an FTP server to your destination.",
      icon_url: "https://fivetran.com/integrations/ftp/resources/ftp.svg",
      icons: [
        "https://fivetran.com/integrations/ftp/resources/ftp-0.svg",
        "https://fivetran.com/integrations/ftp/resources/ftp-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/files/ftp",
      connector_class: "standard",
      link_to_erd: "https://fivetran.com/docs/files/ftp#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "facebook_ads",
      name: "Facebook Ads",
      type: "Marketing",
      description:
        "Facebook Ads provides a history of the settings of your Ad Account, Campaigns, Ad Sets, Ads, and Ad Creatives. Facebook Ads also fetches Ad Insights data.",
      icon_url:
        "https://fivetran.com/integrations/facebook/resources/facebook.svg",
      icons: [
        "https://fivetran.com/integrations/facebook/resources/facebook-logo.svg",
        "https://fivetran.com/integrations/facebook/resources/facebook-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/facebook-ads",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/facebook-ads#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "facebook_pages",
      name: "Facebook Pages",
      type: "Marketing",
      description:
        "Sync your Facebook pages and posts, as well as insights about them, with the Facebook Pages connector.",
      icon_url:
        "https://fivetran.com/integrations/facebook/resources/facebook.svg",
      icons: [
        "https://fivetran.com/integrations/facebook/resources/facebook-logo.svg",
        "https://fivetran.com/integrations/facebook/resources/facebook-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/facebook-pages",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/facebook-pages#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "financial_force",
      name: "FinancialForce",
      type: "API",
      description:
        "FinancialForce is an ERP and service automation tool built on Salesforce.",
      icon_url:
        "https://fivetran.com/integrations/salesforce/resources/financial_force.svg",
      icons: [
        "https://fivetran.com/integrations/salesforce/resources/financial_force-0.svg",
        "https://fivetran.com/integrations/salesforce/resources/financial_force-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/financial-force",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/financial-force#schemainformation",
      service_status: "beta",
    },
    {
      id: "firebase",
      name: "Firebase",
      type: "Database",
      description:
        "Firebase is an application development platform. Firebase Cloud Firestore is a NoSQL document database characterized by a lack of a fixed schema. Data is stored in key-value pairs in documents that form a collection.",
      icon_url:
        "https://fivetran.com/integrations/firebase/resources/firebase.svg",
      icons: [
        "https://fivetran.com/integrations/firebase/resources/firebase.png",
        "https://fivetran.com/integrations/firebase/resources/firebase.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/databases/firebase",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/firebase#schemainformation",
      service_status: "beta",
    },
    {
      id: "fivetran_log",
      name: "Fivetran Platform",
      type: "Free",
      description:
        "Fivetran generates structured log events from connectors, dashboard user actions, and Fivetran API calls. The Fivetran Platform Connector delivers your logs and account metadata to a schema in your destination. This metadata includes granular Fivetran consumption information.",
      icon_url:
        "https://fivetran.com/integrations/fivetran_log/resources/fivetran.svg",
      icons: [
        "https://fivetran.com/integrations/fivetran_log/resources/fivetran-logo.svg",
        "https://fivetran.com/integrations/fivetran_log/resources/fivetran-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/logs/fivetran-platform",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/logs/fivetran-platform#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "fountain",
      name: "Fountain",
      type: "HumanResources",
      description: "Fountain is a hiring automation software.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/fountain/resources/fountain.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/fountain/resources/fountain-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/fountain/resources/fountain-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/fountain",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/fountain#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "freightview",
      name: "Freightview",
      type: "Productivity",
      description: "Freightview is an online transportation management system.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/freightview/resources/freightview.svg",
      icons: null,
      link_to_docs: "https://fivetran.com/docs/applications/freightview",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/freightview#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "freshbooks",
      name: "FreshBooks",
      type: "Finance",
      description: "FreshBooks is an accounting software.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/freshbooks/resources/freshbooks.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/freshbooks/resources/freshbooks-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/freshbooks/resources/freshbooks-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/freshbooks",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/freshbooks#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "freshteam",
      name: "FreshTeam",
      type: "HumanResources",
      description: "FreshTeam is an HR management platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/freshteam/resources/freshteam.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/freshteam/resources/freshteam-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/freshteam/resources/freshteam-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/freshteam",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/freshteam#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "freshchat",
      name: "Freshchat",
      type: "Support",
      description: "Freshchat is a customer messaging software.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/freshchat/resources/freshchat.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/freshchat/resources/freshchat-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/freshchat/resources/freshchat-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/freshchat",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/freshchat#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "freshdesk",
      name: "Freshdesk",
      type: "Support",
      description:
        "Freshdesk is a SaaS customer support software and help desk ticketing system. The solution also includes features like Knowledge Base, Community Platform, Live Chat, Phone Support, etc.",
      icon_url:
        "https://fivetran.com/integrations/freshdesk/resources/freshdesk.svg",
      icons: [
        "https://fivetran.com/integrations/freshdesk/resources/freshdesk-logo.svg",
        "https://fivetran.com/integrations/freshdesk/resources/freshdesk-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/freshdesk",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/freshdesk#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "freshservice",
      name: "Freshservice",
      type: "Engineering",
      description: "Freshservice is a IT service management platform.",
      icon_url:
        "https://fivetran.com/integrations/freshservice/resources/freshservice.svg",
      icons: [
        "https://fivetran.com/integrations/freshservice/resources/freshservice-0.svg",
        "https://fivetran.com/integrations/freshservice/resources/freshservice-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/freshservice",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/freshservice#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "friendbuy",
      name: "Friendbuy",
      type: "Productivity",
      description:
        "Friendbuy is a referral marketing solution for e-commerce businesses.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/friendbuy/resources/friendbuy.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/friendbuy/resources/friendbuy-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/friendbuy/resources/friendbuy-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/friendbuy",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/friendbuy#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "front",
      name: "Front",
      type: "Support",
      description:
        "Front simplifies how your team manages email lists and customer communications. It provides services to centralize all your communication in one place and optimize how your team handles every message with powerful automations. It also connects your inbox with all the apps you rely on.",
      icon_url: "https://fivetran.com/integrations/front/resources/front.svg",
      icons: [
        "https://fivetran.com/integrations/front/resources/front-logo.svg",
        "https://fivetran.com/integrations/front/resources/front-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/front",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/front#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "fullstory",
      name: "FullStory",
      type: "Engineering",
      description: "FullStory is a digital experience intelligence platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/fullstory/resources/fullstory.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/fullstory/resources/fullstory-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/fullstory/resources/fullstory-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/fullstory",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/fullstory#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "gainsight_customer_success",
      name: "Gainsight Customer Success",
      type: "Productivity",
      description: "Gainsight Customer Success is a customer success platform.",
      icon_url:
        "https://fivetran.com/integrations/gainsight_customer_success/resources/gainsight_customer_success.svg",
      icons: [
        "https://fivetran.com/integrations/gainsight_customer_success/resources/gainsight_customer_success-0.svg",
        "https://fivetran.com/integrations/gainsight_customer_success/resources/gainsight_customer_success-0.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/gainsight-customer-success",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/gainsight-customer-success#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "gem",
      name: "Gem",
      type: "HumanResources",
      description: "Gem is a recruitment platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/gem/resources/gem.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/gem/resources/gem-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/gem/resources/gem-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/gem",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/gem#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "github",
      name: "GitHub",
      type: "Engineering",
      description:
        "GitHub is a platform for developers to build personal projects, support their businesses, and work together on open-source technologies. Fivetran helps you export data about commits, users, pull-requests, and other GitHub data.",
      icon_url: "https://fivetran.com/integrations/github/resources/github.svg",
      icons: [
        "https://fivetran.com/integrations/github/resources/github-logo.svg",
        "https://fivetran.com/integrations/github/resources/github-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/github",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/github#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "gladly",
      name: "Gladly",
      type: "Support",
      description: "Gladly is a customer service platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/gladly/resources/gladly.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/gladly/resources/gladly-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/gladly/resources/gladly-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/gladly",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/gladly#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "gocardless",
      name: "GoCardless",
      type: "Finance",
      description:
        "GoCardless is a payment platform that facilitates payment processing for recurring payments.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/gocardless/resources/gocardless_logo.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/gocardless/resources/gocardless-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/gocardless/resources/gocardless-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/gocardless",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/gocardless#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "gongio",
      name: "Gong",
      type: "Productivity",
      description: "Gong is a revenue intelligence platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/gongio/resources/gongio.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/gongio/resources/gongio-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/gongio/resources/gongio-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/gong",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/gong#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "double_click_publishers",
      name: "Google Ad Manager",
      type: "Marketing",
      description:
        "Google Ad Manager is a hosted ad serving platform to streamline ad management, whether you deliver ads to websites, mobile web pages, mobile apps, games, or a combination. It offers an integrated platform to manage all direct and programmatic sales, and to streamline all ad sales across your web and app inventory.",
      icon_url:
        "https://fivetran.com/integrations/google_ad_manager/resources/google_ad_manager.png",
      icons: [
        "https://fivetran.com/integrations/google_ad_manager/resources/google-ad-manager.png",
        "https://fivetran.com/integrations/google_ad_manager/resources/google-ad-manager.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/google-ad-manager",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/google-ad-manager#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "google_ads",
      name: "Google Ads",
      type: "Marketing",
      description: "Google Ads is an online advertising platform",
      icon_url:
        "https://fivetran.com/integrations/google_ads/resources/google-ads.png",
      icons: [
        "https://fivetran.com/integrations/google_ads/resources/google-ads.png",
        "https://fivetran.com/integrations/google_ads/resources/google-ads.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/google-ads",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/google-ads#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "google_analytics",
      name: "Google Analytics",
      type: "Marketing",
      description:
        "Google Analytics lets you analyze data about customer engagement with your website or application.",
      icon_url:
        "https://fivetran.com/integrations/google_analytics/resources/google_analytics.svg",
      icons: [
        "https://fivetran.com/integrations/google_analytics/resources/google-analytics-logo.png",
        "https://fivetran.com/integrations/google_analytics/resources/google-analytics-logo.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/google-analytics",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/google-analytics#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "google_analytics_mcf",
      name: "Google Analytics (MCF)",
      type: "Marketing",
      description:
        "Google Analytics Multi-Channel Funnels (MCF) Reports lets you get conversion path data that shows user interactions with various traffic sources over multiple sessions prior to converting, and to analyze how multiple marketing channels influence conversions.",
      icon_url:
        "https://fivetran.com/integrations/google_analytics/resources/google_analytics_mcf.svg",
      icons: [
        "https://fivetran.com/integrations/google_analytics/resources/google_analytics_mcf-0.svg",
        "https://fivetran.com/integrations/google_analytics/resources/google_analytics_mcf-0.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/google-analytics-mcf",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/google-analytics-mcf#schemainformation",
      service_status: "beta",
    },
    {
      id: "google_analytics_360",
      name: "Google Analytics 360",
      type: "Marketing",
      description:
        "Google Analytics 360 provides the tools and support that enterprise teams need to get actionable insights from their data. You can export session and hit data from a Google Analytics 360 account to BigQuery. When you export data to BigQuery, you own that data, and you can use BigQuery ACLs to manage permissions on projects and datasets.",
      icon_url:
        "https://fivetran.com/integrations/google_analytics_360/resources/google-analytics-360.svg",
      icons: [
        "https://fivetran.com/integrations/google_analytics_360/resources/google-analytics-360-0.svg",
        "https://fivetran.com/integrations/google_analytics_360/resources/google-analytics-360-0.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/google-analytics-360",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/google-analytics-360#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "google_analytics_4",
      name: "Google Analytics 4",
      type: "Marketing",
      description:
        "Google Analytics 4 lets you analyze data about customer engagement with your website or application.",
      icon_url:
        "https://fivetran.com/integrations/google_analytics_4/resources/google_analytics_4.svg",
      icons: [
        "https://fivetran.com/integrations/google_analytics_4/resources/google-analytics-logo.png",
        "https://fivetran.com/integrations/google_analytics_4/resources/google-analytics-logo.svg",
      ],
      link_to_docs: "",
      connector_class: "standard",
      service_status: "general_availability",
    },
    {
      id: "google_analytics_4_export",
      name: "Google Analytics 4 Export",
      type: "Marketing",
      description:
        "Google Analytics 4 is an analytics service to measure traffic and engagement across websites and mobile apps.",
      icon_url:
        "https://fivetran.com/integrations/google_analytics_4_export/resources/google-analytics-4-export.svg",
      icons: [
        "https://fivetran.com/integrations/google_analytics_4_export/resources/google-analytics-logo.png",
        "https://fivetran.com/integrations/google_analytics_4_export/resources/google-analytics-logo.svg",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/google-analytics-4-export",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/google-analytics-4-export#schemainformation",
      service_status: "beta",
    },
    {
      id: "google_calendar",
      name: "Google Calendar",
      type: "Productivity",
      description: "Google Calendar is a time management and calendar service.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/google_calendar/resources/google_calendar.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/google_calendar/resources/google_calendar-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/google_calendar/resources/google_calendar-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/google-calendar",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/google-calendar#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "double_click_campaign_manager",
      name: "Google Campaign Manager 360",
      type: "Marketing",
      description:
        "Google Campaign Manager 360 simplifies advertising campaign management, from media planning to reporting. With all your digital marketing efforts on one powerful platform, you can work smarter, act quicker, and get better results.",
      icon_url:
        "https://fivetran.com/integrations/double_click_campaign_manager/resources/campaign_manager.svg",
      icons: [
        "https://fivetran.com/integrations/double_click_campaign_manager/resources/double_click_campaign_manager.svg",
        "https://fivetran.com/integrations/double_click_campaign_manager/resources/double_click_campaign_manager.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/google-campaign-manager-360",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/google-campaign-manager-360#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "google_cloud_function",
      name: "Google Cloud Functions",
      type: "Function",
      description:
        "Google Cloud Functions is a serverless computing platform that runs code in response to events and automatically manages the compute resources required by that code.",
      icon_url:
        "https://fivetran.com/integrations/functions/google_cloud_function/resources/logo.svg",
      icons: [
        "https://fivetran.com/integrations/functions/google_cloud_function/resources/google_cloud_function.svg",
        "https://fivetran.com/integrations/functions/google_cloud_function/resources/google_cloud_function.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/functions/google-cloud-functions",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/functions/google-cloud-functions#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "google_cloud_mysql",
      name: "Google Cloud MySQL",
      type: "Database",
      description:
        "Google Cloud MySQL is a fully-managed MySQL database on Google Cloud Platform.",
      icon_url:
        "https://fivetran.com/integrations/mysql/connector/resources/logos/cloudsql.png",
      icons: [
        "https://fivetran.com/integrations/mysql/connector/resources/logos/google_cloud_mysql.svg",
        "https://fivetran.com/integrations/mysql/connector/resources/logos/google_cloud_mysql.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/databases/mysql/google-cloud-mysql-setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/mysql/google-cloud-mysql-setup-guide#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "google_cloud_postgresql",
      name: "Google Cloud PostgreSQL",
      type: "Database",
      description:
        "Google Cloud SQL Postgres is a hosted version of the popular Postgres database on the Google Cloud platform",
      icon_url:
        "https://fivetran.com/integrations/postgres/resources/cloudsql.png",
      icons: [
        "https://fivetran.com/integrations/postgres/resources/google_cloud_postgresql.svg",
        "https://fivetran.com/integrations/postgres/resources/google_cloud_postgresql.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/databases/postgresql/gcs-setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/postgresql/gcs-setup-guide#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "google_cloud_sqlserver",
      name: "Google Cloud SQL - SQL Server",
      type: "Database",
      description:
        "Google Cloud SQL is a fully managed SQL Server databases in Google Cloud.",
      icon_url:
        "https://fivetran.com/integrations/sql_server/resources/cloudsql.png",
      icons: [
        "https://fivetran.com/integrations/sql_server/resources/google_cloud_sqlserver.png",
        "https://fivetran.com/integrations/sql_server/resources/google_cloud_sqlserver.svg",
      ],
      link_to_docs:
        "https://fivetran.com/docs/databases/sql-server/gcs-setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/sql-server/gcs-setup-guide#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "gcs",
      name: "Google Cloud Storage",
      type: "File",
      description: "Google Cloud Storage is Google's cloud storage system.",
      icon_url: "https://fivetran.com/integrations/gcs/resources/gcs.svg",
      icons: [
        "https://fivetran.com/integrations/gcs/resources/google-cloud-storage.png",
        "https://fivetran.com/integrations/gcs/resources/google-cloud-storage.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/files/google-cloud-storage",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/files/google-cloud-storage#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "google_display_and_video_360",
      name: "Google Display & Video 360",
      type: "Marketing",
      description:
        "Google Display & Video 360 is an integrated solution for end-to-end advertising campaigns.",
      icon_url:
        "https://fivetran.com/integrations/google_display_and_video_360/resources/google-display-and-video-360.svg",
      icons: [
        "https://fivetran.com/integrations/google_display_and_video_360/resources/google_display_and_video_360-0.svg",
        "https://fivetran.com/integrations/google_display_and_video_360/resources/google_display_and_video_360-0.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/google-display-and-video-360",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/google-display-and-video-360#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "google_drive",
      name: "Google Drive",
      type: "File",
      description:
        "Google Drive is a file storage and synchronization service. Fivetran supports syncing files from your Google Drive to your destination. You can sync multiple files as unique tables to your destination.",
      icon_url:
        "https://fivetran.com/integrations/gdrive/resources/google-drive-icon.svg",
      icons: [
        "https://fivetran.com/integrations/gdrive/resources/google-drive.png",
        "https://fivetran.com/integrations/gdrive/resources/google-drive.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/files/google-drive",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/files/google-drive#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "google_play",
      name: "Google Play",
      type: "Marketing",
      description:
        "The Google Play connector will sync monthly reports about individual Android apps to help you track and understand your app's performance. The mid-month reports are updated daily. The full set of reports are described in the Google Play documentation.",
      icon_url:
        "https://fivetran.com/integrations/google_play/resources/google_play.svg",
      icons: [
        "https://fivetran.com/integrations/google_play/resources/google-play.svg",
        "https://fivetran.com/integrations/google_play/resources/google-play.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/google-play",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/google-play#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "google_search_ads_360",
      name: "Google Search Ads 360",
      type: "Marketing",
      description:
        "helps you monitor and maintain your ad campaigns across multiple search engines using a single centralized tool.",
      icon_url:
        "https://fivetran.com/integrations/google_search_ads_360/resources/google_search_ads_360.png",
      icons: [
        "https://fivetran.com/integrations/google_search_ads_360/resources/google_search_ads_360-0.svg",
        "https://fivetran.com/integrations/google_search_ads_360/resources/google_search_ads_360-0.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/google-search-ads-360",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/google-search-ads-360#schemainformation",
      service_status: "beta",
    },
    {
      id: "google_search_console",
      name: "Google Search Console",
      type: "Marketing",
      description:
        "Google Search Console helps you monitor and maintain your site's presence in Google Search results.",
      icon_url:
        "https://fivetran.com/integrations/google_search_console/resources/google-search-console-logo.svg",
      icons: [
        "https://fivetran.com/integrations/google_search_console/resources/google_search_console-0.svg",
        "https://fivetran.com/integrations/google_search_console/resources/google_search_console-0.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/google-search-console",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/google-search-console#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "google_sheets",
      name: "Google Sheets",
      type: "File",
      description:
        "Google Sheets is a web-based application that allows users to create, update, and modify spreadsheets and share the data live online.",
      icon_url:
        "https://fivetran.com/integrations/gsheets/resources/gsheets.svg",
      icons: [
        "https://fivetran.com/integrations/gsheets/resources/google-sheets.svg",
        "https://fivetran.com/integrations/gsheets/resources/google-sheets.png",
      ],
      link_to_docs: "https://fivetran.com/docs/files/google-sheets",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/files/google-sheets#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "gorgias",
      name: "Gorgias",
      type: "Support",
      description:
        "Gorgias is a customer support platform for e-commerce businesses.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/gorgias/resources/gorgias.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/gorgias/resources/gorgias-1.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/gorgias/resources/gorgias-1.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/gorgias",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/gorgias#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "greenhouse",
      name: "Greenhouse",
      type: "HumanResources",
      description:
        "Greenhouse is an applicant tracking system and recruiting software designed to optimize your entire recruiting process. Find better candidates, conduct more focused interviews, and make data-driven hiring decisions.",
      icon_url:
        "https://fivetran.com/integrations/greenhouse/resources/greenhouse.svg",
      icons: [
        "https://fivetran.com/integrations/greenhouse/resources/greenhouse-logo.svg",
        "https://fivetran.com/integrations/greenhouse/resources/greenhouse-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/greenhouse",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/greenhouse#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "guru",
      name: "Guru",
      type: "Engineering",
      description: "Guru is a data organization platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/guru/resources/guru.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/guru/resources/guru-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/guru/resources/guru-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/guru",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/guru#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "harvest",
      name: "Harvest",
      type: "Productivity",
      description: "Harvest is an online time-tracking tool.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/harvest/resources/harvest.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/harvest/resources/harvest-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/harvest/resources/harvest-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/harvest",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/harvest#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "heap",
      name: "Heap",
      type: "Events",
      description:
        "Heap automatically captures every web, mobile, and cloud interaction: clicks, submits, transactions, emails, and more. Retroactively analyze your data without writing code.",
      icon_url: "https://fivetran.com/integrations/heap/resources/heap.svg",
      icons: [
        "https://fivetran.com/integrations/heap/resources/heap-logo.svg",
        "https://fivetran.com/integrations/heap/resources/heap-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/heap",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/heap#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "height",
      name: "Height",
      type: "Productivity",
      description:
        "Height is a collaborative work service that helps to maintain a project workflow.",
      icon_url: "https://fivetran.com/integrations/height/resources/height.svg",
      icons: [
        "https://fivetran.com/integrations/height/resources/height-0.svg",
        "https://fivetran.com/integrations/height/resources/height-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/height",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/height#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "helpscout",
      name: "Help Scout",
      type: "Support",
      description:
        "Help Scout is a help desk software that provides an email-based customer support.",
      icon_url:
        "https://fivetran.com/integrations/helpscout/resources/helpscout.svg",
      icons: [
        "https://fivetran.com/integrations/helpscout/resources/helpscout-logo.svg",
        "https://fivetran.com/integrations/helpscout/resources/helpscout-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/helpscout",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/helpscout#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "heroku_kafka",
      name: "Heroku Kafka",
      type: "Events",
      description: "Heroku Kafka is a managed distributed streaming platform.",
      icon_url:
        "https://fivetran.com/integrations/kafka/resources/heroku_kafka.svg",
      icons: [
        "https://fivetran.com/integrations/kafka/resources/heroku.svg",
        "https://fivetran.com/integrations/kafka/resources/heroku.png",
      ],
      link_to_docs: "https://fivetran.com/docs/events/heroku-kafka",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/events/heroku-kafka#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "heroku_postgres",
      name: "Heroku PostgreSQL",
      type: "Database",
      description:
        "Heroku PostgreSQL is a hosted version of the popular Postgres database on the Heroku platform",
      icon_url:
        "https://fivetran.com/integrations/postgres/resources/heroku.png",
      icons: [
        "https://fivetran.com/integrations/postgres/resources/heroku_postgres.svg",
        "https://fivetran.com/integrations/postgres/resources/heroku_postgres.png",
      ],
      link_to_docs: "https://fivetran.com/docs/databases/postgresql",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/postgresql/heroku-setup-guide#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "hibob",
      name: "HiBob",
      type: "HumanResources",
      description:
        "HiBob is an HR platform that facilitates management operations.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/hibob/resources/hibob.svg",
      icons: null,
      link_to_docs: "https://fivetran.com/docs/applications/hibob",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/hibob#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "db2i_hva",
      name: "High Volume Agent Db2 for i",
      type: "Database",
      description:
        "Db2 for i is a relational database management system integrated with IBM i. Fivetran replicates data from your Db2 for i source database and loads it into your destination using High-Volume Agent connector.",
      icon_url:
        "https://fivetran.com/integrations/db2i_hva/resources/icon-db2i.svg",
      icons: [
        "https://fivetran.com/integrations/db2i_hva/resources/icon-db2i.svg",
        "https://fivetran.com/integrations/db2i_hva/resources/icon-db2i.png",
      ],
      link_to_docs: "https://fivetran.com/docs/databases/hva-db2i/setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/hva-db2i/setup-guide#schemainformation",
      service_status: "beta",
    },
    {
      id: "oracle_hva",
      name: "High-Volume Agent Oracle",
      type: "Database",
      description:
        "Oracle is an object-relational database management system created by the Oracle Corporation. Fivetran replicates data from your Oracle source database and loads it into your destination using High-Volume Agent connector.",
      icon_url: "https://fivetran.com/integrations/oracle/resources/oracle.svg",
      icons: [
        "https://fivetran.com/integrations/oracle/resources/oracle-logo.svg",
        "https://fivetran.com/integrations/oracle/resources/oracle-logo.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/databases/oracle/hva-oracle/setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/oracle/hva-oracle/setup-guide#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "oracle_sap_hva",
      name: "High-Volume Agent SAP ECC on Oracle",
      type: "Database",
      description:
        "SAP ECC on Oracle is an enterprise resource planning and analytics platform built on top of Oracle database. Fivetran replicates SAP data from your Oracle source database and loads it into your destination using High-Volume Agent connector.",
      icon_url:
        "https://fivetran.com/integrations/oracle/resources/oracle-sap.svg",
      icons: [
        "https://fivetran.com/integrations/oracle/resources/oracle-sap-logo.svg",
        "https://fivetran.com/integrations/oracle/resources/oracle-sap-logo.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/databases/sap-erp/high-volume-agent/hva-sap-ecc-oracle/setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/sap-erp/high-volume-agent/hva-sap-ecc-oracle/setup-guide#schemainformation",
      service_status: "beta",
    },
    {
      id: "oracle_sap_hva_netweaver",
      name: "High-Volume Agent SAP ECC on Oracle with NetWeaver",
      type: "Database",
      description:
        "SAP ECC on Oracle is an enterprise resource planning and analytics platform built on top of Oracle database. Fivetran replicates SAP data from your Oracle source database and loads it into your destination using High-Volume Agent connector.",
      icon_url:
        "https://fivetran.com/integrations/oracle/resources/oracle-sap.svg",
      icons: [
        "https://fivetran.com/integrations/oracle/resources/oracle-sap-logo.svg",
        "https://fivetran.com/integrations/oracle/resources/oracle-sap-logo.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/databases/sap-erp/high-volume-agent/hva-sap-ecc-oracle-nw/setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/sap-erp/high-volume-agent/hva-sap-ecc-oracle-nw/setup-guide#schemainformation",
      service_status: "beta",
    },
    {
      id: "sql_server_hva",
      name: "High-Volume Agent SQL Server",
      type: "Database",
      description:
        "SQL Server is Microsoft's SQL database. Fivetran replicates data from your SQL Server source database and loads it into your destination at regular intervals using the High-Volume Agent connector.",
      icon_url:
        "https://fivetran.com/integrations/sql_server_hva/resources/sql_server.svg",
      icons: [
        "https://fivetran.com/integrations/sql_server_hva/resources/sql_server_hva.svg",
        "https://fivetran.com/integrations/sql_server_hva/resources/sql_server_hva.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/databases/sql-server/hva-sql-server/setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/sql-server/hva-sql-server/setup-guide#schemainformation",
      service_status: "beta",
    },
    {
      id: "hopin",
      name: "Hopin",
      type: "Marketing",
      description: "Hopin is an online events platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/hopin/resources/hopin.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/hopin/resources/hopin-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/hopin/resources/hopin-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/hopin",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/hopin#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "hubspot",
      name: "HubSpot",
      type: "Marketing",
      description:
        "HubSpot is an inbound marketing and sales software that helps companies attract visitors, convert leads, and close customers.",
      icon_url:
        "https://fivetran.com/integrations/hubspot/resources/hubspot.svg",
      icons: [
        "https://fivetran.com/integrations/hubspot/resources/hubspot-logo.svg",
        "https://fivetran.com/integrations/hubspot/resources/hubspot-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/hubspot",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/hubspot#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "impact",
      name: "Impact",
      type: "Marketing",
      description: "Impact is a partnership management platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/impact/resources/impact.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/impact/resources/impact-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/impact/resources/impact-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/impact",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/impact#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "instagram_business",
      name: "Instagram Business",
      type: "Marketing",
      description:
        "Instagram Business provides metadata and insights for IG User (Instagram Business Account) and IG Media (Instagram Photo, Video, Story, or Album) objects.",
      icon_url:
        "https://fivetran.com/integrations/instagram/resources/instagram.png",
      icons: [
        "https://fivetran.com/integrations/instagram/resources/instagram-logo.png",
        "https://fivetran.com/integrations/instagram/resources/instagram-logo.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/instagram-business",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/instagram-business#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "integral_ad_science",
      name: "Integral Ad Science",
      type: "Marketing",
      description: "Integral Ad Science is a digital ad verification platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/integral_ad_science/resources/integral_ad_science.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/integral_ad_science/resources/integral_ad_science-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/integral_ad_science/resources/integral_ad_science-0.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/integral-ad-science",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/integral-ad-science#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "intercom",
      name: "Intercom",
      type: "Support",
      description:
        "Intercom brings messaging products for sales, marketing & customer service to one platform, helping businesses avoid the stiff, spammy status quo and have real conversations that build real connections. Designed to feel like the messaging apps you use every day, Intercom lets you talk to consumers almost anywhere: inside your app, on your website, across social media and via email.",
      icon_url:
        "https://fivetran.com/integrations/intercom/resources/intercom.svg",
      icons: [
        "https://fivetran.com/integrations/intercom/resources/intercom-logo.svg",
        "https://fivetran.com/integrations/intercom/resources/intercom-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/intercom",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/intercom#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "ironclad",
      name: "Ironclad",
      type: "Productivity",
      description:
        "Ironclad automates contracting processes for legal, sales, and operational sides of businesses.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/ironclad/resources/ironclad.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/ironclad/resources/ironclad-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/ironclad/resources/ironclad-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/ironclad",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/ironclad#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "iterable",
      name: "Iterable",
      type: "Marketing",
      description:
        "Iterable is a growth marketing and customer engagement platform. It enables brands to create, execute and optimize campaigns across email, push, SMS, in-app and more with unparalleled data flexibility.",
      icon_url:
        "https://fivetran.com/integrations/iterable/resources/iterable.svg",
      icons: [
        "https://fivetran.com/integrations/iterable/resources/iterable-logo.svg",
        "https://fivetran.com/integrations/iterable/resources/iterable-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/iterable",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/iterable#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "jira",
      name: "Jira",
      type: "Engineering",
      description:
        "Jira is a bug tracking, issue tracking and project management software.",
      icon_url: "https://fivetran.com/integrations/jira/resources/jira.svg",
      icons: [
        "https://fivetran.com/integrations/jira/resources/jira-logo.svg",
        "https://fivetran.com/integrations/jira/resources/jira-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/jira",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/jira#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "atlassian_jira_align",
      name: "Jira Align",
      type: "Marketing",
      description:
        "Jira Align is a project management tool to coordinate software development activities across multiple teams.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/atlassian_jira_align/resources/atlassian_jira_align.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/atlassian_jira_align/resources/atlassian_jira_align-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/atlassian_jira_align/resources/atlassian_jira_align-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/jira-align",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/jira-align#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "kandji",
      name: "Kandji",
      type: "Engineering",
      description:
        "Kandji is a cloud-based platform for managing Apple devices.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/kandji/resources/kandji.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/kandji/resources/kandji-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/kandji/resources/kandji-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/kandji",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/kandji#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "khoros_care",
      name: "Khoros Care",
      type: "Support",
      description: "Khoros Care is a digital customer engagement platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/khoros_care/resources/khoros_care.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/khoros_care/resources/khoros_care-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/khoros_care/resources/khoros_care-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/khoros-care",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/khoros-care#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "kissmetrics",
      name: "Kissmetrics",
      type: "Marketing",
      description:
        "Kissmetrics analyzes the behavior of website visitors to help businesses make product and marketing decisions.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/kissmetrics/resources/kissmetrics.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/kissmetrics/resources/kissmetrics-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/kissmetrics/resources/kissmetrics-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/kissmetrics",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/kissmetrics#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "klaviyo",
      name: "Klaviyo",
      type: "Marketing",
      description:
        "Klaviyo is an email platform powered by data built for commerce and web businesses. Klaviyo makes it easy for ecommerce marketers to target, personalize, measure and optimize email and Facebook campaigns.",
      icon_url:
        "https://fivetran.com/integrations/klaviyo/resources/klaviyo.svg",
      icons: [
        "https://fivetran.com/integrations/klaviyo/resources/klaviyo-logo.svg",
        "https://fivetran.com/integrations/klaviyo/resources/klaviyo-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/klaviyo",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/klaviyo#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "kustomer",
      name: "Kustomer",
      type: "Support",
      description:
        "Kustomer is a software platform for customer experience, service, and support integrated with a number of sources to give support staff a complete picture of a customer when they contact the company.",
      icon_url:
        "https://fivetran.com/integrations/kustomer/resources/kustomer.svg",
      icons: [
        "https://fivetran.com/integrations/kustomer/resources/kustomer-logo.svg",
        "https://fivetran.com/integrations/kustomer/resources/kustomer-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/kustomer",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/kustomer#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "lattice",
      name: "Lattice",
      type: "HumanResources",
      description: "Lattice is a people management platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/lattice/resources/lattice.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/lattice/resources/lattice-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/lattice/resources/lattice-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/lattice",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/lattice#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "launchdarkly",
      name: "LaunchDarkly",
      type: "Engineering",
      description: "LaunchDarkly is a software feature management platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/launchdarkly/resources/launchdarkly.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/launchdarkly/resources/launchdarkly-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/launchdarkly/resources/launchdarkly-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/launchdarkly",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/launchdarkly#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "lessonly",
      name: "Lessonly",
      type: "HumanResources",
      description: "Lessonly by Seismic is a team training software.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/lessonly/resources/lessonly.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/lessonly/resources/lessonly-0.png",
        "https://fivetran.com/integrations/coil_connectors/resources/lessonly/resources/lessonly-0.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/lessonly",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/lessonly#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "lever",
      name: "Lever",
      type: "HumanResources",
      description:
        "Lever is a Modern Recruiting Software that streamlines the hiring process and simplifies the Applicant Tracking System.",
      icon_url: "https://fivetran.com/integrations/lever/resources/lever.svg",
      icons: [
        "https://fivetran.com/integrations/lever/resources/lever-logo.svg",
        "https://fivetran.com/integrations/lever/resources/lever-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/lever",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/lever#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "light_speed_retail",
      name: "Lightspeed Retail",
      type: "Finance",
      description:
        "Lightspeed Retail is a cloud-based point of sale (POS) solution that is suitable for retailers.",
      icon_url:
        "https://fivetran.com/integrations/lightspeed_retail/resources/lightspeed.svg",
      icons: [
        "https://fivetran.com/integrations/lightspeed_retail/resources/lightspeed-logo.svg",
        "https://fivetran.com/integrations/lightspeed_retail/resources/lightspeed-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/light-speed-retail",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/light-speed-retail#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "linkedin_ads",
      name: "LinkedIn Ad Analytics",
      type: "Marketing",
      description:
        "LinkedIn Ad Analytics offer you information about the performance of your LinkedIn Ads.",
      icon_url:
        "https://fivetran.com/integrations/linkedin/resources/linkedin_ads.svg",
      icons: [
        "https://fivetran.com/integrations/linkedin/resources/linkedin.svg",
        "https://fivetran.com/integrations/linkedin/resources/linkedin.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/linkedin-ads",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/linkedin-ads#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "linkedin_company_pages",
      name: "LinkedIn Company Pages",
      type: "Marketing",
      description:
        "LinkedIn Company Pages offer you information about the company pages statistics, reactions and social metadata by your LinkedIn organizations.",
      icon_url:
        "https://fivetran.com/integrations/linkedin/resources/linkedin_company_pages.svg",
      icons: [
        "https://fivetran.com/integrations/linkedin/resources/linkedin.svg",
        "https://fivetran.com/integrations/linkedin/resources/linkedin.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/linkedin-company-pages",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/linkedin-company-pages#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "livechat",
      name: "LiveChat",
      type: "Productivity",
      description: "LiveChat helps handle conversations with the customers.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/livechat/resources/livechat.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/livechat/resources/livechat-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/livechat/resources/livechat-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/livechat",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/livechat#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "lob",
      name: "Lob",
      type: "Marketing",
      description: "Lob is direct mail platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/lob/resources/lob.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/lob/resources/lob-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/lob/resources/lob-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/lob",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/lob#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "luma",
      name: "Luma",
      type: "Marketing",
      description: "Luma is an event management solution.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/luma/resources/luma.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/luma/resources/luma-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/luma/resources/luma-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/luma",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/luma#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "mountain",
      name: "MNTN",
      type: "Marketing",
      description: "MNTN is a television advertising platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/mountain/resources/mountain.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/mountain/resources/mountain-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/mountain/resources/mountain-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/mntn",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/mntn#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "magento_mysql",
      name: "Magento MySQL",
      type: "Database",
      description:
        "Magento is an ecommerce platform which provides online merchants with a flexible shopping cart system, and customization of their online store. It offers marketing, search engine optimization, and catalog-management tools. Magento's underlying technology is built on the MySQL database.",
      icon_url:
        "https://fivetran.com/integrations/mysql/connector/resources/logos/magento.png",
      icons: [
        "https://fivetran.com/integrations/mysql/connector/resources/logos/magento-logo.png",
        "https://fivetran.com/integrations/mysql/connector/resources/logos/magento-logo.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/magento",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/magento#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "magento_mysql_rds",
      name: "Magento MySQL RDS",
      type: "Database",
      description:
        "Magento is an ecommerce platform which provides online merchants with a flexible shopping cart system, and customization of their online store. It offers marketing, search engine optimization, and catalog-management tools. Magento's underlying technology is built on the MySQL RDS database.",
      icon_url:
        "https://fivetran.com/integrations/mysql/connector/resources/logos/magento.png",
      icons: [
        "https://fivetran.com/integrations/mysql/connector/resources/logos/magento-logo.png",
        "https://fivetran.com/integrations/mysql/connector/resources/logos/magento-logo.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/magento",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/magento#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "mailchimp",
      name: "Mailchimp",
      type: "Marketing",
      description:
        "Mailchimp is an email marketing platform to send emails and automated messages, create targeted campaigns, facilitate reporting and analytics, and sell online.",
      icon_url:
        "https://fivetran.com/integrations/mailchimp/resources/mailchimp.svg",
      icons: [
        "https://fivetran.com/integrations/mailchimp/resources/mailchimp-logo.svg",
        "https://fivetran.com/integrations/mailchimp/resources/mailchimp-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/mailchimp",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/mailchimp#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "mailgun",
      name: "Mailgun",
      type: "Marketing",
      description: "Mailgun is the intelligent email delivery platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/mailgun/resources/mailgun.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/mailgun/resources/mailgun-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/mailgun/resources/mailgun-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/mailgun",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/mailgun#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "mandrill",
      name: "Mandrill",
      type: "Marketing",
      description: "Mandrill is a transactional email API for Mailchimp users.",
      icon_url:
        "https://fivetran.com/integrations/mandrill/resources/mandrill.svg",
      icons: [
        "https://fivetran.com/integrations/mandrill/resources/mandrill-logo.svg",
        "https://fivetran.com/integrations/mandrill/resources/mandrill-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/mandrill",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/mandrill#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "maria",
      name: "MariaDB",
      type: "Database",
      description:
        "MariaDB is an open-source fork of the popular MySQL relational database. Its development is led by some of the original developers of MySQL.",
      icon_url:
        "https://fivetran.com/integrations/mysql/connector/resources/logos/maria.png",
      icons: [
        "https://fivetran.com/integrations/mysql/connector/resources/logos/maria-db.png",
        "https://fivetran.com/integrations/mysql/connector/resources/logos/maria-db.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/databases/mariadb/setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/mariadb/setup-guide#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "maria_rds",
      name: "MariaDB RDS",
      type: "Database",
      description:
        "MariaDB RDS is a fork of the popular MySQL database on Amazon RDS.",
      icon_url:
        "https://fivetran.com/integrations/mysql/connector/resources/logos/maria.png",
      icons: [
        "https://fivetran.com/integrations/mysql/connector/resources/logos/maria-db.png",
        "https://fivetran.com/integrations/mysql/connector/resources/logos/maria-db.svg",
      ],
      link_to_docs:
        "https://fivetran.com/docs/databases/mariadb/rds-setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/mariadb/rds-setup-guide#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "marin",
      name: "Marin Software",
      type: "Marketing",
      description:
        "Marin Software is an online advertising platform to measure, manage, and optimize ad spending across the web and mobile devices. Marin provides a Data Passback service which exports reporting data to an SFTP server. Fivetran syncs files from Marin's Data Passback SFTP server to your destination.",
      icon_url: "https://fivetran.com/integrations/marin/resources/logo.png",
      icons: [
        "https://fivetran.com/integrations/marin/resources/marin-software.png",
        "https://fivetran.com/integrations/marin/resources/marin-software.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/marin-software",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/marin-software#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "marketo",
      name: "Marketo",
      type: "Marketing",
      description:
        "Marketo develops marketing automation software that provides inbound marketing, social marketing, CRM, and other related services.",
      icon_url:
        "https://fivetran.com/integrations/marketo/resources/marketo.svg",
      icons: [
        "https://fivetran.com/integrations/marketo/resources/marketo-logo.svg",
        "https://fivetran.com/integrations/marketo/resources/marketo-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/marketo",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/marketo#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "mavenlink",
      name: "Mavenlink",
      type: "Productivity",
      description:
        "Mavenlink is an online project management software. Besides project management, it has time tracking, resource planning, collaboration, finance etc. functions.",
      icon_url:
        "https://fivetran.com/integrations/mavenlink/resources/mavenlink.svg",
      icons: [
        "https://fivetran.com/integrations/mavenlink/resources/mavenlink-0.svg",
        "https://fivetran.com/integrations/mavenlink/resources/mavenlink-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/mavenlink",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/mavenlink#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "medallia",
      name: "Medallia",
      type: "Support",
      description: "Medallia is a customer experience management platform.",
      icon_url:
        "https://fivetran.com/integrations/medallia/resources/medallia.svg",
      icons: [
        "https://fivetran.com/integrations/medallia/resources/medallia-0.svg",
        "https://fivetran.com/integrations/medallia/resources/medallia-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/medallia",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/medallia#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "mention",
      name: "Mention",
      type: "Marketing",
      description: "Mention is a social media monitoring software.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/mention/resources/mention.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/mention/resources/mention-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/mention/resources/mention-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/mention",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/mention#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "bingads",
      name: "Microsoft Advertising",
      type: "Marketing",
      description:
        "Microsoft Advertising (formerly known as Bing Ads) is a service that provides pay-per-click advertising for both the Bing and Yahoo search engines. To get an overview of Microsoft Advertising, see its entity hierarchy and limits documentation.",
      icon_url:
        "https://fivetran.com/integrations/bingads/resources/bingads.svg",
      icons: [
        "https://fivetran.com/integrations/bingads/resources/bing.svg",
        "https://fivetran.com/integrations/bingads/resources/bing.png",
        "https://fivetran.com/integrations/bingads/resources/microsoft-ads.svg",
        "https://fivetran.com/integrations/bingads/resources/microsoft-ads.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/microsoft-advertising",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/microsoft-advertising#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "dynamics_365",
      name: "Microsoft Dynamics 365 CRM",
      type: "Sales",
      description:
        "Microsoft Dynamics 365 CRM is a cloud-based CRM built by Microsoft for maximum flexibility and extensibility.",
      icon_url:
        "https://fivetran.com/integrations/dynamics365/resources/logo.svg",
      icons: [
        "https://fivetran.com/integrations/dynamics365/resources/dynamics_365.svg",
        "https://fivetran.com/integrations/dynamics365/resources/dynamics_365.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/microsoft-dynamics/dynamics365crm",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/microsoft-dynamics/dynamics365crm#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "dynamics_365_fo",
      name: "Microsoft Dynamics 365 F/O",
      type: "Database",
      description:
        "Microsoft Dynamics 365 for Finance and Operations is an enterprise-level ERP solution that can be deployed in the cloud or on-premises.",
      icon_url:
        "https://fivetran.com/integrations/dynamics365/resources/logo.svg",
      icons: [
        "https://fivetran.com/integrations/sql_server/resources/dynamics_365_fo.png",
        "https://fivetran.com/integrations/sql_server/resources/dynamics_365_fo.svg",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/microsoft-dynamics365-fo/setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/microsoft-dynamics365-fo#schemainformation",
      service_status: "sunset",
    },
    {
      id: "microsoft_dynamics_365_fo",
      name: "Microsoft Dynamics 365 Finance",
      type: "Sales",
      description:
        "Microsoft Dynamics 365 Finance is an ERP solution for large to medium organizations.",
      icon_url:
        "https://fivetran.com/integrations/dynamics_365_fo/resources/logo.svg",
      icons: [
        "https://fivetran.com/integrations/dynamics_365_fo/resources/dynamics_365_fo.svg",
        "https://fivetran.com/integrations/dynamics_365_fo/resources/dynamics_365_fo.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/microsoft-dynamics/dynamics365-finance-adls",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/microsoft-dynamics/dynamics365-finance-adls#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "microsoft_lists",
      name: "Microsoft Lists",
      type: "Engineering",
      description:
        "Microsoft Lists is a Microsoft 365 application for work management and tracking.",
      icon_url:
        "https://fivetran.com/integrations/microsoft_lists/resources/microsoft-lists-icon.svg",
      icons: [
        "https://fivetran.com/integrations/microsoft_lists/resources/microsoft_lists.svg",
        "https://fivetran.com/integrations/microsoft_lists/resources/microsoft_lists.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/microsoft-lists",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/microsoft-lists#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "mixmax",
      name: "Mixmax",
      type: "Marketing",
      description: "Mixmax is a sales engagement platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/mixmax/resources/mixmax.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/mixmax/resources/mixmax-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/mixmax/resources/mixmax-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/mixmax",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/mixmax#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "mixpanel",
      name: "Mixpanel",
      type: "Events",
      description:
        "Mixpanel is a business analytics service that tracks user interactions with web and mobile applications. The data collected is used to build custom reports and measure user engagement and retention.",
      icon_url:
        "https://fivetran.com/integrations/mixpanel/resources/mixpanel.svg",
      icons: [
        "https://fivetran.com/integrations/mixpanel/resources/mixpanel-logo.svg",
        "https://fivetran.com/integrations/mixpanel/resources/mixpanel-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/mixpanel",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/mixpanel#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "monday",
      name: "Monday.com",
      type: "Engineering",
      description: "Monday.com is a web and mobile work management platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/monday/resources/monday.svg",
      icons: null,
      link_to_docs: "https://fivetran.com/docs/applications/monday.com",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/monday.com#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "mongo",
      name: "MongoDB",
      type: "Database",
      description:
        "MongoDB is a NoSQL database characterized by a lack of fixed columns and fixed tables. Instead, it has collections (which are similar to tables) and dynamic schemas. MongoDB is a document-oriented database that uses JSON documents.",
      icon_url: "https://fivetran.com/integrations/mongo/resources/mongo.svg",
      icons: [
        "https://fivetran.com/integrations/mongo/resources/mongo-logo.svg",
        "https://fivetran.com/integrations/mongo/resources/mongo-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/databases/mongodb",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/mongodb#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "mongo_sharded",
      name: "MongoDB Sharded",
      type: "Database",
      description:
        "MongoDB is a NoSQL database characterized by a lack of fixed columns and fixed tables. Instead, it has collections (which are similar to tables) and dynamic schemas. MongoDB is a document-oriented database that uses JSON documents.",
      icon_url: "https://fivetran.com/integrations/mongo/resources/mongo.svg",
      icons: [
        "https://fivetran.com/integrations/mongo/resources/mongo-logo.svg",
        "https://fivetran.com/integrations/mongo/resources/mongo-logo.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/databases/mongodb/sharded-setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/mongodb/sharded-setup-guide#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "mux",
      name: "Mux",
      type: "Marketing",
      description: "Mux is an API-based video platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/mux/resources/mux.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/mux/resources/mux-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/mux/resources/mux-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/mux",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/mux#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "mysql",
      name: "MySQL",
      type: "Database",
      description:
        "MySQL is an open-source relational database. Fivetran's integration platform replicates data from your MySQL source database and loads it into your destination.",
      icon_url:
        "https://fivetran.com/integrations/mysql/connector/resources/logos/mysql.png",
      icons: [
        "https://fivetran.com/integrations/mysql/connector/resources/logos/mysql.svg",
        "https://fivetran.com/integrations/mysql/connector/resources/logos/mysql.png",
      ],
      link_to_docs: "https://fivetran.com/docs/databases/mysql/setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/mysql/setup-guide#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "mysql_rds",
      name: "MySQL RDS",
      type: "Database",
      description:
        "MySQL RDS is a hosted version of the popular MySQL database on the Amazon RDS platform.",
      icon_url:
        "https://fivetran.com/integrations/mysql/connector/resources/logos/mysql.png",
      icons: [
        "https://fivetran.com/integrations/mysql/connector/resources/logos/mysql_rds.svg",
        "https://fivetran.com/integrations/mysql/connector/resources/logos/mysql_rds.png",
      ],
      link_to_docs: "https://fivetran.com/docs/databases/mysql/rds-setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/mysql/rds-setup-guide#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "namely",
      name: "Namely",
      type: "HumanResources",
      description: "Namely is an HR platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/namely/resources/namely.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/namely/resources/namely-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/namely/resources/namely-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/namely",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/namely#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "netsuite_suiteanalytics",
      name: "NetSuite SuiteAnalytics",
      type: "Finance",
      description:
        "NetSuite provides SuiteAnalytics as a paid option, giving businesses the power of built-in real-time dashboards, reporting and analysis—integrated right into the ERP, CRM or ecommerce application that you use every day.",
      icon_url:
        "https://fivetran.com/integrations/netsuite/resources/netsuite.svg",
      icons: [
        "https://fivetran.com/integrations/netsuite/resources/netsuite-logo.svg",
        "https://fivetran.com/integrations/netsuite/resources/netsuite-logo.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/netsuite-suiteanalytics",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/netsuite-suiteanalytics#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "notion",
      name: "Notion",
      type: "Engineering",
      description: "Notion is a note taking application.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/notion/resources/notion.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/notion/resources/notion-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/notion/resources/notion-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/notion",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/notion#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "okta",
      name: "Okta",
      type: "Productivity",
      description: "Okta is an identity management service.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/okta/resources/okta.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/okta/resources/okta-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/okta/resources/okta-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/okta",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/okta#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "one_drive",
      name: "OneDrive",
      type: "File",
      description:
        "OneDrive is a cloud-based file storage service provided by Microsoft. Fivetran supports syncing files from your OneDrive account to your destination. You can sync multiple files as unique tables to your destination.",
      icon_url:
        "https://fivetran.com/integrations/azure_consumer_file/resources/one_drive.svg",
      icons: [
        "https://fivetran.com/integrations/azure_consumer_file/resources/one_drive-0.svg",
        "https://fivetran.com/integrations/azure_consumer_file/resources/one_drive-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/files/one-drive",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/files/one-drive#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "open_data_dc",
      name: "Open Data DC",
      type: "Productivity",
      description:
        "Open Data DC is an open data portal that provides government data for Washington D.C.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/open_data_dc/resources/open_data_dc.svg",
      icons: null,
      link_to_docs: "https://fivetran.com/docs/applications/open-data-dc",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/open-data-dc#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "opendistro",
      name: "Open Distro",
      type: "Database",
      description: "Open Distro is a NoSql Document Database",
      icon_url:
        "https://fivetran.com/integrations/elasticsearch/resources/logos/opendistro.png",
      icons: [
        "https://fivetran.com/integrations/elasticsearch/resources/logos/opendistro-0.png",
        "https://fivetran.com/integrations/elasticsearch/resources/logos/opendistro-0.svg",
      ],
      link_to_docs:
        "https://fivetran.com/docs/databases/opensearch/opendistro-setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/opensearch/opendistro-setup-guide#schemainformation",
      service_status: "beta",
    },
    {
      id: "open_exchange_rates",
      name: "Open Exchange Rates",
      type: "Finance",
      description:
        "Open Exchange Rates is a JSON API that provides live and historical currency exchange rates.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/open_exchange_rates/resources/open_exchange_rates.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/open_exchange_rates/resources/open_exchange_rates.png",
        "https://fivetran.com/integrations/coil_connectors/resources/open_exchange_rates/resources/open_exchange_rates.svg",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/open-exchange-rates",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/open-exchange-rates#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "opensearch",
      name: "Opensearch",
      type: "Database",
      description: "OpenSearch is a NoSql Document Database",
      icon_url:
        "https://fivetran.com/integrations/elasticsearch/resources/logos/opensearch.png",
      icons: [
        "https://fivetran.com/integrations/elasticsearch/resources/logos/opensearch-0.png",
        "https://fivetran.com/integrations/elasticsearch/resources/logos/opensearch-0.svg",
      ],
      link_to_docs:
        "https://fivetran.com/docs/databases/opensearch/setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/opensearch/setup-guide#schemainformation",
      service_status: "beta",
    },
    {
      id: "atlassian_ops_genie",
      name: "Opsgenie",
      type: "Engineering",
      description:
        "Opsgenie is an incident management platform for development and operations teams.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/atlassian_ops_genie/resources/atlassian_ops_genie.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/atlassian_ops_genie/resources/atlassian_ops_genie-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/atlassian_ops_genie/resources/atlassian_ops_genie-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/opsgenie",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/opsgenie#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "optimizely",
      name: "Optimizely",
      type: "Events",
      description:
        "Optimizely is an experimentation platform, enabling businesses to deliver continuous experimentation and personalization across websites, mobile apps, and connected devices.",
      icon_url:
        "https://fivetran.com/integrations/optimizely/resources/optimizely.svg",
      icons: [
        "https://fivetran.com/integrations/optimizely/resources/optimizely-logo.svg",
        "https://fivetran.com/integrations/optimizely/resources/optimizely-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/optimizely",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/optimizely#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "oracle",
      name: "Oracle",
      type: "Database",
      description:
        "Oracle is an object-relational database management system created by the Oracle Corporation. Fivetran's integration platform replicates data from your Oracle source database and loads it into your destination.",
      icon_url: "https://fivetran.com/integrations/oracle/resources/oracle.svg",
      icons: [
        "https://fivetran.com/integrations/oracle/resources/oracle-logo.svg",
        "https://fivetran.com/integrations/oracle/resources/oracle-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/databases/oracle/setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/oracle/setup-guide#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "oracle_ebs",
      name: "Oracle EBS",
      type: "Database",
      description:
        "Oracle E-Business Suite (EBS) is a set of business applications for managing and automating processes across your organization. Oracle EBS is built on the Oracle database. Instead of pulling data from its API, we pull directly from the source database. Our Oracle E-Business Suite connector shares the limitations of our Oracle database connector.",
      icon_url: "https://fivetran.com/integrations/oracle/resources/oracle.svg",
      icons: [
        "https://fivetran.com/integrations/oracle/resources/oracle-logo.svg",
        "https://fivetran.com/integrations/oracle/resources/oracle-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/oracle-ebs",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/oracle-ebs#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "oracle_fusion_cloud_apps_crm",
      name: "Oracle Fusion Cloud Applications - CRM",
      type: "Sales",
      description:
        "Oracle Fusion Cloud Applications - CRM is a cloud solution that supports multiple areas of the sales process including customer data management, configure price quote, and subscription management.",
      icon_url:
        "https://fivetran.com/integrations/oracle_fusion_cloud_apps/resources/oracle.svg",
      icons: [
        "https://fivetran.com/integrations/oracle_fusion_cloud_apps/resources/oracle-logo.svg",
        "https://fivetran.com/integrations/oracle_fusion_cloud_apps/resources/oracle-logo.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/oracle-fusion-cloud-applications/oracle-fusion-crm",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/oracle-fusion-cloud-applications/oracle-fusion-crm#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "oracle_fusion_cloud_apps_fscm",
      name: "Oracle Fusion Cloud Applications - FSCM(ERP & SCM)",
      type: "Finance",
      description:
        "Oracle Fusion Cloud Applications - FSCM (ERP & SCM) is an Enterprise Resource Planning (ERP) and Supply Chain Management platform covering multiple business areas including, accounting, consolidation, planning, procurement and projects.",
      icon_url:
        "https://fivetran.com/integrations/oracle_fusion_cloud_apps/resources/oracle.svg",
      icons: [
        "https://fivetran.com/integrations/oracle_fusion_cloud_apps/resources/oracle-logo.svg",
        "https://fivetran.com/integrations/oracle_fusion_cloud_apps/resources/oracle-logo.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/oracle-fusion-cloud-applications/oracle-fusion-fscm",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/oracle-fusion-cloud-applications/oracle-fusion-fscm#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "oracle_fusion_cloud_apps_hcm",
      name: "Oracle Fusion Cloud Applications - HCM",
      type: "HumanResources",
      description:
        "Oracle Fusion Cloud Applications - HCM is a cloud solution that supports multiple people operations in an organization including Human Resources, Talent Management, Workforce Management, and Payroll.",
      icon_url:
        "https://fivetran.com/integrations/oracle_fusion_cloud_apps/resources/oracle.svg",
      icons: [
        "https://fivetran.com/integrations/oracle_fusion_cloud_apps/resources/oracle-logo.svg",
        "https://fivetran.com/integrations/oracle_fusion_cloud_apps/resources/oracle-logo.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/oracle-fusion-cloud-applications/oracle-fusion-hcm",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/oracle-fusion-cloud-applications/oracle-fusion-hcm#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "oracle_rac",
      name: "Oracle RAC",
      type: "Database",
      description: "Oracle Real Application Cluster is a database system.",
      icon_url: "https://fivetran.com/integrations/oracle/resources/oracle.svg",
      icons: [
        "https://fivetran.com/integrations/oracle/resources/oracle-logo.svg",
        "https://fivetran.com/integrations/oracle/resources/oracle-logo.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/databases/oracle/rac-setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/oracle/rac-setup-guide#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "oracle_rds",
      name: "Oracle RDS",
      type: "Database",
      description:
        "Oracle Database RDS is a hosted version of the popular Oracle database on the Amazon RDS platform.",
      icon_url: "https://fivetran.com/integrations/oracle/resources/oracle.svg",
      icons: [
        "https://fivetran.com/integrations/oracle/resources/oracle-logo.svg",
        "https://fivetran.com/integrations/oracle/resources/oracle-logo.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/databases/oracle/rds-setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/oracle/rds-setup-guide#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "orbit",
      name: "Orbit",
      type: "Marketing",
      description: "Orbit is a community management platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/orbit/resources/orbit.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/orbit/resources/orbit-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/orbit/resources/orbit-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/orbit",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/orbit#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "ortto",
      name: "Ortto",
      type: "Marketing",
      description:
        "Ortto is a platform for businesses to automate and analyze their messaging and marketing.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/ortto/resources/ortto.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/ortto/resources/ortto-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/ortto/resources/ortto-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/ortto",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/ortto#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "outbrain",
      name: "Outbrain",
      type: "Marketing",
      description:
        "Outbrain is a native advertising platform using targeted advertising to recommend articles, slideshows, blog posts, photos or videos to a reader.",
      icon_url:
        "https://fivetran.com/integrations/outbrain/resources/outbrain_logo.png",
      icons: [
        "https://fivetran.com/integrations/outbrain/resources/outbrain.svg",
        "https://fivetran.com/integrations/outbrain/resources/outbrain.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/outbrain",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/outbrain#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "outreach",
      name: "Outreach",
      type: "Sales",
      description:
        "Outreach is a sales engagement platform and automation service.",
      icon_url:
        "https://fivetran.com/integrations/outreach/resources/outreach.svg",
      icons: [
        "https://fivetran.com/integrations/outreach/resources/outreach-logo.svg",
        "https://fivetran.com/integrations/outreach/resources/outreach-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/outreach",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/outreach#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "pagerduty",
      name: "PagerDuty",
      type: "Marketing",
      description: "PagerDuty is an incident response platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/pagerduty/resources/pagerduty.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/pagerduty/resources/pagerduty-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/pagerduty/resources/pagerduty-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/pagerduty",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/pagerduty#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "papershift",
      name: "Papershift",
      type: "HumanResources",
      description:
        "Papershift is a rota planning and staff management software.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/papershift/resources/papershift.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/papershift/resources/papershift-logo.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/papershift/resources/papershift-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/papershift",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/papershift#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "pardot",
      name: "Pardot",
      type: "Marketing",
      description:
        "Pardot is a SaaS-based marketing automation application from Salesforce, enabling marketers to create, deploy and manage online promotion campaigns.",
      icon_url: "https://fivetran.com/integrations/pardot/resources/pardot.svg",
      icons: [
        "https://fivetran.com/integrations/pardot/resources/pardot-0.svg",
        "https://fivetran.com/integrations/pardot/resources/pardot-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/pardot",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/pardot#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "paypal",
      name: "Paypal",
      type: "Finance",
      description: "Paypal is an online payment processing platform",
      icon_url: "https://fivetran.com/integrations/paypal/resources/paypal.svg",
      icons: [
        "https://fivetran.com/integrations/paypal/resources/paypal-0.svg",
        "https://fivetran.com/integrations/paypal/resources/paypal-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/paypal",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/paypal#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "paypal_sandbox",
      name: "Paypal Sandbox",
      type: "Finance",
      description:
        "PayPal Sandbox is a self-contained, virtual testing environment that simulates the live PayPal production environment",
      icon_url: "https://fivetran.com/integrations/paypal/resources/paypal.svg",
      icons: [
        "https://fivetran.com/integrations/paypal/resources/paypal_sandbox.svg",
        "https://fivetran.com/integrations/paypal/resources/paypal_sandbox.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/paypal",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/paypal#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "pendo",
      name: "Pendo",
      type: "Events",
      description:
        "Pendo helps product teams understand and guide users to create product experiences that customers love.",
      icon_url: "https://fivetran.com/integrations/pendo/resources/pendo.svg",
      icons: [
        "https://fivetran.com/integrations/pendo/resources/pendo-logo.svg",
        "https://fivetran.com/integrations/pendo/resources/pendo-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/pendo",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/pendo#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "pinterest_ads",
      name: "Pinterest Ads",
      type: "Marketing",
      description:
        "Pinterest is an online pin board for collecting and sharing multimedia. Pinterest Ads help businesses increase brand's visibility and drive conversions. Ads can be targeted based on user's demographics, search terms, and interests.",
      icon_url:
        "https://fivetran.com/integrations/pinterest/resources/pinterest-ads.svg",
      icons: [
        "https://fivetran.com/integrations/pinterest/resources/pinterest.svg",
        "https://fivetran.com/integrations/pinterest/resources/pinterest.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/pinterest-ads",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/pinterest-ads#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "pipedrive",
      name: "Pipedrive",
      type: "Sales",
      description: "Pipedrive is a sales CRM and pipeline management platform.",
      icon_url:
        "https://fivetran.com/integrations/pipedrive/resources/pipedrive.svg",
      icons: [
        "https://fivetran.com/integrations/pipedrive/resources/pipedrive-logo.svg",
        "https://fivetran.com/integrations/pipedrive/resources/pipedrive-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/pipedrive",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/pipedrive#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "posthog",
      name: "PostHog",
      type: "Engineering",
      description: "PostHog is an open-source product analytics platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/posthog/resources/posthog.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/posthog/resources/posthog-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/posthog/resources/posthog-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/posthog",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/posthog#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "postgres",
      name: "PostgreSQL",
      type: "Database",
      description: "PostgreSQL is a database system",
      icon_url:
        "https://fivetran.com/integrations/postgres/resources/postgres.svg",
      icons: [
        "https://fivetran.com/integrations/postgres/resources/postgres-logo.svg",
        "https://fivetran.com/integrations/postgres/resources/postgres-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/databases/postgresql",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/postgresql/setup-guide#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "postgres_rds",
      name: "PostgreSQL RDS",
      type: "Database",
      description:
        "PostgreSQL RDS is a hosted version of the popular Postgres database on the Amazon RDS platform",
      icon_url:
        "https://fivetran.com/integrations/postgres/resources/postgres.svg",
      icons: [
        "https://fivetran.com/integrations/postgres/resources/postgres-logo.svg",
        "https://fivetran.com/integrations/postgres/resources/postgres-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/databases/postgresql",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/postgresql#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "productboard",
      name: "Productboard",
      type: "Engineering",
      description:
        "Productboard is a customer-driven product management system.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/productboard/resources/productboard.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/productboard/resources/productboard-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/productboard/resources/productboard-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/productboard",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/productboard#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "qualaroo",
      name: "Qualaroo",
      type: "Support",
      description: "Qualaroo is a cloud-based feedback management tool.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/qualaroo/resources/qualaroo.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/qualaroo/resources/qualaroo-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/qualaroo/resources/qualaroo-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/qualaroo",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/qualaroo#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "qualtrics",
      name: "Qualtrics",
      type: "Support",
      description:
        "Qualtrics is an online survey tool that allows you to build and distribute surveys, and analyze responses.",
      icon_url:
        "https://fivetran.com/integrations/qualtrics/resources/qualtrics.svg",
      icons: [
        "https://fivetran.com/integrations/qualtrics/resources/qualtrics-0.svg",
        "https://fivetran.com/integrations/qualtrics/resources/qualtrics-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/qualtrics",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/qualtrics#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "quickbooks",
      name: "QuickBooks",
      type: "Finance",
      description:
        "QuickBooks is Intuit Inc's set of software solutions designed to manage payroll, inventory, sales, and other needs of a small business. The software's features include marketing tools, merchant services, product and supplies training solutions.",
      icon_url:
        "https://fivetran.com/integrations/quickbooks/resources/quickbooks.svg",
      icons: [
        "https://fivetran.com/integrations/quickbooks/resources/quickbooks-logo.svg",
        "https://fivetran.com/integrations/quickbooks/resources/quickbooks-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/quickbooks",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/quickbooks#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "ramp",
      name: "Ramp",
      type: "Finance",
      description: "Ramp is a corporate card and spend management platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/ramp/resources/ramp.svg",
      icons: null,
      link_to_docs: "https://fivetran.com/docs/applications/ramp",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/ramp#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "rarible",
      name: "Rarible",
      type: "Engineering",
      description:
        "Rarible is a marketplace for issuing and selling custom crypto assets.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/rarible/resources/rarible.svg",
      icons: null,
      link_to_docs: "https://fivetran.com/docs/applications/rarible",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/rarible#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "recharge",
      name: "Recharge",
      type: "Finance",
      description:
        "Recharge provides a recurring billing and subscriptions solution for ecommerce.",
      icon_url:
        "https://fivetran.com/integrations/recharge/resources/recharge.svg",
      icons: [
        "https://fivetran.com/integrations/recharge/resources/recharge-logo.svg",
        "https://fivetran.com/integrations/recharge/resources/recharge-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/recharge",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/recharge#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "recurly",
      name: "Recurly",
      type: "Finance",
      description:
        "Recurly provides recurring billing management as an outsourced service.",
      icon_url:
        "https://fivetran.com/integrations/recurly/resources/recurly.svg",
      icons: [
        "https://fivetran.com/integrations/recurly/resources/recurly-logo.svg",
        "https://fivetran.com/integrations/recurly/resources/recurly-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/recurly",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/recurly#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "reddit_ads",
      name: "Reddit Ads",
      type: "Marketing",
      description:
        "Reddit Ads is an online advertising platform where advertisers bid to display promoted posts sprinkled in with regular Reddit posts.",
      icon_url: "https://fivetran.com/integrations/reddit/resources/reddit.svg",
      icons: null,
      link_to_docs: "https://fivetran.com/docs/applications/reddit-ads",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/reddit-ads#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "retailnext",
      name: "RetailNext",
      type: "Marketing",
      description: "RetailNext is an in-store retail analytics platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/retailnext/resources/retailnext.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/retailnext/resources/retailnext-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/retailnext/resources/retailnext-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/retailnext",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/retailnext#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "retently",
      name: "Retently",
      type: "Marketing",
      description: "Retently is a customer experience management platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/retently/resources/retently.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/retently/resources/retently-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/retently/resources/retently-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/retently",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/retently#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "revel",
      name: "Revel Systems",
      type: "Finance",
      description:
        "Revel Systems is a point of sale (POS) and business management platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/revel/resources/revel.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/revel/resources/revel-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/revel/resources/revel-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/revel-systems",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/revel-systems#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "ringcentral",
      name: "RingCentral",
      type: "Support",
      description:
        "RingCentral is a cloud-based business communications solutions platform.",
      icon_url:
        "https://fivetran.com/integrations/ringcentral/resources/ringcentral.svg",
      icons: null,
      link_to_docs: "https://fivetran.com/docs/applications/ringcentral",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/ringcentral#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "rollbar",
      name: "Rollbar",
      type: "Engineering",
      description:
        "Rollbar is a cloud-based code improvement solution to monitor and debug errors in your application code.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/rollbar/resources/rollbar.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/rollbar/resources/rollbar-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/rollbar/resources/rollbar-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/rollbar",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/rollbar#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "rootly",
      name: "Rootly",
      type: "Productivity",
      description: "Rootly is an incident management platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/rootly/resources/rootly.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/rootly/resources/rootly-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/rootly/resources/rootly-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/rootly",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/rootly#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "sap_business_by_design",
      name: "SAP Business ByDesign",
      type: "Finance",
      description:
        "SAP Business ByDesign is a cloud-based enterprise resource planning system for small and medium-sized organizations.",
      icon_url:
        "https://fivetran.com/integrations/sap_business_bydesign/resources/logo.png",
      icons: [
        "https://fivetran.com/integrations/sap_business_bydesign/resources/sap_business_by_design.svg",
        "https://fivetran.com/integrations/sap_business_bydesign/resources/sap_business_by_design.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/sap-business-bydesign",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/sap-business-bydesign#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "concur",
      name: "SAP Concur",
      type: "Finance",
      description:
        "SAP Concur provides an integrated online business travel and expense management software solution that automates your travel expenses.",
      icon_url:
        "https://fivetran.com/integrations/sap_concur/resources/sap_concur_logo.svg",
      icons: [
        "https://fivetran.com/integrations/sap_concur/resources/concur.svg",
        "https://fivetran.com/integrations/sap_concur/resources/concur.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/sap-concur",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/sap-concur#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "sap_hana_db",
      name: "SAP ERP on HANA",
      type: "Database",
      description:
        "SAP HANA is an in-memory, column-oriented, relational database management system. Fivetran replicates data from your SAP HANA source database and loads it into your destination.",
      icon_url:
        "https://fivetran.com/integrations/sap_hana_db/resources/sap_hana_db.svg",
      icons: null,
      link_to_docs: "https://fivetran.com/docs/databases/sap-erp/sap-erp-hana",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/sap-erp/sap-erp-hana#schemainformation",
      service_status: "beta",
    },
    {
      id: "scorm",
      name: "SCORM",
      type: "HumanResources",
      description:
        "SCORM (Sharable Content Object Reference Model) is a set of technical standards for eLearning products.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/scorm/resources/scorm.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/scorm/resources/scorm-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/scorm/resources/scorm-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/scorm",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/scorm#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "sftp",
      name: "SFTP",
      type: "File",
      description:
        "Fivetran supports syncing files from your SSH server to your destination using SFTP.",
      icon_url: "https://fivetran.com/integrations/sftp/resources/sftp.svg",
      icons: [
        "https://fivetran.com/integrations/sftp/resources/sftp-0.svg",
        "https://fivetran.com/integrations/sftp/resources/sftp-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/files/sftp",
      connector_class: "standard",
      link_to_erd: "https://fivetran.com/docs/files/sftp#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "sql_server",
      name: "SQL Server",
      type: "Database",
      description:
        "SQL Server is Microsoft's SQL database. Fivetran's integration service replicates data from your SQL Server source database and loads it into your destination at regular intervals.",
      icon_url:
        "https://fivetran.com/integrations/sql_server/resources/sql_server.svg",
      icons: [
        "https://fivetran.com/integrations/sql_server/resources/microsoft-sql-server.svg",
        "https://fivetran.com/integrations/sql_server/resources/microsoft-sql-server.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/databases/sql-server/setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/sql-server/setup-guide#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "sql_server_rds",
      name: "SQL Server RDS",
      type: "Database",
      description:
        "SQL Server RDS is a hosted version of the popular Microsoft SQL Server database on the Amazon RDS platform.",
      icon_url:
        "https://fivetran.com/integrations/sql_server/resources/sql_server.svg",
      icons: [
        "https://fivetran.com/integrations/sql_server/resources/microsoft-sql-server.svg",
        "https://fivetran.com/integrations/sql_server/resources/microsoft-sql-server.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/databases/sql-server/rds-setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/sql-server/rds-setup-guide#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "sage_intacct",
      name: "Sage Intacct",
      type: "Finance",
      description:
        "Sage Intacct is a provider of cloud-based accounting software which can provide real-time financial and operational insights, as well as the ability to automate critical processes.",
      icon_url:
        "https://fivetran.com/integrations/sage_intacct/resources/sage_intacct_logo.svg",
      icons: [
        "https://fivetran.com/integrations/sage_intacct/resources/sage_intacct.svg",
        "https://fivetran.com/integrations/sage_intacct/resources/sage_intacct.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/sage-intacct",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/sage-intacct#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "sailthru",
      name: "Sailthru",
      type: "Marketing",
      description:
        "Sailthru provides services for email management. Sailthru services include scheduling and sending campaigns and transactional emails to each user's interest. Campaigns are email messages scheduled for group of users based on a specific template. It can contain news update or promotional messages. Transactional emails are sent to a particular user in response to a specific action.",
      icon_url:
        "https://fivetran.com/integrations/sailthru/resources/sailthru.svg",
      icons: [
        "https://fivetran.com/integrations/sailthru/resources/sailthru-0.svg",
        "https://fivetran.com/integrations/sailthru/resources/sailthru-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/sailthru",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/sailthru#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "salesforce",
      name: "Salesforce",
      type: "Sales",
      description: "Salesforce is a cloud-based CRM platform.",
      icon_url:
        "https://fivetran.com/integrations/salesforce/resources/salesforce.svg",
      icons: [
        "https://fivetran.com/integrations/salesforce/resources/salesforce-logo.svg",
        "https://fivetran.com/integrations/salesforce/resources/salesforce-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/salesforce",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/salesforce#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "salesforce_commerce_cloud",
      name: "Salesforce Commerce Cloud",
      type: "Finance",
      description:
        "Salesforce Commerce is an ecommerce platform that provides unified, intelligent digital commerce experiences, both online and in the store.",
      icon_url:
        "https://fivetran.com/integrations/salesforce_commerce_cloud/resources/salesforce_commerce_cloud.svg",
      icons: [
        "https://fivetran.com/integrations/salesforce_commerce_cloud/resources/salesforce_commerce_cloud-0.svg",
        "https://fivetran.com/integrations/salesforce_commerce_cloud/resources/salesforce_commerce_cloud-0.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/salesforce-commerce-cloud",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/salesforce-commerce-cloud#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "salesforce_marketing_cloud",
      name: "Salesforce Marketing Cloud",
      type: "Marketing",
      description:
        "Salesforce Marketing Cloud provides digital marketing automation, analytics, and lead management for B2B and B2C companies.",
      icon_url:
        "https://fivetran.com/integrations/salesforce_marketing_cloud/resources/salesforce_marketing_cloud.svg",
      icons: [
        "https://fivetran.com/integrations/salesforce_marketing_cloud/resources/salesforce_marketing_cloud-0.svg",
        "https://fivetran.com/integrations/salesforce_marketing_cloud/resources/salesforce_marketing_cloud-0.png",
      ],
      link_to_docs:
        "https://fivetran.com/docs/applications/salesforce-marketing-cloud",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/salesforce-marketing-cloud#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "salesforce_sandbox",
      name: "Salesforce sandbox",
      type: "Sales",
      description: "Salesforce is a cloud-based CRM platform.",
      icon_url:
        "https://fivetran.com/integrations/salesforce/resources/salesforce_sandbox.png",
      icons: [
        "https://fivetran.com/integrations/salesforce/resources/salesforce_sandbox-0.svg",
        "https://fivetran.com/integrations/salesforce/resources/salesforce_sandbox-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/salesforce",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/salesforce#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "salesloft",
      name: "Salesloft",
      type: "Sales",
      description: "Salesloft is a sales engagement platform.",
      icon_url:
        "https://fivetran.com/integrations/salesloft/resources/salesloft.svg",
      icons: null,
      link_to_docs: "https://fivetran.com/docs/applications/salesloft",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/salesloft#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "salsify",
      name: "Salsify",
      type: "Engineering",
      description: "Salsify is a product experience management tool.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/salsify/resources/salsify.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/salsify/resources/salsify-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/salsify/resources/salsify-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/salsify",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/salsify#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "samsara",
      name: "Samsara",
      type: "Productivity",
      description:
        "Samsara develops sensor systems with remote networking and cloud-based analytics.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/samsara/resources/samsara.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/samsara/resources/samsara-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/samsara/resources/samsara-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/samsara",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/samsara#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "security_journey",
      name: "Security Journey",
      type: "HumanResources",
      description:
        "Security Journey is a software development security training platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/security_journey/resources/security_journey.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/security_journey/resources/security_journey-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/security_journey/resources/security_journey-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/security-journey",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/security-journey#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "segment",
      name: "Segment",
      type: "Events",
      description: "Segment is an event tracking library.",
      icon_url:
        "https://fivetran.com/integrations/segment/resources/segment.svg",
      icons: [
        "https://fivetran.com/integrations/segment/resources/segment-logo.svg",
        "https://fivetran.com/integrations/segment/resources/segment-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/events/segment",
      connector_class: "standard",
      link_to_erd: "https://fivetran.com/docs/events/segment#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "es_self_hosted",
      name: "Self Hosted Elasticsearch",
      type: "Database",
      description: "Self-Hosted Elasticsearch is a NoSql Document Database",
      icon_url:
        "https://fivetran.com/integrations/elasticsearch/resources/logos/elastic.png",
      icons: [
        "https://fivetran.com/integrations/elasticsearch/resources/logos/es_self_hosted.png",
        "https://fivetran.com/integrations/elasticsearch/resources/logos/es_self_hosted.svg",
      ],
      link_to_docs:
        "https://fivetran.com/docs/databases/elasticsearch/elasticsearch-self-hosted-setup-guide",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/elasticsearch/elasticsearch-self-hosted-setup-guide#schemainformation",
      service_status: "beta",
    },
    {
      id: "sendgrid",
      name: "SendGrid",
      type: "Marketing",
      description:
        "SendGrid is a customer communication platform for transactional and marketing emails.",
      icon_url:
        "https://fivetran.com/integrations/sendgrid/resources/sendgrid.svg",
      icons: [
        "https://fivetran.com/integrations/sendgrid/resources/sendgrid-logo.svg",
        "https://fivetran.com/integrations/sendgrid/resources/sendgrid-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/sendgrid",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/sendgrid#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "sendinblue",
      name: "Sendinblue",
      type: "Marketing",
      description: "Sendinblue is a digital marketing platform for businesses.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/sendinblue/resources/sendinblue.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/sendinblue/resources/sendinblue-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/sendinblue/resources/sendinblue-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/sendinblue",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/sendinblue#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "servicenow",
      name: "ServiceNow",
      type: "Productivity",
      description:
        "ServiceNow is a cloud platform that provides service management software as a service.",
      icon_url:
        "https://fivetran.com/integrations/service_now/resources/logo.svg",
      icons: [
        "https://fivetran.com/integrations/service_now/resources/servicenow-0.svg",
        "https://fivetran.com/integrations/service_now/resources/servicenow-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/servicenow",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/servicenow#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "share_point",
      name: "SharePoint",
      type: "File",
      description:
        "SharePoint is a file storage and collaboration service. Fivetran supports syncing files and lists from your SharePoint site to your destination. You can sync multiple files and lists as unique tables to your destination.",
      icon_url:
        "https://fivetran.com/integrations/azure_consumer_file/resources/share_point.svg",
      icons: [
        "https://fivetran.com/integrations/azure_consumer_file/resources/share_point-0.svg",
        "https://fivetran.com/integrations/azure_consumer_file/resources/share_point-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/files/share-point",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/files/share-point#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "shiphero",
      name: "ShipHero",
      type: "Finance",
      description: "ShipHero is a cloud-based warehouse management solution.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/shiphero/resources/shiphero.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/shiphero/resources/shiphero-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/shiphero/resources/shiphero-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/shiphero",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/shiphero#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "shipstation",
      name: "ShipStation",
      type: "Productivity",
      description: "ShipStation is an ecommerce shipping platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/shipstation/resources/shipstation.svg",
      icons: null,
      link_to_docs: "https://fivetran.com/docs/applications/shipstation",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/shipstation#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "shopify",
      name: "Shopify",
      type: "Sales",
      description:
        "Shopify is an ecommerce platform that has everything you need to sell online, on social media, or in person.",
      icon_url:
        "https://fivetran.com/integrations/shopify/resources/shopify.svg",
      icons: [
        "https://fivetran.com/integrations/shopify/resources/shopify-logo.svg",
        "https://fivetran.com/integrations/shopify/resources/shopify-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/shopify",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/shopify#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "simplesat",
      name: "Simplesat",
      type: "Support",
      description: "Simplesat is a customer feedback and survey platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/simplesat/resources/simplesat.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/simplesat/resources/simplesat-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/simplesat/resources/simplesat-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/simplesat",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/simplesat#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "skilljar",
      name: "Skilljar",
      type: "Marketing",
      description: "Skilljar is a customer training platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/skilljar/resources/skilljar.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/skilljar/resources/skilljar-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/skilljar/resources/skilljar-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/skilljar",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/skilljar#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "skuvault",
      name: "SkuVault",
      type: "Productivity",
      description:
        "SkuVault is a cloud-based inventory and warehouse management platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/skuvault/resources/skuvault.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/skuvault/resources/skuvault-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/skuvault/resources/skuvault-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/skuvault",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/skuvault#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "slack",
      name: "Slack",
      type: "Productivity",
      description:
        "Slack is a messaging program designed specifically for the workplace.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/slack/resources/slack.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/slack/resources/slack-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/slack/resources/slack-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/slack",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/slack#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "smadex",
      name: "Smadex",
      type: "Marketing",
      description:
        "Smadex is a software solution for user acquisition, retargeting, and branding campaigns.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/smadex/resources/smadex.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/smadex/resources/smadex-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/smadex/resources/smadex-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/smadex",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/smadex#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "snapchat_ads",
      name: "Snapchat Ads",
      type: "Marketing",
      description:
        "Snapchat Ads provides a platform to create, manage and measure your campaigns. You can find more information on Snapchat documentation. Snapchat Ads connector provides a history of the settings and detailed reports of your Snapchat Ad Accounts, Campaigns, Ad Squads, and Media Creatives.",
      icon_url:
        "https://fivetran.com/integrations/snapchat_ads/resources/snapchat.png",
      icons: [
        "https://fivetran.com/integrations/snapchat_ads/resources/snapchat-logo.svg",
        "https://fivetran.com/integrations/snapchat_ads/resources/snapchat-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/snapchat-ads",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/snapchat-ads#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "snowflake_db",
      name: "Snowflake",
      type: "Database",
      description: "Cloud Database Service (https://www.snowflake.com/)",
      icon_url:
        "https://fivetran.com/integrations/snowflake/resources/snowflake.png",
      icons: [
        "https://fivetran.com/integrations/snowflake/resources/snowflake-logo.svg",
        "https://fivetran.com/integrations/snowflake/resources/snowflake-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/databases/snowflake",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/databases/snowflake#schemainformation",
      service_status: "beta",
    },
    {
      id: "snowplow",
      name: "Snowplow",
      type: "Events",
      description:
        "Snowplow Analytics is an event tracking platform that allows you to track events across all channels and action the data in real-time. Snowplow uses trackers to track your information in whichever language your project is built.",
      icon_url:
        "https://fivetran.com/integrations/snowplow/resources/snowplow.svg",
      icons: [
        "https://fivetran.com/integrations/snowplow/resources/snowplow-logo.svg",
        "https://fivetran.com/integrations/snowplow/resources/snowplow-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/events/snowplow",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/events/snowplow#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "sonarqube",
      name: "SonarQube",
      type: "Productivity",
      description:
        "SonarQube is an open-source platform for continuous inspection of code quality.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/sonarqube/resources/sonarqube.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/sonarqube/resources/sonarqube-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/sonarqube/resources/sonarqube-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/sonarqube",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/sonarqube#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "splitio",
      name: "Split",
      type: "Engineering",
      description: "Split is a feature delivery platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/splitio/resources/splitio.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/splitio/resources/splitio-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/splitio/resources/splitio-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/split",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/split#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "splunk",
      name: "Splunk",
      type: "Engineering",
      description:
        "Splunk is a software platform that searches, analyzes and visualizes the machine-generated data gathered from websites, applications, sensors, and devices.",
      icon_url: "https://fivetran.com/integrations/splunk/resources/splunk.svg",
      icons: [
        "https://fivetran.com/integrations/splunk/resources/splunk-0.svg",
        "https://fivetran.com/integrations/splunk/resources/splunk-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/splunk",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/splunk#schemainformation",
      service_status: "beta",
    },
    {
      id: "square",
      name: "Square",
      type: "Finance",
      description:
        "Square, Inc. is a financial services, merchant services aggregator, and mobile payment company.",
      icon_url: "https://fivetran.com/integrations/square/resources/square.png",
      icons: [
        "https://fivetran.com/integrations/square/resources/square-logo.svg",
        "https://fivetran.com/integrations/square/resources/square-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/square",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/square#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "squarespace",
      name: "Squarespace",
      type: "Finance",
      description:
        "Squarespace is a platform for building and hosting websites.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/squarespace/resources/squarespace.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/squarespace/resources/squarespace-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/squarespace/resources/squarespace-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/squarespace",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/squarespace#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "stackadapt",
      name: "StackAdapt",
      type: "Marketing",
      description: "StackAdapt is an advertising platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/stackadapt/resources/stackadapt.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/stackadapt/resources/stackadapt-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/stackadapt/resources/stackadapt-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/stackadapt",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/stackadapt#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "statsig",
      name: "Statsig",
      type: "Engineering",
      description: "Statsig is a product observability platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/statsig/resources/statsig.svg",
      icons: null,
      link_to_docs: "https://fivetran.com/docs/applications/statsig",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/statsig#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "stripe",
      name: "Stripe",
      type: "Finance",
      description:
        "Stripe is a credit card payment system that helps you track recurring charges",
      icon_url: "https://fivetran.com/integrations/stripe/resources/stripe.svg",
      icons: [
        "https://fivetran.com/integrations/stripe/resources/stripe-logo.svg",
        "https://fivetran.com/integrations/stripe/resources/stripe-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/stripe",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/stripe#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "stripe_test",
      name: "Stripe test mode",
      type: "Finance",
      description: "Stripe is an online payment processing platform.",
      icon_url: "https://fivetran.com/integrations/stripe/resources/stripe.svg",
      icons: [
        "https://fivetran.com/integrations/stripe/resources/stripe_test-0.svg",
        "https://fivetran.com/integrations/stripe/resources/stripe_test-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/stripe",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/stripe#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "stylight",
      name: "Stylight",
      type: "Marketing",
      description:
        "Stylight is a fashion and lifestyle platform that allows users to discover and shop for the latest trends and products from a wide range of brands and retailers.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/stylight/resources/stylight.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/stylight/resources/stylight-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/stylight/resources/stylight-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/stylight",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/stylight#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "subscript",
      name: "Subscript",
      type: "Finance",
      description:
        "Subscript is a subscription intelligence platform that helps finance teams in tracking and comprehending subscription metrics",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/subscript/resources/subscript.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/subscript/resources/subscript-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/subscript/resources/subscript-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/subscript",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/subscript#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "survey_monkey",
      name: "SurveyMonkey",
      type: "Support",
      description:
        "SurveyMonkey is an online survey development tool to create and run surveys.",
      icon_url:
        "https://fivetran.com/integrations/survey_monkey/resources/survey_monkey.svg",
      icons: [
        "https://fivetran.com/integrations/survey_monkey/resources/surveymonkey.svg",
        "https://fivetran.com/integrations/survey_monkey/resources/surveymonkey.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/surveymonkey",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/surveymonkey#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "survicate",
      name: "Survicate",
      type: "Support",
      description:
        "Survicate is a survey tool that can gather data on a user's experience across a platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/survicate/resources/survicate.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/survicate/resources/survicate-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/survicate/resources/survicate-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/survicate",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/survicate#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "tableau_source",
      name: "Tableau",
      type: "Engineering",
      description: "Tableau is a data visualization platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/tableau_source/resources/tableau_source.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/tableau_source/resources/tableau_source-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/tableau_source/resources/tableau_source-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/tableau",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/tableau#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "taboola",
      name: "Taboola",
      type: "Marketing",
      description:
        "Taboola develops and markets a service for online content publishers and advertisers that recommends digital content to website users. You can find more information on Taboola documentation.",
      icon_url:
        "https://fivetran.com/integrations/taboola/resources/taboola.svg",
      icons: [
        "https://fivetran.com/integrations/taboola/resources/taboola-logo.svg",
        "https://fivetran.com/integrations/taboola/resources/taboola-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/taboola",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/taboola#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "talkdesk",
      name: "Talkdesk",
      type: "Support",
      description: "Talkdesk is a cloud-based call center solution.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/talkdesk/resources/talkdesk.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/talkdesk/resources/talkdesk.png",
        "https://fivetran.com/integrations/coil_connectors/resources/talkdesk/resources/talkdesk.svg",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/talkdesk",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/talkdesk#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "teamwork",
      name: "Teamwork",
      type: "Engineering",
      description: "Teamwork is a project management platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/teamwork/resources/teamwork.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/teamwork/resources/teamwork-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/teamwork/resources/teamwork-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/teamwork",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/teamwork#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "tempo",
      name: "Tempo",
      type: "Engineering",
      description: "Tempo is a time management platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/tempo/resources/tempo.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/tempo/resources/tempo-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/tempo/resources/tempo-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/tempo",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/tempo#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "the_trade_desk",
      name: "The Trade Desk",
      type: "Marketing",
      description:
        "The Trade Desk is an advertising service that provides users with a multifaceted approach to advertising automation.",
      icon_url:
        "https://fivetran.com/integrations/the_trade_desk/resources/the-trade-desk-logo-vector.svg",
      icons: [
        "https://fivetran.com/integrations/the_trade_desk/resources/the_trade_desk-0.svg",
        "https://fivetran.com/integrations/the_trade_desk/resources/the_trade_desk-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/the-trade-desk",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/the-trade-desk#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "tiktok_ads",
      name: "Tiktok Ads",
      type: "Marketing",
      description:
        "TikTok is a video-sharing social networking platform. TikTok Ads gives you information about the performance of your ads.",
      icon_url:
        "https://fivetran.com/integrations/tiktok_ads/resources/tiktok-logo.svg",
      icons: [
        "https://fivetran.com/integrations/tiktok_ads/resources/tiktok.svg",
        "https://fivetran.com/integrations/tiktok_ads/resources/tiktok.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/tiktok-ads",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/tiktok-ads#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "toggl_track",
      name: "Toggl Track",
      type: "Productivity",
      description:
        "Toggl Track is an online time tracking and management software.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/toggl_track/resources/toggl_track.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/toggl_track/resources/toggl_track-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/toggl_track/resources/toggl_track-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/toggl-track",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/toggl-track#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "trello",
      name: "Trello",
      type: "Engineering",
      description: "Trello is a project management tool.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/trello/resources/trello.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/trello/resources/trello-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/trello/resources/trello-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/trello",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/trello#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "trisolute",
      name: "Trisolute",
      type: "Marketing",
      description: "Trisolute is a news dashboard.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/trisolute/resources/trisolute.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/trisolute/resources/trisolute-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/trisolute/resources/trisolute-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/trisolute",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/trisolute#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "twilio",
      name: "Twilio",
      type: "Engineering",
      description:
        "Twilio is a cloud communications platform that allows users to programmatically make and receive phone calls, send and receive text messages.",
      icon_url: "https://fivetran.com/integrations/twilio/resources/twilio.svg",
      icons: [
        "https://fivetran.com/integrations/twilio/resources/twilio-logo.svg",
        "https://fivetran.com/integrations/twilio/resources/twilio-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/twilio",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/twilio#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "twitter_ads",
      name: "Twitter Ads",
      type: "Marketing",
      description:
        "Twitter Ads provides a history of the settings and detailed reports of your Twitter Ad Accounts, Campaigns, Line Items, Tweets, and Media Creatives.",
      icon_url:
        "https://fivetran.com/integrations/twitter/resources/twitter_ads.svg",
      icons: [
        "https://fivetran.com/integrations/twitter/resources/twitter.svg",
        "https://fivetran.com/integrations/twitter/resources/twitter.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/twitter-ads",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/twitter-ads#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "twitter",
      name: "Twitter Organic",
      type: "Marketing",
      description:
        "Twitter is a microblogging and social networking service. Fivetran's Twitter Organic connector provides a history of the settings and a detailed report of your organic tweet data.",
      icon_url:
        "https://fivetran.com/integrations/twitter/resources/twitter_ads.svg",
      icons: [
        "https://fivetran.com/integrations/twitter/resources/twitter.svg",
        "https://fivetran.com/integrations/twitter/resources/twitter.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/twitter",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/twitter#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "typeform",
      name: "Typeform",
      type: "Support",
      description: "Typeform is an online survey management solution.",
      icon_url:
        "https://fivetran.com/integrations/typeform/resources/typeform.svg",
      icons: [
        "https://fivetran.com/integrations/typeform/resources/typeform-logo.svg",
        "https://fivetran.com/integrations/typeform/resources/typeform-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/typeform",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/typeform#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "upland",
      name: "Upland Software",
      type: "Marketing",
      description: "Upland Software is a SMS marketing software.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/upland/resources/upland.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/upland/resources/upland-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/upland/resources/upland-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/upland",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/upland#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "uservoice",
      name: "UserVoice",
      type: "Support",
      description:
        "UserVoice is product feedback management software that enables businesses gather and analyze customer feedback and prioritize feature requests to drive strategic product decisions.",
      icon_url:
        "https://fivetran.com/integrations/uservoice/resources/uservoice.svg",
      icons: [
        "https://fivetran.com/integrations/uservoice/resources/uservoice-logo.svg",
        "https://fivetran.com/integrations/uservoice/resources/uservoice-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/uservoice",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/uservoice#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "yahoo_gemini",
      name: "Verizon Media",
      type: "Marketing",
      description:
        "Verizon Media (formerly known as Yahoo Gemini) offers you information about the performance of your Verizon Media Ads. You can find more information about it on Verizon Media's documentation page.",
      icon_url:
        "https://fivetran.com/integrations/yahoo_gemini/resources/verizon.png",
      icons: [
        "https://fivetran.com/integrations/yahoo_gemini/resources/yahoo_gemini-0.svg",
        "https://fivetran.com/integrations/yahoo_gemini/resources/yahoo_gemini-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/verizon",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/verizon#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "webhooks",
      name: "Webhooks",
      type: "Events",
      description:
        "A webhook is a user-defined HTTP callback triggered by an event on your website or in your application. A webhook allows you to send real-time HTTP notifications from one application to another whenever an event you specify occurs. A webhook URL receives JSON objects as HTTP POST requests.",
      icon_url:
        "https://fivetran.com/integrations/webhooks/resources/webhooks.svg",
      icons: [
        "https://fivetran.com/integrations/webhooks/resources/webhooks-0.svg",
        "https://fivetran.com/integrations/webhooks/resources/webhooks-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/events/webhooks",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/events/webhooks#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "when_i_work",
      name: "When I Work",
      type: "HumanResources",
      description: "When I Work is a cloud-based employee scheduling solution.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/when_i_work/resources/when_i_work.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/when_i_work/resources/when_i_work-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/when_i_work/resources/when_i_work-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/when-i-work",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/when-i-work#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "wherefour",
      name: "Wherefour",
      type: "Productivity",
      description:
        "Wherefour is a cloud-based software for traceability and inventory management.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/wherefour/resources/wherefour.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/wherefour/resources/wherefour-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/wherefour/resources/wherefour-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/wherefour",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/wherefour#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "woocommerce",
      name: "WooCommerce",
      type: "Marketing",
      description:
        "WooCommerce is an open-source e-commerce plugin for WordPress.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/woocommerce/resources/woocommerce.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/woocommerce/resources/woocommerce-logo.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/woocommerce/resources/woocommerce-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/woocommerce",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/woocommerce#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "workable",
      name: "Workable",
      type: "HumanResources",
      description: "Workable is the world's leading hiring platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/workable/resources/workable.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/workable/resources/workable-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/workable/resources/workable-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/workable",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/workable#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "workday_hcm",
      name: "Workday HCM",
      type: "HumanResources",
      description:
        "Workday HCM is a cloud-based human capital management that brings HR, recruiting, staffing, benefits administration, compensation management and related functions together in one system.",
      icon_url:
        "https://fivetran.com/integrations/workday_hcm/resources/workday.png",
      icons: [
        "https://fivetran.com/integrations/workday_hcm/resources/workday-logo.svg",
        "https://fivetran.com/integrations/workday_hcm/resources/workday-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/workday-hcm",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/workday-hcm#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "workday",
      name: "Workday RaaS",
      type: "HumanResources",
      description:
        "Workday RaaS is a cloud ERP system for finance, HR, and planning.",
      icon_url:
        "https://fivetran.com/integrations/workday/resources/workday.png",
      icons: [
        "https://fivetran.com/integrations/workday/resources/workday-logo.svg",
        "https://fivetran.com/integrations/workday/resources/workday-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/workday-raas",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/workday-raas#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "wrike",
      name: "Wrike",
      type: "Productivity",
      description: "Wrike is a cloud-based work management platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/wrike/resources/wrike.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/wrike/resources/wrike-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/wrike/resources/wrike-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/wrike",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/wrike#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "xero",
      name: "Xero",
      type: "Finance",
      description:
        "Xero provides cloud accounting services and includes a cashbook, automated daily bank feeds, invoicing, debtors, creditors, sales tax and reporting.",
      icon_url: "https://fivetran.com/integrations/xero/resources/xero.svg",
      icons: [
        "https://fivetran.com/integrations/xero/resources/xero-logo.svg",
        "https://fivetran.com/integrations/xero/resources/xero-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/xero",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/xero#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "youtube_analytics",
      name: "YouTube Analytics",
      type: "Marketing",
      description:
        "YouTube Analytics Bulk Reports contain a comprehensive set of YouTube Analytics data for a channel or content owner.",
      icon_url:
        "https://fivetran.com/integrations/youtube_analytics/resources/youtube_analytics.svg",
      icons: [
        "https://fivetran.com/integrations/youtube_analytics/resources/youtube.svg",
        "https://fivetran.com/integrations/youtube_analytics/resources/youtube.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/youtube-analytics",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/youtube-analytics#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "zendesk_chat",
      name: "Zendesk Chat",
      type: "Support",
      description:
        "Zendesk Chat creates customer engagement for online businesses with its live chat software.",
      icon_url:
        "https://fivetran.com/integrations/zendesk_chat/resources/zendesk_chat.svg",
      icons: [
        "https://fivetran.com/integrations/zendesk_chat/resources/zendesk-chat-logo.svg",
        "https://fivetran.com/integrations/zendesk_chat/resources/zendesk-chat-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/zendesk-chat",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/zendesk-chat#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "zendesk_sell",
      name: "Zendesk Sell",
      type: "Support",
      description:
        "Zendesk Sell is a sales CRM solution and sales force automation tool.",
      icon_url:
        "https://fivetran.com/integrations/zendesk_sell/resources/zendesk_sell.svg",
      icons: [
        "https://fivetran.com/integrations/zendesk_sell/resources/zendesk-sell-logo.svg",
        "https://fivetran.com/integrations/zendesk_sell/resources/zendesk-sell-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/zendesk-sell",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/zendesk-sell#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "zendesk_sunshine",
      name: "Zendesk Sunshine",
      type: "Support",
      description:
        "Zendesk Sunshine is an open and flexible CRM platform. It lets you seamlessly connect and understand all your customer data. It is fast, powerful and gives you the freedom to build whatever you want.",
      icon_url:
        "https://fivetran.com/integrations/zendesk_sunshine/resources/logo-zendesk.svg",
      icons: [
        "https://fivetran.com/integrations/zendesk_sunshine/resources/zendesk-sunshine.svg",
        "https://fivetran.com/integrations/zendesk_sunshine/resources/zendesk-sunshine.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/zendesk-sunshine",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/zendesk-sunshine#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "zendesk",
      name: "Zendesk Support",
      type: "Support",
      description:
        "Zendesk Support is a cloud-based customer support platform.",
      icon_url:
        "https://fivetran.com/integrations/zendesk/resources/zendesk.svg",
      icons: [
        "https://fivetran.com/integrations/zendesk/resources/zendesk-logo.svg",
        "https://fivetran.com/integrations/zendesk/resources/zendesk-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/zendesk",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/zendesk#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "zoho_crm",
      name: "Zoho CRM",
      type: "Support",
      description:
        "Zoho CRM is an online CRM platform that manages your sales, marketing, and customer support activities.",
      icon_url: "https://fivetran.com/integrations/zoho_crm/resources/zoho.svg",
      icons: [
        "https://fivetran.com/integrations/zoho_crm/resources/zoho-logo.svg",
        "https://fivetran.com/integrations/zoho_crm/resources/zoho-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/zoho-crm",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/zoho-crm#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "zoho_campaigns",
      name: "Zoho Campaigns",
      type: "Marketing",
      description: "Zoho Campaigns is an email marketing automation software.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/zoho_campaigns/resources/zoho_campaigns.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/zoho_campaigns/resources/zoho_campaigns-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/zoho_campaigns/resources/zoho_campaigns-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/zoho-campaigns",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/zoho-campaigns#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "zuora",
      name: "Zuora",
      type: "Finance",
      description:
        "Zuora is a SaaS application designed to automate billing, commerce and finance operations. It helps companies build and run subscription businesses.",
      icon_url: "https://fivetran.com/integrations/zuora/resources/zuora.svg",
      icons: [
        "https://fivetran.com/integrations/zuora/resources/zuora-logo.svg",
        "https://fivetran.com/integrations/zuora/resources/zuora-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/zuora",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/zuora#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "zuora_sandbox",
      name: "Zuora Sandbox",
      type: "Finance",
      description:
        "Zuora is a SaaS application designed to automate billing, commerce and finance operations. It helps companies build and run subscription businesses.",
      icon_url: "https://fivetran.com/integrations/zuora/resources/zuora.svg",
      icons: [
        "https://fivetran.com/integrations/zuora/resources/zuora-logo.svg",
        "https://fivetran.com/integrations/zuora/resources/zuora-logo.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/zuora",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/zuora#schemainformation",
      service_status: "general_availability",
    },
    {
      id: "commercetools",
      name: "commercetools",
      type: "Marketing",
      description:
        "commercetools is a cloud-based commerce solution for global businesses.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/commercetools/resources/commercetools.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/commercetools/resources/commercetools-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/commercetools/resources/commercetools-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/commercetools",
      connector_class: "standard",
      link_to_erd:
        "https://fivetran.com/docs/applications/commercetools#schemainformation",
      service_status: "beta",
    },
    {
      id: "loop",
      name: "loop",
      type: "Sales",
      description: "loop is a return management platform.",
      icon_url:
        "https://fivetran.com/integrations/coil_connectors/resources/loop/resources/loop.svg",
      icons: [
        "https://fivetran.com/integrations/coil_connectors/resources/loop/resources/loop-0.svg",
        "https://fivetran.com/integrations/coil_connectors/resources/loop/resources/loop-0.png",
      ],
      link_to_docs: "https://fivetran.com/docs/applications/loop",
      connector_class: "lite",
      link_to_erd:
        "https://fivetran.com/docs/applications/loop#schemainformation",
      service_status: "general_availability",
    },
  ];
};
