import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  Icon,
  VStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { FiPlusCircle } from "react-icons/fi";

import { getIconAndColor } from "../../utils/icons";
import { getServiceName } from "../../utils/connectors";

interface Props {
  connector: any;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
}

export default function ConnectionCard(props: Props) {
  const { connector } = props;
  const { connectorIcon } = getIconAndColor(connector?.id);

  return (
    <Link
      key={connector.id}
      to={`/connector/create/${connector.id}`}
      relative="route"
    >
      <VStack>
        <Box m="0 auto" height="128px">
          <Icon
            as={connectorIcon as React.ElementType}
            bg="brand.300"
            color="white"
            m="0 auto"
            p={5}
            w={28}
            h={28}
            _hover={{
              bg: "brand.400",
            }}
            style={{
              clipPath:
                "polygon(0 20%, 50% 0, 100% 20%, 100% 80%, 50% 100%, 0 80%)",
            }}
          />
        </Box>

        <Heading
          autoCapitalize="true"
          color="brand.400"
          size="md"
          textTransform={"capitalize"}
        >
          {getServiceName(connector?.id)}
        </Heading>

        <Box m="0 auto">
          <ButtonGroup spacing="2">
            <Button
              colorScheme="brand"
              cursor="pointer"
              size="lg"
              title="Add your data to the connector"
              variant="ghost"
              _hover={{
                color: "brand.500",
              }}
            >
              <FiPlusCircle />
            </Button>
          </ButtonGroup>
        </Box>
      </VStack>
    </Link>
  );
}
