import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Heading,
  Center,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Icon,
} from "@chakra-ui/react";

import { FaQuestion } from "react-icons/fa";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { mapMatchFields } from "../utils";

export default function HelpButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button
        colorScheme="blue"
        onClick={onOpen}
        rounded="full"
        w="48px"
        h="48px"
      >
        <Icon as={FaQuestion} fontSize={16} p="0" />
      </Button>

      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose} size="2xl">
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <Center my="16px">
                <Heading size="md">List of fields allowed</Heading>
              </Center>

              <Table variant="simple">
                <TableCaption>
                  <Box>
                    * Case Insensitive (No difference between upper/lower case)
                  </Box>
                  <Box>
                    * Delivery At must be a string with the following format:
                    YYYY-MM-DD or MM/DD/YYYY
                  </Box>
                </TableCaption>
                <Thead>
                  <Tr>
                    <Th>Field</Th>
                    <Th>Allowed</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {mapMatchFields &&
                    mapMatchFields.map((val: { field: string; allowed: string[] }) => {
                      return (
                        <Tr key={val.field}>
                          <Td>{val.field}</Td>
                          <Td>{val.allowed.join(", ")}</Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
